<template>
  <div>
    <div>
      <v-row>
        <v-col><user-teams /></v-col>
        
        <v-col>
          <div>
            <v-card class="mt-8" width="95%" height="500">
              <perfomance />
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import UserTeams from "./UserTeams.vue";
import Perfomance from "../Performance.vue";
export default {
  components: {
    // CODE_REVIEW: Can use the shorthand here and just give "UserTeams"
    UserTeams: UserTeams,
    Perfomance: Perfomance,
  },
};
</script>
