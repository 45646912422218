<template>
  <v-container class="px-1 pt-1">
    <!-- {{ selfReviewDetails }} -->
    <div>
      <v-row class="text-left">
        <v-col class="py-2"
          >Expectation of this month :{{
            selfReviewDetails[0].expectation
          }}</v-col
        >
      </v-row>
      <v-row class="text-left mt-0 font-weight-medium">
        <v-col>Where we are on it ?</v-col>
      </v-row>
      <v-row class="text-left mt-0">
        <v-col>Achievement:{{ selfReviewDetails[0].metric }} </v-col>
        <!-- <v-col  md="7">Comment:{{ selfReviewDetails[0].comment }} </v-col> -->
      </v-row>
      <v-row class="text-left mt-0">
      <v-col class="comment">Comment:{{ selfReviewDetails[0].comment }} </v-col>
      </v-row>
    </div>
    <!-- <div v-else>
      <v-alert class="text-left caption py-1" dense text type="info">
        Review not yet completed !!
      </v-alert>
    </div> -->
  </v-container>
</template>

<script>
// import axios from "axios";

export default {
  data() {
    return {};
  },

  props: {
    selfReviewDetails: {
      required: true,
    },
  },

  // mounted() {
  //   this.getSelfReviewKpi();
  // },
  methods: {
    // getSelfReviewKpi() {
    //   this.getReviewData.performance_self_review_id = this.selfReviewId;
    //   this.getReviewData.performance_kpi_id = this.kpi_id;
    //   const token = localStorage.getItem("accessToken");
    //   if (token) {
    //     axios
    //       .post(
    //         this.getReviewData,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //         }
    //       )
    //       .then((response) => {
    //         // this.reviewData= response.data.results[0];
    //         if (response.data.results[0]) {
    //           console.log("response");
    //           this.reviewData.metric = response.data.results[0].metric;
    //           this.reviewData.expectation =
    //             response.data.results[0].expectation;
    //           this.reviewData.comment = response.data.results[0].comment;
    //           this.status = response.data.results[0].status;
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   } else {
    //     this.$router.push({ path: "/login" });
    //   }
    // },
  },
};
</script>
<style scoped>

</style>
