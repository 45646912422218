<template>
  <div class="tab-custom">
    <!-- {{ userId }}-{{ roleId }} -->
    <v-tabs background-color="white" slider-color="transparent" v-model="tab">
      <v-tab
        class="custom-transform-class text-none caption"
        @click="monthlyTab"
      >
        Monthly
      </v-tab>
      <v-tab
        @click="quarterlyTab"
        class="custom-transform-class text-none caption"
      >
        <!-- CODE_REVIEW: Check spelling -->
        Quarterly
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item><month-card ref="month_card"></month-card></v-tab-item>
      <v-tab-item
        ><quarterly-card ref="quarterly_card"></quarterly-card
      ></v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import MonthCard from "@/views/performance/reviews/monthly/MonthCard.vue";
import QuarterlyCard from "@/views/performance/reviews/quarterly/QuarterlyCard.vue";

export default {
  components: {
    MonthCard,
    QuarterlyCard,
  },
  props: {
    userId: {
      required: true,
    },
    roleId: {
      required: true,
    },
  },
  data() {
    return {
      tab: 0,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (
          this.$route.query.tab === "monthly" ||
          this.$route.query.reviewTab === "monthly"
        ) {
          this.tab = 0;
        } else if (
          this.$route.query.tab === "quarterly" ||
          this.$route.query.reviewTab === "quarterly"
        ) {
          this.tab = 1;
        }
      },
    },
  },

  mounted() {
    console.log("MonthTabs");
  },

  methods: {
    monthlyTab() {
      // console.log("monthly tab", this.$refs.month_card?.callApis);
      this.$refs.month_card?.callApis();
      this.$router.push({
        name: "Team Member Monthly",
        query: {
          tab: "reviews",
          reviewTab: "monthly",
          myTeamFlag: true,
          roleId: this.roleId,
          userId: this.userId,
          monthCardFlag: true,
        },
      });
    },
    quarterlyTab() {
      // console.log("quarterly tab", this.$refs.quarterly_card?.callApis);
      this.$refs.quarterly_card?.callApis();
      this.$router.push({
        name: "Team Member Quarterly",
        query: {
          tab: "reviews",
          reviewTab: "quarterly",
          myTeamFlag: true,
          roleId: this.roleId,
          userId: this.userId,
          quarterlyCardFlag: true,
        },
      });
    },
  },
};
</script>