<template>
  <div>
    <h3 class="text-center font-weight-bold subtitle-1 mt-2">
      {{ $route.query.month }} - Self reviews KPI
    </h3>
    <!-- {{ kraKpiSelfReviewList }} -->
    <div>
      <!-- <kra-kpi-review /> -->
      <div class="prograss-custom">
        <v-progress-linear
          class="mt-4 mb-5 w-100"
          color="indigo"
          height="30"
          rounded
          striped
          :value="progressValue"
          @click.prevent="test(progressValue)"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>

        <!-- <v-progress-linear class="mt-4 mb-5 w-100"
      color="indigo"
      rounded
      height="20"
      striped
      :value="progressValue"
      @click.prevent="test(progressValue)"
    >
    <template>
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
    </v-progress-linear> -->
      </div>
      <!-- <div>
      <vue-step :now-step="nowStep" :step-list="stepList" />
    </div> -->
      <!-- Submit modal starts -->
      <v-dialog v-model="submitDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="primary justify-content-between">
            <span class="text-h6 text-white">Confirmation</span>
            <v-btn
              icon
              color="white"
              class="grey--lighten-7 px-5 caption"
              @click="submitDialog = false"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="subtitle-1 mt-5">
              Are you sure you want to submit your details ?
            </div>
          </v-card-text>
          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              color="primary"
              class="caption px-5"
              @click="changeStatusToCompleted(submitId)"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Submit modal ends -->

      <div class="kra-boxes">
        <div class="kra-title">
          <h5 class="ttl">
            {{
              kraKpiSelfReviewList.performance_year.performance_kras[kraIndex]
                .title
            }}
          </h5>
          <div class="weighage">
            <h5 class="my-2">Weightage:</h5>
            <h4 class="mb-0">
              {{
                kraKpiSelfReviewList.performance_year.performance_kras[kraIndex]
                  .weightage
              }}
            </h4>
          </div>
        </div>
        <!-- {{ totalWeightageKra }} -->
        <!-- weightage : {{ item.weightage }} -->
        <div>
          <div class="mt-1">
            <!-- keyperformance index -->

            <v-card
              elevation="0"
              class="mb-6 kpi-boxes-outer"
              v-for="(item, index) in kraKpiSelfReviewList.performance_year
                .performance_kras[kraIndex].performance_kpis"
              :key="index"
            >
              <!---------- delete icon starts ------------>

              <!-- <v-dialog
        class="mr-12"
        v-model="dialog"
        persistent
        max-width="400"
        v-if="buttonFlag"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="delete-box">
            <v-icon color="white" x-small v-bind="attrs" v-on="on"
              >mdi-delete</v-icon
            >
          </div>
        </template>
        <v-card>
          <v-card-title class="primary">
            <span class="text-h6 text-white">Confirmation</span>
          </v-card-title>
          <v-card-text>
            <div class="subtitle-1 mt-5">Are you sure you want to delete ?</div>
          </v-card-text>
          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              class="grey--lighten-7 px-5 caption"
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              rounded
              color="error"
              class="caption px-5"
              @click="dialog = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

              <!-- -------------------delete icon ends -------------->
              <!-- ---------------------edit icon starts ---------------->

              <!-- <v-dialog
        class="mr-12"
        v-model="editDialog"
        persistent
        max-width="600px"
        v-if="buttonFlag"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="edit-box">
            <v-icon color="white" x-small v-bind="attrs" v-on="on"
              >mdi-pencil</v-icon
            >
          </div>
        </template>
        <v-card>
          <v-card-title class="primary">
            <span class="text-h6 text-white">Edit</span>
          </v-card-title>
          <v-row>
            <v-col><v-text-field label="initiatives"></v-text-field> </v-col>
            <v-col><v-text-field label="expectation"></v-text-field> </v-col>
            <v-col><v-text-field label="weightage"></v-text-field> </v-col>
          </v-row>

          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              class="grey--lighten-7 px-5 caption"
              @click="editDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              rounded
              class="primary caption px-5"
              color="blue darken-1"
              @click="editDialog = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

              <!-- <div class="edit-box" v-if="buttonFlag">
        <v-icon color="white" x-small v-bind="attrs" v-on="on">mdi-pencil</v-icon>
      </div> -->

              <!--------------- edit icon ends ---------------------->

              <!-- {{item}} -->
              <!-- {{ $route.query.quarterlyGetReviewFlag }} -->
              <div class="kpi-boxes">
                <div class="content-area d-flex justify-content-between flex-wrap">
                  <div class="title">
                    <h6 class="font-weight-bold my-2 mr-2">{{ item.title }}</h6>

                    <initiative-modal
                      :kpi_id="item.id"
                      :performance_role_kpi_id="
                        item.performance_role_kpis[0].id
                      "
                      :userId="userId"
                    ></initiative-modal>
                  </div>
                  <!-- <v-card
          class="px-4 pb-1 text-left"
          elevation="0"
          v-for="(subitem, index) in item.performance_role_kpis"
          :key="index"
        > -->
                  <!-- achuthan delete button starts
          <div class="text-right" v-if="buttonFlag">
            <v-btn icon color="error" >
              <v-icon small class="mr-3">mdi-delete</v-icon>
            </v-btn>
          </div>
             achuthan delete button ends -->

                  <!-- {{ subitem.role_id }} -->
                  <div
                    class="kpi-det pt-1"
                    v-for="(subitem, index) in item.performance_role_kpis"
                    :key="index"
                  >
                    <h6>
                      Weightage : <span> {{ subitem.weightage }}</span>
                    </h6>
                    <h6>
                      KPI/Unit of measure :
                      <span>{{ item.measurement_unit }}</span>
                    </h6>
                    <h6>
                      Expectation : <span> {{ subitem.expectation }}</span>
                    </h6>
                  </div>
                  
                  <!-- </v-card> -->
                </div>
                <p class="description text-left mb-0">
                    {{ item.description }}
                  </p>
              </div>

              <div v-if="item.self_review_kpis.length">
                <div
                  class="mt-1 pl-3 text-caption"
                  v-for="(subitemnew, index) in item.self_review_kpis"
                  :key="index"
                >
                  <!-- {{ item.self_review_kpis }} -->
                  <div>
                    <performance-add-reviews
                      :kpi_id="item.id"
                      :self_review_id="$route.query.id"
                      :self_review_kpi="subitemnew"
                      :unit="item.measurement_unit"
                      @update-progress="updateprogressbar"
                    />
                  </div>
                </div>
              </div>
              <div v-else>
                <div>
                  <performance-add-reviews
                    :kpi_id="item.id"
                    :self_review_id="$route.query.id"
                    :unit="item.measurement_unit"
                    @update-progress="updateprogressbar"
                  />
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <v-btn
          align="left"
          v-if="isPrev === true && kraIndex != 0"
          color="primary"
          @click="moveToBack()"
          ><v-icon>mdi-arrow-left-box</v-icon>Back
        </v-btn>
        <v-btn
          style="margin-left: auto"
          v-if="
            isNext === true &&
            kraIndex !=
              this.kraKpiSelfReviewList.performance_year.performance_kras
                .length -
                1
          "
          color="primary"
          @click="moveToNext()"
          >Next<v-icon>mdi-arrow-right-box</v-icon></v-btn
        >
        <v-btn
          v-else-if="
            ($route.query.status === 'Active' ||
              $route.query.status === 'InProgress') &&
            progressValue === 100
          "
          color="primary"
          style="margin: 0 auto"
          @click="submitModal($route.query.id)"
          >SUBMIT
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
// import vueStep from 'vue-step'
import { mapGetters } from "vuex";
import InitiativeModal from "@/views/performance/kra-kpi/InitiativeModal.vue";
import PerformanceAddReviews from "@/views/performance/reviews/monthly/PerformanceAddReviews.vue";
import axios from "@/axios";

export default {
  components: {
    InitiativeModal,
    PerformanceAddReviews,
    // KeyPerformanceIndex,
    // vueStep
  },
  props: {
    //  flag: {
    //   type: Boolean,
    //   required: true,
    // },
    userId: {
      required: false,
    },
  },
  computed: {
    ...mapGetters(["kraKpiSelfReviewList", "allKpiCount", "performanceKras"]),
    performanceKraLength() {
      return this.kraKpiSelfReviewList.performance_year.performance_kras.length;
    },
    performanceKpiCount() {
      return this.allKpiCount;
    },
    // kraChange() {
    //   return  this.kraKpiSelfReviewList.performance_year.performance_kras[this.kraIndex].performance_kpis
    // },
    reviewProgress() {
      return 100 / this.kraKpiSelfReviewList.performance_kpis_count;
    },
    progressValue() {
      return parseInt(this.reviewArray.length * this.reviewProgress);
    },
    performanceKra() {
      return this.performanceKras;
    },
  },
  watch: {
    // kraChange() {
    //   this.getKraKpisSelfReviews();
    // },
    performanceKra() {
      this.getProgressRange(this.performanceKras);
    },
  },
  data() {
    return {
      roleId: null,
      submitDialog: false,
      role_id: null,
      kraIndex: 0,
      isNext: true,
      isPrev: false,
      isSubmit: false,
      kra_name: null,
      countList: [],
      totalCount: 0,
      moveNext: false,
      totalWeightageKra: "",
      reviewArray: [],
      buttonFlag: "",
      formData: {
        performance_self_review_id: null,
      },
    };
  },
  created() {
    this.roleId = localStorage.getItem("role_id");
  },
  mounted() {
    // console.log("nbsndbsb");
    this.getKraKpisSelfReviews();
    this.getProgressRange()
  },
  methods: {
    getProgressRange(obj) {
      for (const key in obj) {
        const item = obj[key];
        if (item.performance_kpis) {
          for (const x in item.performance_kpis) {
            const subItem = item.performance_kpis[x];
            if (subItem.self_review_kpis.length) {
              if (
                !this.reviewArray.includes(
                  subItem.self_review_kpis[0].id
                ) && subItem.self_review_kpis[0].metric !== null
              
              ) 
             {
                
                this.reviewArray.push(subItem.self_review_kpis[0].id);
            
              }
            }
          }
        }
      }
    },
    updateprogressbar() {
      this.getKraKpisSelfReviews();
      this.getProgressRange(this.performanceKras);
    },
    getKraKpisSelfReviews() {
      this.loading = true;
      this.formData.performance_self_review_id = this.$route.query.id;
      const payload = { ...this.formData };
      this.$store.dispatch("getKraKpisSelfReviews", payload);
      this.loading = false;
    },

    submitModal(id) {
      this.submitDialog = true;
      this.submitId = id;
    },

    moveToNext() {
      this.moveNext = true;
      if (
        this.kraIndex <
        this.kraKpiSelfReviewList.performance_year.performance_kras.length - 1
      ) {
        this.kraIndex += 1;
        this.isPrev = true;
         window.scrollTo(0, 0);
      } else {
        this.isNext = false;
      }
    },

    changeStatusToCompleted(id) {
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .put(
            "self-review-status-update/" + id,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    moveToBack() {
      this.moveNext = true;
      if (this.isPrev === true && this.kraIndex > 0) {
        this.kraIndex -= 1;
        this.isNext = true;
        window.scrollTo(0, 0);
      } else {
        this.isPrev = false;
      }
    },
  },
};
</script>
<!-- <style lang="sass" scoped>
 .stepper-wrap
  position: absolute
  width: 100%
  top: -7.5em
</style> -->
<style lang="scss" scoped>
.v-progress-linear {
  width: 25vw;
}
</style>
