<template>
  <div>
    <div class="text-left my-auto ml-n5">
      <bread-crumb :items="breadcrumbs" />
    </div>
            <h5>Financial Years List</h5>

    <!-- <p>{{allYear}}</p> -->
    <!-- <div>
      <bread-crumb-component :items="items" />
    </div> -->
    <!-- <div> -->
    <div class="d-flex align-items-center justify-content-between">
      <div class="my-auto">
        <!-- <h5>Financial Years List</h5> -->
      </div>
      <div class="">
        <v-dialog class="mr-12" v-model="dialog" persistent max-width="790">
          <template v-slot:activator="{ on, attrs }">
            <div class="buttons">
              <v-btn
                rounded
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="mb-4 mr-4"
                >Add Year</v-btn
              >
              <v-btn
                rounded
                color="primary"
                small
                @click="refreshDialog = true"
                class="mb-4"
                >Month Card refresh</v-btn
              >
            </div>
          </template>
          <v-card>
            <v-card-title class="primary justify-content-between">
              <span class="text-h6 text-white">Select a year</span>
              <v-btn
                icon
                color="white"
                class="grey--lighten-7 px-5 caption"
                @click="closeModal"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-form lazy-validation ref="form" style="min-height: 380px">
              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row
                      ><v-col cols="6">
                        <!-- <v-text-field
                          type="date"
                          label="From date"
                          v-model="yearData.date_from"
                          :rules="[rules.required]"
                        ></v-text-field> -->
                        <!-- <v-date-picker v-model="yearData.date_from" type="year">
                        </v-date-picker> -->
                        <div class="yearfrom">
                          <year-picker
                            v-model="yearData.date_from"
                            :year-disable="'no'"
                            placeholder="Select from year"
                            auto-grow
                            @input="showDefaultFromDate"
                          ></year-picker>
                          <div class="defaultyearfrom">
                            <p v-if="defaultFromDate">April 1st</p>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <!-- <v-text-field
                          type="date"
                          label="To date"
                          v-model="yearData.date_to"
                          :rules="[rules.required]"
                        ></v-text-field> -->
                        <!-- <v-date-picker v-model="yearData.date_from" type="year">
                        </v-date-picker> -->
                        <div class="yearto">
                          <year-picker
                            v-model="yearData.date_to"
                            :year-disable="'no'"
                            placeholder="Select to year"
                            @input="showDefaultToDate"
                          />
                          <div class="defaultyearto">
                            <p v-if="defaultToDate">March 31st</p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- <v-btn
                rounded
                class="grey--lighten-7 px-5 caption"
                @click="closeModal"
               
              >
                Close
              </v-btn> -->
                  <!-- <v-btn
                rounded
                class="primary caption px-5 justify-end"
                style="margin-left: 500px;"
                color="blue darken-1"
                :disabled="hide"
                
                @click="storeYear"
               
              >
               Add
              </v-btn> -->
                  <v-col cols="12" sm="6" md="12">
                    <v-row>
                      <v-checkbox
                        class="pt-0"
                        style="width: 50px"
                        v-model="enabled"
                        hide-details
                        @click="checkboxClick"
                      ></v-checkbox
                      >Do you want to duplicate previous year details
                      <v-row align="left">
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-select
                            v-if="enabled"
                            :items="allYear"
                            item-value="id"
                            item-text="date_from"
                            v-model="yearData.performance_year_id"
                            label="Select year for duplication"
                            hide-details
                          ></v-select>
                        </v-col>
                      </v-row>
                      <!-- <v-select
                        v-if="enabled"
                        :items="allYear"
                        menu-props="auto"
                        item-value="id"
                        item-text="date_to"
                        v-model="yearData.date_to"
                        label="Select to year for duplication"
                        hide-details
                        single-line
                      ></v-select> -->
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>
            </v-form>
            <v-card-actions class="pb-5 px-5 justify-end">
              <v-spacer></v-spacer>

              <!-- <v-btn
                rounded
                class="grey--lighten-7 caption px-5"
                @click="closeModal"
                v-if="enabled"
              >
                Close
              </v-btn> -->
              <v-btn
                rounded
                class="primary caption px-5"
                color="blue darken-1"
                type="submit"
                @click="storeYear()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div class="my-2">
      <v-card outlined class="mx-auto border-0">
        <div class="years-bar">
          <h6 class="mb-4 mx-auto">Years</h6>
        </div>
        <v-data-table
          :headers="headers"
          :items="allYear"
          :sort-by="['date_from', 'date_to', 'status']"
          class="elevation-0 table-common"
        >
          <template v-slot:[`item.date_from`]="{ item }">
            <span> {{ item.date_from | formatDate }} </span>
          </template>
          <template v-slot:[`item.date_to`]="{ item }">
            <span> {{ item.date_to | formatDate }} </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <div class="options-btns role-options">
                <span
                  @click="
                    setQueryParams({
                      id: item.id,
                      from: item.date_from,
                      to: item.date_to,
                      status: item.status,
                    })
                  "
                >
                  <router-link
                    :to="{
                      path: '/performance/admin/year/kras',
                      query: {
                        id: item.id,
                        from: item.date_from,
                        to: item.date_to,
                        status: item.status,
                      },
                    }"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon small>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View Details</span>
                    </v-tooltip></router-link
                  ></span
                >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="icon-box delete-btn"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small @click="deleteModal(item)"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </template>

                  <span>Delete Year</span>
                </v-tooltip>
              </div>
              <v-btn
                v-if="item.status != 'Active'"
                rounded
                x-small
                @click="releaseModal(item.id)"
                depressed
                color="primary"
                class="text-white"
              >
                Release
              </v-btn>
              <v-btn
                v-if="item.status === 'Active'"
                disabled
                rounded
                depressed
                color="cyan"
                class="text-white"
                x-small
                >Released</v-btn
              >
            </div>
          </template>
        </v-data-table>
      </v-card>
      <!-- delete modal start -->
      <v-dialog v-model="deleteDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="primary justify-content-between">
            <span class="text-h6 text-white">Confirmation</span>
            <v-btn
              icon
              color="white"
              class="grey--lighten-7 px-5 caption"
              @click="deleteDialog = false"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="subtitle-1 mt-5">
              Are you sure you want to delete this year?
            </div>
          </v-card-text>
          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              color="error"
              class="caption px-5"
              @click="deleteYear(deleteId)"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- delete modal ends -->

      <!-- modal to show can't delete message start -->
      <v-dialog v-model="deleteDisableDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="primary justify-content-between">
            <span class="text-h6 text-white">Warning</span>
            <v-btn
              icon
              color="white"
              class="grey--lighten-7 px-5 caption"
              @click="deleteDisableDialog = false"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="subtitle-1 mt-5">
              This is a released year, so it can't be deleted
            </div>
          </v-card-text>
          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- modal to show can't delete message ends -->

      <!-- release modal start -->
      <v-dialog v-model="releaseDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="primary justify-content-between">
            <span class="text-h6 text-white">Confirmation</span>
            <v-btn
              icon
              color="white"
              class="grey--lighten-7 px-5 caption"
              @click="releaseDialog = false"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="subtitle-1 mt-5">
              Are you sure you want to release the year? You cannot edit this
              year if you release.
            </div>
          </v-card-text>
          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              color="primary"
              class="caption px-5"
              @click="releaseYear(releaseId)"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- release modal ends -->
      <!-- refresh modal start -->
      <v-dialog v-model="refreshDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="primary justify-content-between">
            <span class="text-h6 text-white">Confirmation</span>
            <v-btn
              icon
              color="white"
              class="grey--lighten-7 px-5 caption"
              @click="refreshDialog = false"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="subtitle-1 mt-5">
              Are you sure you want to refresh the month card?
            </div>
          </v-card-text>
          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              color="primary"
              class="caption px-5"
              @click="refreshMonthCard"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- refresh modal end -->

      <v-snackbar
        min-width="0"
        app
        :color="color"
        right
        top
        v-model="toggleSnackbar"
        timeout="2000"
      >
        {{ text }}
      </v-snackbar>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios";

// import BreadCrumbComponent from "../../BreadCrumbComponent.vue";
import BreadCrumb from "../../BreadCrumb.vue";

//import moment from 'moment'

export default {
  components: {
    // BreadCrumbComponent,
    BreadCrumb,
  },
  data() {
    return {
      dialog: false,
      defaultData: "/04/01",
      deleteDialog: false,
      releaseDialog: false,
      refreshDialog: false,
      deleteDisableDialog: false,
      loading: false,
      toggleSnackbar: false,
      duplicateYearId: "",
      color: "",
      DatePickerFormat: "yyyy",
      enabled: false,
      yearId: "",
      duplicateFlag: false,
      text: "",
      sortedArray: null,
      hide: false,
      defaultFromDate: false,
      defaultToDate: false,
      headers: [
        { text: "From Date", value: "date_from" },
        { text: "To Date ", value: "date_to" },
        { text: "Status", value: "status" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "action-btns",
        },
      ],
      yearData: {
        date_from: null,
        date_to: null,
        performance_year_id: null,
      },
      yearDuplicateData: {
        performance_year_id: null,
        date_from: null,
        date_to: null,
      },
      rules: {
        required: (value) => !!value || "Required",
      },

      // items: [
      //   {
      //     text: "Year List",
      //     disabled: false,
      //     to: {
      //       name: "Year",
      //     },
      //   },
      // ],
    };
  },

  computed: {
    ...mapGetters([
      "allYear",
      "yearSuccessSnackbar",
      "yearErrorSnackbar",
      "yearErrorMessage",
      "performanceKraId",
    ]),
    sortYear() {
      let arr = this.allYear;
      arr.pop();
      arr.sort(
        (a, b) =>
          new Date(b.date_from).valueOf() - new Date(a.date_from).valueOf()
      );
      return arr;
    },
    // yearId() {
    //   return this.allYear.find((item) => item.id);
    // },
    breadcrumbs() {
      return [
        {
          text: "Year List",
          query: {},
          to: {
            name: "Year",
          },
          active: true,
        },
      ];
    },
  },
  // watch: {
  //   breadcrumbs() {
  //      this.setQueryParams()
  //   }
  // },

  mounted() {
    this.$store.dispatch("loadYears");
  },

  methods: {
    // addYear() {
    //     this.duplicateYear();
    // },
    storeYear() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const payload = { ...this.yearData };
        this.$store
          .dispatch("storeYear", payload)
          .then(() => {
            if (this.yearSuccessSnackbar) {
              this.toggleSnackbar = this.yearSuccessSnackbar;
              this.color = "success";
              this.text = "New year successfully added";
              // this.yearData.date_from = null;
              // this.yearData.date_to = null;
              this.loading = false;
              this.hide = true;
              window.location.reload();
            }
          })
          .catch(() => {
            this.toggleSnackbar = this.yearErrorSnackbar;
            this.text = this.yearErrorMessage;
            this.color = "error";
            this.text = "Year already exist";
            this.loading = false;
            this.dialog = false;
            this.yearData.date_from = null;
            this.yearData.date_to = null;
          });
        this.$refs.form.reset();
      }
    },
    closeModal() {
      this.dialog = false;
      this.hide = false;
      // window.location.reload();
      this.$refs.form.reset();
    },
    showDefaultFromDate() {
      this.defaultFromDate = true;
    },
    showDefaultToDate() {
      this.defaultToDate = true;
    },
    setQueryParams(data) {
      this.$store.commit("breadCrumb/SET_QUERY_PARAMS", data);
    },
    deleteYear(id) {
      this.deleteDialog = false;
      // CODE_REVIEW: The actions on request success should be inside then block
      this.$store
        .dispatch("deleteYear", id)
        .then(() => {
          if (this.yearSuccessSnackbar) {
            this.toggleSnackbar = this.yearSuccessSnackbar;
            this.color = "success";
            this.text = "Year successfully deleted";
          }
        })
        .catch(() => {
          // console.log("hai", this.yearErrorSnackbar);
          if (this.yearErrorSnackbar) {
            this.toggleSnackbar = this.yearErrorSnackbar;
            this.text = "KRA exist, so year can't be deleted";
            this.color = "error";
          }
        });
    },

    deleteModal(item) {
      if (item.status === "Active") {
        this.deleteDisableDialog = true;
      } else {
        this.deleteDialog = true;
        this.deleteId = item.id;
      }
    },
    checkboxClick() {
      this.duplicateFlag = true;
    },

    releaseModal(id) {
      this.releaseDialog = true;
      this.releaseId = id;
    },

    releaseCloseModal(id) {
      this.releaseDialog = false;
      this.releaseId = id;
    },

    duplicateYear() {
      for (let i in this.allYear) {
        this.yearId = this.allYear[i];
        console.log("yearrr", this.yearId.id);
      }
      this.yearDuplicateData.performance_year_id = this.duplicateYearId;
      this.yearDuplicateData.date_from = this.yearData.date_from;
      this.yearDuplicateData.date_to = this.yearData.date_to;
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .post("replicate-kra-kpi", this.yearDuplicateData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log("year duplicate data", response.data.results);
            this.toggleSnackbar = true;
            this.text = "Year successfully replicated";
            this.color = "success";
            this.dialog = false;
            this.hide = false;
            window.location.reload();
          })
          .catch(() => {
            this.toggleSnackbar = true;
            this.text = "Error";
            this.color = "error";
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },

    releaseYear(id) {
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .post(
            "release-api/" + id,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data.results);
            this.$store.dispatch("loadYears");
            this.releaseDialog = false;
          })
          .catch((error) => {
            console.log(error.response.data.message);
            this.toggleSnackbar = true;
            this.text = error.response.data.message;
            this.color = "error";
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    refreshMonthCard() {
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios.post(
          "monthily-performance-review",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ).then(()=>{
          this.refreshDialog=false;
            this.toggleSnackbar = true;
            this.text = "Refreshed Month Card";
            this.color = "success";
        })
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>
<style scoped>
.buttouns {
  display: flex;
}
.v-data-table a {
  text-decoration: none;
}
.yearfrom {
  position: relative;
}
.defaultyearfrom {
  position: absolute;
  left: 145px;
  top: 6px;
}
.yearto {
  position: relative;
}
.defaultyearto {
  position: absolute;
  left: 145px;
  top: 6px;
}
</style>
