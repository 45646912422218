<template>
  <div>
    <!-- Amal bootstrap  breadcrumb start -->
    <div class="text-left my-auto ml-n5">
      <bread-crumb :items="breadcrumbs" />
    </div>
    <!-- Amal bootstrap  breadcrumb ends -->

    <!-- Vuetify  breadcrumb start -->
    <!-- <div>
      <bread-crumb-component :items="items" />
    </div> -->

    <!-- Vuetify  breadcrumb ends -->

    <div class="d-flex align-items-center justify-content-between">
      <h5>Assign Roles - {{ $route.query.title }}</h5>
    </div>
    <div>
      <v-card class="py-2 px-3 border rolebox mt-4" outlined>
        <div>
          <v-card-title
            class="mb-2 d-flex justify-content-between align-items-center"
          >
            <div class="d-flex align-items-center">
              <span class="kpi-ttl">Kpi: </span>
              <h5 class="my-1">
                {{ $route.query.title }}
              </h5>
            </div>
            <v-btn
              rounded
              class="my-1"
              color="primary"
              x-small
              @click="addModal"
            >
              Assign New Roles
            </v-btn>
          </v-card-title>
          <div
            class="text-left text-body-2 caption pt-3 pb-2"
            width="950"
            elevation="0"
          >
            {{ $route.query.description }}
          </div>
        </div>
        <div>
          <v-dialog v-model="dialog" persistent max-width="700">
            <v-card>
              <v-card-title
                class="primary justify-content-between"
                v-if="addActive"
              >
                <span class="text-h6 text-white">Assign roles</span>
                <v-btn
                  icon
                  color="white"
                  class="grey--lighten-7 px-5 caption"
                  @click="closeModal"
                >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-title
                class="primary justify-content-between"
                v-if="editActive"
              >
                <span class="text-h6 text-white">Edit Assign roles</span>
                <v-btn
                  icon
                  color="white"
                  class="grey--lighten-7 px-5 caption"
                  @click="closeModal"
                >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-form lazy-validation ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col sm="6">
                        <v-select
                          placeholder="Role"
                          :items="allRoles"
                          item-value="id"
                          item-text="role_name"
                          v-model="kpiRolesData.role_id"
                          :rules="[rules.required]"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                      <v-col sm="6">
                        <v-text-field
                          placeholder="Weightage"
                          type="number"
                          v-model="kpiRolesData.weightage"
                          :rules="[rules.required, rules.weightageValue]"
                          oninput="this.value = parseInt(this.value);"
                          auto-grow
                          outlined
                          dense
                          
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-text-field
                      type="number"
                      placeholder="Expectation"
                      v-model="kpiRolesData.expectation"
                      :rules="[rules.required, rules.length]"
                      oninput="this.value"
                      auto-grow
                      outlined
                      dense
                    ></v-text-field>

                    <v-textarea
                      class="mt-4"
                      placeholder="Initiative"
                      v-model="kpiRolesData.initiative"
                      :rules="[rules.required, rules.initiativelength]"
                      auto-grow
                      outlined
                      dense
                      rows="1"
                    ></v-textarea>
                  </v-container>
                </v-card-text>
                <v-card-actions class="pb-5 px-5">
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="addActive"
                    rounded
                    class="primary caption px-5"
                    color="blue darken-1"
                    @click="addKpiRole"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    v-if="editActive"
                    rounded
                    class="primary caption px-5"
                    color="blue darken-1"
                    @click="updateKpiRole"
                  >
                    update
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
      </v-card>
    </div>
    <div class="mb-2 mt-4">
      <v-card outlined class="mx-auto border-0">
        <div class="years-bar">
          <h6 class="mb-4 mx-auto">Key Performance Index Role List</h6>
        </div>
        <v-data-table
          :headers="headers"
          :items="allKpiRoles"
          class="elevation-0 table-common"
          :sort-by="[
            'role_name',
            'weightage',
            'expectation',
            'performance_role_kpi_initiatives[0].initiative',
          ]"
        >
          <template
            v-slot:[`item.performance_role_kpi_initiatives[0].initiative`]="{
              item,
            }"
          >
            <v-tooltip top max-width="50%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  rounded
                  color="cyan"
                  class="text-white ml-2"
                  depressed
                  v-bind="attrs"
                  v-on="on"
                >
                  view
                </v-btn>
              </template>
              <span class="font-weight-medium">{{
                item.performance_role_kpi_initiatives[0].initiative
              }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="options-btns role-options">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="icon-box edit-btn"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small @click="editModal(item)">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Roles</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="icon-box delete-btn"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small @click="deleteModal(item.id)"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </template>
                <span>Delete Roles</span>
              </v-tooltip>
            </div>
          </template></v-data-table
        >
      </v-card>
      <v-snackbar
        min-width="0"
        app
        :color="color"
        right
        top
        v-model="toggleSnackbar"
        timeout="2000"
      >
        {{ text }}
      </v-snackbar>
      <!-- delete modal start -->
      <v-dialog v-model="deleteDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="primary justify-content-between">
            <span class="text-h6 text-white">Confirmation</span>
            <v-btn
              icon
              color="white"
              class="grey--lighten-7 px-5 caption"
              @click="deleteDialog = false"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="subtitle-1 mt-5">
              Are you sure you want to delete this role?
            </div>
          </v-card-text>
          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              small
              color="error"
              class="caption px-5"
              @click="deleteKpiRole(deleteId)"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- delete modal ends -->

      <!-- modal to show can't delete message start -->
      <v-dialog v-model="deleteDisableDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="primary justify-content-between">
            <span class="text-h6 text-white">Warning</span>
            <v-btn
              icon
              color="white"
              class="grey--lighten-7 px-5 caption"
              @click="deleteDisableDialog = false"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="subtitle-1 mt-5">
              Released year assigned roles list can't be edited
            </div>
          </v-card-text>
          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- modal to show can't delete message ends -->
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../../../BreadCrumb.vue";
// import BreadCrumbComponent from "../../../BreadCrumbComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BreadCrumb,
    // BreadCrumbComponent,
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      deleteDisableDialog: false,
      addActive: false,
      editActive: false,
      color: "",
      text: "",
      toggleSnackbar: false,
      status: 1,
      kpiRolesData: {
        performance_kra_id: null,
        performance_kpi_id: null,
        role_id: null,
        weightage: null,
        expectation: null,
        initiative: null,
        type: "Default",
      },
      rules: {
        required: (value) => !!value || "Required",
        length: (value) =>
          (value && value.length <= 50) || "Maximum 50 characters",
        weightageValue: (value) => value <= 100 || "Maximum weightage <= 100",
        initiativelength: (value) =>
          (value && value.length <= 250) || "Maximum 250 characters",
      },
      headers: [
        { text: "Role Name", value: "role_name" },
        { text: "Weightage %", value: "weightage" },
        { text: "Expectation", value: "expectation" },
        {
          text: "Initiatives",
          value: "performance_role_kpi_initiatives[0].initiative",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],

      // Amal starts

      // Amal ends

      // vuetify stasrts
      // items: [
      //   {
      //     text: "Year List",
      //     disabled: false,
      //     to: {
      //       name: "Year",
      //     },
      //   },
      //   {
      //     text: "Key Results Areas",
      //     disabled: false,
      //     to: {
      //       name: "Kras",
      //     },
      //   },
      //   {
      //     text: "Key Performance Index",
      //     disabled: false,
      //     to: {
      //       name: "kpis",
      //     },
      //   },
      //   {
      //     text: "Key Performance Index Assign Roles",
      //     disabled: true,
      //     to: {
      //       name: "Assign Kpi Roles",
      //     },
      //   },
      // ],
      // Vuetify ends
    };
  },

  computed: {
    ...mapGetters([
      "allKpiRoles",
      "allRoles",
      "kpiRoleSuccessSnackbar",
      "kpiRoleErrorSnackbar",
      "kpiRoleErrorMessage",
    ]),
    queryKpi() {
      return this.$store.getters["breadCrumb/allQueryKpi"];
    },
    query() {
      return this.$store.getters["breadCrumb/allQuery"];
    },
    queryKra() {
      return this.$store.getters["breadCrumb/allQueryKra"];
    },
    breadcrumbs() {
      return [
        {
          text: "Year List",
          query: {},
          to: {
            name: "Year",
          },
        },
        {
          text: "Key Results Areas",
          query: this.query,
          to: {
            name: "kras",
          },
        },
        {
          text: "Key Performance Index",
          query: this.queryKra,
          to: {
            name: "kpis",
          },
        },
        {
          text: "Assign Roles Kpi",
          query: this.queryKpi,
          to: {
            name: "",
          },
          active: true,
        },
      ];
    },
  },

  mounted() {
    this.$store.dispatch("getRoles");
    this.getKpiRoles();
  },

  methods: {
    addModal() {
      if (this.$route.query.status === "Active") {
        this.deleteDisableDialog = true;
      } else {
        this.dialog = true;
        this.addActive = true;
      }
    },

    editModal(item) {
      if (this.$route.query.status === "Active") {
        this.deleteDisableDialog = true;
      } else {
        this.dialog = true;
        this.editActive = true;
        this.roleKpiId = item.performance_role_kpi_initiatives[0].id;
        this.kpiRolesData.role_id = item.role_id;
        this.kpiRolesData.weightage = item.weightage;
        this.kpiRolesData.expectation = item.expectation;
        this.kpiRolesData.initiative =
          item.performance_role_kpi_initiatives[0].initiative;
      }
    },
    deleteModal(id) {
      if (this.$route.query.status === "Active") {
        this.deleteDisableDialog = true;
      } else {
        this.deleteDialog = true;
        this.deleteId = id;
      }
    },

    closeModal() {
      this.dialog = this.addActive = this.editActive = false;
      this.kpiRolesData.role_id = null;
      this.kpiRolesData.weightage = null;
      this.kpiRolesData.expectation = null;
      this.kpiRolesData.initiative = null;
      this.$refs.form.reset();
    },

    getKpiRoles() {
      this.$store.dispatch("getKpiRoles", this.$route.query.kpi_id);
    },

    addKpiRole() {
      if (this.$refs.form.validate()) {
        this.addActive = false;
        this.dialog = false;
        this.kpiRolesData.performance_kra_id = this.$route.query.kra_id;
        this.kpiRolesData.performance_kpi_id = this.$route.query.kpi_id;
        const payload = { ...this.kpiRolesData };
        this.$store
          .dispatch("addKpiRole", payload)
          .then(() => {
            if (this.kpiRoleSuccessSnackbar) {
              this.toggleSnackbar = this.kpiRoleSuccessSnackbar;
              this.color = "success";
              this.text = "New role successfully assigned";
              this.kpiRolesData.role_id = null;
              this.kpiRolesData.weightage = null;
              this.kpiRolesData.expectation = null;
              this.kpiRolesData.initiative = null;
              this.$store.dispatch("getKpiRoles", this.$route.query.kpi_id);
            }
          })
          .catch(() => {
            this.toggleSnackbar = this.kpiRoleErrorSnackbar;
            this.color = "error";
            this.text = this.kpiRoleErrorMessage;
          });
        this.$refs.form.reset();
      }
    },

    updateKpiRole() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.editActive = false;
        this.kpiRolesData.performance_kra_id = this.$route.query.kra_id;
        this.kpiRolesData.performance_kpi_id = this.$route.query.kpi_id;
        const payload = { id: this.roleKpiId, formData: this.kpiRolesData };
        this.$store
          .dispatch("updateKpiRole", payload)
          .then(() => {
            if (this.kpiRoleSuccessSnackbar) {
              this.kpiRolesData.role_id = null;
              this.kpiRolesData.weightage = null;
              this.kpiRolesData.expectation = null;
              this.kpiRolesData.initiative = null;
              this.toggleSnackbar = this.kpiRoleSuccessSnackbar;
              this.color = "success";
              this.text = "Role details successfully edited";
              this.$store.dispatch("getKpiRoles", this.$route.query.kpi_id);
            }
          })
          .catch(() => {
            if (this.kpiRoleErrorSnackbar) {
              this.toggleSnackbar = this.kpiRoleErrorSnackbar;
              this.color = "error";
              this.text = "Error in role";
            }
          });
        this.$refs.form.reset();
      }
    },

    deleteKpiRole(id) {
      this.deleteDialog = false;
      // CODE_REVIEW: The actions on request success should be inside then block
      this.$store
        .dispatch("deleteKpiRole", id)
        .then(() => {
          this.toggleSnackbar = this.kpiRoleSuccessSnackbar;
          this.color = "success";
          this.text = "Assigned role successfully deleted";
        })
        .catch(() => {
          if (this.kpiRoleErrorSnackbar) {
            this.toggleSnackbar = this.kpiRoleErrorSnackbar;
            this.color = "error";
            this.text = "Error in roles delete";
          }
        });
    },
  },
};
</script>
