import axios from "@/axios";
import router from '@/router'

const state = {
  kraKpiList: [],
  initatives: [],
  kpiCount: 0
};

const getters = {
  allKraKpi: (state) => state.kraKpiList,
  allInitiative: state => state.initatives,
  allKpiCount: state => state.kpiCount,
};

const mutations = {
  GET_KRA_KPIS(state, data) {
    state.kraKpiList = data;
  },
  GET_KPI_COUNT(state, data) {
    console.log("kpi count from store", data)
    state.kpiCount = data;

  },

  ADD_INITIATIVE(state, data) {
    state.initatives.push(data);
  },
  UPDATE_INITIATIVE(state, data) {
    const index = state.initatives.findIndex(initatives => initatives.id === data.id);
    if (index !== -1) {
      state.initatives.splice(index, 1, data);
    }
  },
  DELETE_INITIATIVE(state, id) {
    state.initatives = state.initatives.filter(
      (initatives) => initatives.id !== id
    );
  },

};

const actions = {
  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async getKraKpis({ commit, state }, data) {
    state.kraKpiList = []
    state.kpiCount = ''
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .post(
          "kra-kpi-list",
         data ,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          // console.log(response.data.results);
          // coverting first letter of a string in JSON response to caps starts
          const capsKraKpi = response.data.results.year_kras_kpis_initiatives;
          // CODE_REVIEW: Extract the replacer function of JSON.stringify to separate function to avoid repetition
          let userObj = JSON.stringify(capsKraKpi, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });
          var parsed = JSON.parse(userObj);
          // coverting first letter of a string in JSON response to caps ends
          commit("GET_KRA_KPIS", parsed);
          commit("GET_KPI_COUNT", response.data.results.performance_kpis_count);
        })
        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  
  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async addInitiative({ commit }, formData) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .post(
          "strategic-initiatives-store",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.results);
          commit("ADD_INITIATIVE", response.data.results);
        })
        .catch((error) => {
          // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 71
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },
  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async updateInitiative({ commit }, payload) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .put(
          "strategic-initiatives-update/" +
          payload.id,
          payload.formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          commit("UPDATE_INITIATIVE", response.data.results);
        })
        .catch((error) => {
          // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 71
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },
  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async deleteInitiative({ commit }, id) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .delete(
          "strategic-initiatives-delete/" +
          id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          console.log(result.data.id);
          commit("DELETE_INITIATIVE", id);
        })

        .catch((error) => {
          // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 71
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },
};
export default { state, mutations, actions, getters };
