<template>
  <div class>
    <v-card
      elevation="0"
      class="mb-3"
      v-for="(item, index) in performanceKpis"
      :key="index"
    >
      <!-- delete icon starts -->
      <!-- <div v-if="kraStatus !== 'active'">
        <v-dialog class="mr-12" persistent max-width="400" v-if="buttonFlag">
          <template v-slot:activator="{ on, attrs }">
            <div class="delete-box">
              <v-icon color="white" x-small v-bind="attrs" v-on="on"
                >mdi-delete</v-icon
              >
            </div>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-card-title class="primary">
                <span class="text-h6 text-white">Confirmation</span>
              </v-card-title>
              <v-card-text>
                <div class="subtitle-1 mt-5">
                  Are you sure you want to delete ?
                </div>
              </v-card-text>
              <v-card-actions class="pb-5 px-5">
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  class="grey--lighten-7 px-5 caption"
                  @click="dialog.value = false"
                >
                  Close
                </v-btn>
                <v-btn
                  rounded
                  color="error"
                  class="caption px-5"
                  @click="dialog.value = false"
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div> -->

      <!-- delete icon ends -->
      <!-- edit icon starts -->
      <div v-if="kraStatus !== 'active'">

      <!-- <v-dialog persistent max-width="700px" v-if="buttonFlag">
        <template v-slot:activator="{ on, attrs }">
          <div class="edit-box">
            <v-icon color="white" x-small v-bind="attrs" v-on="on"
              >mdi-pencil</v-icon
            >
          </div>
        </template>
        <template v-slot:default="editdialog">
          <v-card>
            <v-card-title class="primary justify-content-between">
              <span class="text-h6 text-white">Edit</span>
              <v-btn
                icon
                color="white"
                class="grey--lighten-7 px-5 caption"
                @click="editdialog.value = false"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-row
              v-for="(subitem, index) in item.performance_role_kpis"
              :key="index"
            >
              <v-col
                ><v-text-field
                  placeholder="initiatives"
                  v-model="
                    subitem.performance_role_kpi_initiatives[0].initiative
                  "
                  auto-grow
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                ><v-text-field
                  placeholder="expectation"
                  v-model="subitem.expectation"
                  auto-grow
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                ><v-text-field
                  placeholder="weightage"
                  v-model="subitem.weightage"
                  auto-grow
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-card-actions class="pb-5 px-5">
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  class="grey--lighten-7 px-5 caption"
                  @click="editdialog.value = false"
                >
                  Close
                </v-btn>
                <v-btn
                  rounded
                  class="primary caption px-5"
                  color="blue darken-1"
                  @click="
                   editdialog.value = false"
                   
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-row>

            
          </v-card>
        </template>
      </v-dialog> -->
      </div>
      <!-- <div class="edit-box" v-if="buttonFlag">
        <v-icon color="white" x-small v-bind="attrs" v-on="on">mdi-pencil</v-icon>
      </div> -->
      <!-- edit icon ends -->
      <!-- {{item}} -->
      <!-- {{ $route.query.quarterlyGetReviewFlag }} -->

      <!-- anju design start-->

      <div class="kpi-boxes">
        <div class="content-area">
          <div class="title">
            <h6 class="font-weight-bold my-2 mr-2">{{ item.title }}</h6>
          <div v-if="$route.query.dashBoardFlag">
          </div>
            <div v-else>
            <initiative-modal
              :kpi_id="item.id"
              :performance_role_kpi_id="item.performance_role_kpis[0].id"
              :userId="userId"
             ></initiative-modal>
             </div>
            <!-- <span class="badge badge-primary">View Initiative</span> -->
          </div>
          <div
            class="kpi-det"
            v-for="(subitem, index) in item.performance_role_kpis"
            :key="index"
          >
            <h6>
              Weightage : <span> {{ subitem.weightage }}</span>
            </h6>
            <h6>
              KPI/Unit of measure : <span>{{ item.measurement_unit }}</span>
            </h6>
            <h6>
              Expectation : <span> {{ parseFloat(subitem.expectation) }}</span>
            </h6>
          </div>
          <p class="description" v-if="$route.query.dashBoardFlag"></p>
          <p class="description" v-else>{{ item.description }}</p>
        </div>
        <div class="image-area"></div>
      </div>

      <!--anju design end-->

      <!-- old design start -->

      <!-- <div class="d-flex align-items-center justify-content-between flex-wrap">
        <v-card-title class="pb-1">
          <span class="subtitle-2 mt-n4"> {{ item.title }}</span>
          <initiative-modal
            :kpi_id="item.id"
            :performance_role_kpi_id="item.performance_role_kpis[0].id"
            :userId="userId"
          ></initiative-modal>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card
          class="px-4 pb-1 text-left"
          elevation="0"
          v-for="(subitem, index) in item.performance_role_kpis"
          :key="index"
        >
         
          <div>
            <span class="text-subtitle-2"
              >weightage : {{ subitem.weightage }}</span
            >
            <span class="text-subtitle-2 ml-6"
              >KPI/Unit of measure : {{ item.measurement_unit }}
            </span>
            <span class="text-subtitle-2 ml-6"
              >Expectation : {{ subitem.expectation }}
            </span>
          </div>
        </v-card>
      </div>
      <div class="mx-4 text-left caption" elevation="0">
        {{ item.description }}
      </div> -->

      <!-- old design end -->

      <div
        class="mt-1 pl-3 text-caption"
        v-for="(subitem, index) in item.performance_role_kpis"
        :key="index"
      >
        <!-- {{ item.self_review_kpis }} -->
        <div>
          <performance-add-reviews
            :kpi_id="item.id"
            :self_review_id="$route.query.id"
            v-if="flag"
          />
          <performance-get-reviews
            v-if="$route.query.quarterlyGetReviewFlag"
            :kpi_id="item.id"
          />
          <myteam-get-reviews
            :selfReviewDetails="item.self_review_kpis"
            v-if="monthlyGetReviewFlag"
          />
          <myteam-add-reviews
            v-if="quarterlyAddReviewFlag"
            :kpi_id="item.id"
            :quarterReviewId="quarterReviewId"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import InitiativeModal from "@/views/performance/kra-kpi/InitiativeModal.vue";
import PerformanceAddReviews from "@/views/performance/reviews/monthly/PerformanceAddReviews.vue";
import PerformanceGetReviews from "@/views/performance/reviews/quarterly/PerformanceGetReviews.vue";
import MyteamGetReviews from "@/views/my-team/monthly/MyteamGetReviews.vue";
import MyteamAddReviews from "../../my-team/quarterly/MyteamAddReviews.vue";

export default {
  data() {
    return {
      // dialog: false,
      // weightageList: [],
      // totalSum: 0,
      // kpiCount: 0,
      // kraIndex: 0,

      editDialog: false,
      kpiRolesData: {
        performance_kra_id: null,
        performance_kpi_id: null,
        role_id: null,
        weightage: null,
        expectation: null,
        initiative: null,
        type: "Default",
      },
    };
  },

  components: {
    InitiativeModal,
    PerformanceAddReviews,
    PerformanceGetReviews,
    MyteamGetReviews,
    MyteamAddReviews,
  },
  computed: {
    // kraChange() {
    //   return this.moveNext;
    // },
  },
  watch: {
    // kraChange() {
    //   this.weightageList = [];
    //   this.totalSum = 0;
    //   this.getWeightageList(this.performanceKpis);
    // },
  },
  props: {
    performanceKpis: {
      type: [Object, Array],
      required: true,
    },
    userId: {
      required: false,
    },
    flag: {
      type: Boolean,
      required: false,
    },
    monthlyGetReviewFlag: {
      type: Boolean,
    },
    quarterlyAddReviewFlag: {
      type: Boolean,
    },
    buttonFlag: {
      type: Boolean,
      required: false,
    },
    quarterReviewId: {
      required: false,
    },
    selfReviewId: {
      required: false,
    },
    moveNext: {
      type: Number,
      required: false,
    },
    kraStatus: {
      type: [String],
      required: false,
    },
  },
  mounted() {
    // this.getWeightageList(this.performanceKpis);
  },
  methods: {
    updateKpiRole(kraId, kpiId, weightage, expectation) {
      this.kpiRolesData.performance_kra_id = kraId;
      this.kpiRolesData.performance_kpi_id = kpiId;
      this.kpiRolesData.role_id = this.$route.query.id;
      this.kpiRolesData.weightage = weightage;
      this.kpiRolesData.expectation = expectation;

      const payload = { id: this.roleKpiId, formData: this.kpiRolesData };
      this.$store
        .dispatch("updateKpiRole", payload)
        .then(() => {
          this.kpiRolesData.role_id = null;
          this.kpiRolesData.weightage = null;
          this.kpiRolesData.expectation = null;
          this.kpiRolesData.initiative = null;
          this.toggleSnackbar = this.kpiRoleSuccessSnackbar;
          this.color = "success";
          this.text = "Role details successfully edited";
          this.$store.dispatch("getKpiRoles", this.$route.query.kpi_id);
        })
        .catch(() => {});
      this.$refs.form.reset();
    },
    // getWeightageList(obj) {
    //   for (let key = 0; key < obj.length; key++) {
    //     const item = obj[key];
    //     if (item.performance_role_kpis) {
    //       this.weightageList.push(item.performance_role_kpis[0].weightage);
    //     }
    //   }
    //   if (this.weightageList.length) {
    //     this.weightageSum();
    //   }
    // },
    // weightageSum() {
    //   for (let i = 0; i < this.weightageList.length; i++) {
    //     this.totalSum += this.weightageList[i];
    //   }
    //   this.$emit("totalWeightageSum", this.totalSum);
    // },
  },
};
</script>
