<template>
  <div>
    <!-- monthly reviewsss -->
    <!-- {{ kraKpiSelfReviewList.performance_year.performance_kras }} -->
    <!-- {{ $route.query.selfReviewId }} -->
    <div class="kra-boxes"
      v-for="item in kraKpiSelfReviewList.performance_year.performance_kras"
      :key="item.id"
    >
     <div class="kra-title">
        <h5 class="ttl">{{ item.title }}</h5>
        <div class="weighage">
          <h5 class="my-2">Total Weightage: </h5>
        <h4 class="mb-0">{{ item.weightage }}</h4> 
        </div>
      </div> 
      <!-- <v-card elevation="0" class="font-weight-bold mx-auto color-light-grey">
        <v-list-item class="dark--text mt-2 overline">
          {{ item.title }}<v-spacer></v-spacer>Total Weightage:
          {{ item.weightage }}
        </v-list-item>
      </v-card> -->
      <div class="mt-1">
        <key-performance-index
          class="mb-1"
          :performance-kpis="item.performance_kpis"
          :monthlyGetReviewFlag="monthlyGetReviewFlag"
          :selfReviewId="$route.query.selfReviewId"
          :userId="$route.query.userId"
        ></key-performance-index>
      </div>
    </div>
  </div>
</template>

<script>
import KeyPerformanceIndex from "@/views/performance/kra-kpi/KeyPerformanceIndex.vue";
import { mapGetters } from "vuex";
// import Loading from 'vue-loading-overlay'

export default {
  components: {
    KeyPerformanceIndex,
  },

  data() {
    return {
      monthlyGetReviewFlag: null,
      totalWeightageKra: "",
      loading: false,
      formData: {
        user_id: null,
        performance_self_review_id: null,
      },
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.monthlyGetReviewFlag) {
          this.monthlyGetReviewFlag = true;
        }
      },
    },
  },

  computed: { ...mapGetters(["kraKpiSelfReviewList"]) },

  mounted() {
    // console.log("haiaii", this.$route.query.selfReviewId);
    this.getKraKpisSelfReviews();
  },

  methods: {
    getKraKpisSelfReviews() {
      // console.log(this.$route.query.selfReviewId);
      this.loading = true;
      this.formData.user_id = this.$route.query.userId;
      this.formData.performance_self_review_id = this.$route.query.selfReviewId;
      const payload = { ...this.formData };
      this.$store.dispatch("getKraKpisSelfReviews", payload);
      this.loading = false;
    },
  },
};
</script>