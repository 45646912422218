<template>
  <div>
    <!-- {{ userId }}{{ roleId }} -->
    <kra-kpi ref="krakpi" :roleId="roleId" :userId="userId"></kra-kpi>
  </div>
</template>

<script>
import KraKpi from "@/views/performance/kra-kpi/KraKpi.vue";

export default {
  components: {
    KraKpi,
  },
  props: {
    userId: {
      required: false,
    },
    roleId: {
      required: false,
    },
  },
  mounted(){
  }
};
</script>