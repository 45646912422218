<template>
  <div>
    <!-- Amal bootstrap breadcrumb start -->
    <div class="text-left my-auto ml-n5">
      <bread-crumb :items="breadcrumbs" />
    </div>
    <!-- Amal bootstrap breadcrumb end -->
    <!-- vueitfy breadcrumb start -->
    <!-- <div>
      <bread-crumb-component :items="items" />
    </div> -->
    <!-- vueitfy breadcrumb end -->

    <div class="d-flex align-items-center justify-content-between">
      <h5 v-if="!query">
        Key Results Area - ( {{ $route.query.from | formatDate }} to
        {{ $route.query.to | formatDate }})
      </h5>
      <h5 else>
        Key Results Areas - ( {{ query.from | formatDate }} to
        {{ query.to | formatDate }})
      </h5>

      <!-- Modal to add and edit Kra -->
      <div class="">
        <v-btn rounded class="mb-4" color="primary" @click="addModal" small
          >Add KRA</v-btn
        >

        <v-dialog v-model="dialog" persistent max-width="500">
          <v-card>
            <v-card-title
              class="primary justify-content-between"
              v-if="addActive"
            >
              <span class="text-h6 text-white">Add new KRA</span>
              <v-btn
                icon
                color="white"
                class="grey--lighten-7 px-5 caption"
                @click="closeModal"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-title
              class="primary justify-content-between"
              v-if="editActive"
            >
              <span class="text-h6 text-white">Edit KRA</span>
              <v-btn
                icon
                color="white"
                class="grey--lighten-7 px-5 caption"
                @click="closeModal"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-form lazy-validation ref="form" class="px-3 pt-3">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-text-field
                      placeholder="KRA Name"
                      v-model="kraData.title"
                      :rules="[rules.required, rules.length]"
                      v-on:keydown.enter.prevent="delay"
                      auto-grow
                      outlined
                      dense
                      rows="1"
                    ></v-text-field>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-5 px-5">
                <v-spacer></v-spacer>
                <v-btn
                :disabled="disabled"
                  v-if="addActive"
                  rounded
                  class="primary caption px-5"
                  color="blue darken-1"
                  @click="delay"
                >
                  Save
                </v-btn>
                <v-btn
                  v-if="editActive"
                  rounded
                  class="primary caption px-5"
                  color="blue darken-1"
                  @click="updateKra"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <div class="my-2">
      <v-card outlined class="mx-auto border-0">
        <div class="years-bar">
          <h6 class="mb-4 mx-auto">Key Results Areas List</h6>
        </div>
        <v-data-table
          :headers="headers"
          :items="allKra"
          :sort-by="['title']"
          class="elevation-0 table-common"
        >
          <template v-slot:[`item.actions`]="{ item }">
          <div class="options-btns role-options">
            <span
              @click="
                setQueryParamsKra({
                  id: item.id,
                  kra: item.title,
                  status: $route.query.status,
                })
              "
            >
              <router-link
                :to="{
                  path: '/performance/admin/year/kras/kpis',
                  query: {
                    id: item.id,
                    kra: item.title,
                    status: $route.query.status,
                  },
                }"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small class="mr-2">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View Details</span>
                </v-tooltip></router-link
              ></span
            >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="icon-box edit-btn" v-bind="attrs" v-on="on">
                  <v-icon small @click="editModal(item)"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              <span>Edit Kra</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="icon-box delete-btn" v-bind="attrs" v-on="on">
                  <v-icon small @click="deleteModal(item.id)"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              <span>Delete Kra</span>
            </v-tooltip>
          </div>
          </template></v-data-table
        >
      </v-card>
      <v-snackbar
        app
        :color="color"
        right
        top
        v-model="toggleSnackbar"
        timeout="2000"
      >
        {{ text }}
      </v-snackbar>
      <!-- delete modal start -->
      <v-dialog v-model="deleteDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="primary justify-content-between">
            <span class="text-h6 text-white">Confirmation</span>
            <v-btn
              icon
              color="white"
              class="grey--lighten-7 px-5 caption"
              @click="deleteDialog = false"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="subtitle-1 mt-5">
              Are you sure you want to delete this KRA?
            </div>
          </v-card-text>
          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
            <v-btn
              rounded
              color="error"
              small
              class="caption px-5"
              @click="deleteKra(deleteId)"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- delete modal ends -->

      <!-- modal to show can't delete message start -->
      <v-dialog v-model="deleteDisableDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="primary justify-content-between">
            <span class="text-h6 text-white">Warning</span>
            <v-btn
              icon
              color="white"
              class="grey--lighten-7 px-5 caption"
              @click="deleteDisableDialog = false"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="subtitle-1 mt-5">
              Released year KRAs list can't be edited
            </div>
          </v-card-text>
          <v-card-actions class="pb-5 px-5">
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- modal to show can't delete message ends -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BreadCrumb from "../../BreadCrumb.vue";
// import BreadCrumbComponent from "../../BreadCrumbComponent.vue";
export default {
  components: {
    BreadCrumb,
    // BreadCrumbComponent,
  },

  data() {
    return {
      dialog: false,
      deleteDialog: false,
      deleteDisableDialog: false,
      addActive: false,
      disabled: false,
      timeout: null,
      editActive: false,
      color: "",
      text: "",
      toggleSnackbar: false,
      kraId: null,
      headers: [
        {
          text: "Key Result Area",
          value: "title",
          width: "85%",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "action-btns",
        },
      ],
      kraData: {
        id: null,
        performance_year_id: null,
        title: null,
      },
      rules: {
        required: (value) => !!value || "Required",
        length: (value) =>
          (value && value.length <= 250) || "Maximum 250 characters",
      },

      // Amal bootsrap starts

      // Amal bootstrap ends

      // vuetify breadcrumb data start
      // items: [
      //   {
      //     text: "Year List",
      //     disabled: false,
      //     to: {
      //       name: "Year",
      //     },
      //   },
      //   {
      //     text: "Key Results Areas",
      //     disabled: false,
      //     to: {
      //       name: "Kras",
      //     },
      //   },
      // ],
      // vuetify breadcrumb data end
    };
  },

  computed: {
    ...mapGetters(["allKra", "kraSuccessSnackbar", "kraErrorSnackbar"]),
    query() {
      return this.$store.getters["breadCrumb/allQuery"];
    },

    breadcrumbs() {
      return [
        {
          text: "Year List",
          query: {},
          to: {
            name: "Year",
          },
        },
        {
          text: "Key Results Areas",
          query: this.query,
          to: {
            name: "kras",
          },
          active: true,
        },
      ];
    },
  },
  // watch: {
  //   breadCrumbs() {
  //      this.setQueryParamsKra()
  //   }
  // },

  mounted() {
    this.getKras();
  },

  methods: {
    addModal() {
      if (
        this.$route.query.status
          ? this.$route.query.status === "Active"
          : this.query.status === "Active"
      ) {
        this.deleteDisableDialog = true;
      } else {
        this.dialog = true;
        this.addActive = true;
      }
    },
    setQueryParamsKra(data) {
      this.$store.commit("breadCrumb/SET_QUERY_PARAMS_KRA", data);
    },

    editModal(item) {
      if (
        this.$route.query.status
          ? this.$route.query.status === "Active"
          : this.query.status === "Active"
      ) {
        this.deleteDisableDialog = true;
      } else {
        this.dialog = true;
        this.editActive = true;
        this.kraId = item.id;
        this.kraData.title = item.title;
      }
    },
    deleteModal(id) {
      if (
        this.$route.query.status === "Active" ||
        this.query.status === "Active"
      ) {
        this.deleteDisableDialog = true;
      } else {
        this.deleteDialog = true;
        this.deleteId = id;
      }
    },

    closeModal() {
      this.dialog = this.addActive = this.editActive = false;
      this.kraData.title = null;
      this.$refs.form.reset();
    },

    getKras() {
      this.$store.dispatch("getKras", this.$route.query.id || this.query.id);
    },

    addKra() {
      if (this.$refs.form.validate()) {
        this.kraData.performance_year_id = this.$route.query.id
          ? this.$route.query.id
          : this.query.id;
        const payload = { ...this.kraData };
        this.$store.dispatch("addKra", payload).then(() => {
          if (this.kraSuccessSnackbar) {
            this.toggleSnackbar = this.kraSuccessSnackbar;
            this.color = "success";
            this.text = "New KRA successfully added";
            this.dialog = false;
            this.addActive = false;
            this.kraData.title = null;
          } else if (this.kraErrorSnackbar) {
            this.text = "Error in addition";
            this.color = "error";
            this.toggleSnackbar = this.kraErrorSnackbar;
          }

          this.$refs.form.reset();
        });
      }
    },

    updateKra() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.editActive = false;
        this.kraData.performance_year_id = this.$route.query.id
          ? this.$route.query.id
          : this.query.id;
        const payload = { id: this.kraId, formData: this.kraData };
        this.$store.dispatch("updateKra", payload).then(() => {
          if (this.kraSuccessSnackbar) {
            this.kraData.title = null;
            this.toggleSnackbar = this.kraSuccessSnackbar;
            this.text = "KRA successfully edited";
            this.color = "success";
          } else if (this.kraErrorSnackbar) {
            this.toggleSnackbar = this.kraErrorSnackbar;
            this.text = "Error in updation";
            this.color = "error";
          }
          this.$refs.form.reset();
        });
      }
    },
     delay() {
      this.disabled = true;
      this.timeout = setTimeout(() => {
        this.disabled = false;
      }, 3000);
      this.addKra();
    },

    deleteKra(id) {
      // CODE_REVIEW: The actions on request success should be inside then block
      this.deleteDialog = false;
      this.$store
        .dispatch("deleteKra", id)
        .then(() => {
          if (this.kraSuccessSnackbar) {
            this.toggleSnackbar = this.kraSuccessSnackbar;
            this.color = "success";
            this.text = "KRA successfully deleted";
          }
        })
        .catch(() => {
          if (this.kraErrorSnackbar) {
            this.toggleSnackbar = this.kraErrorSnackbar;
            this.text = "KPI exist, so it can't be deleted";
            this.color = "error";
          }
        });
    },
  },
};
</script>
<style scoped>
.v-data-table a {
  text-decoration: none;
}
</style>
