<template>                                              
  <div class="text-left my-6">
    <!-- {{ aggregate_metric }}
    {{ reviewData }} -->
    <!-- {{quarterReviewId}} -->
    Aggregate Score:{{ reviewData.aggregated_metric }}
    <v-checkbox
      @click="checkboxActive = !checkboxActive"
      label="RM Review"
    ></v-checkbox>
    <v-row v-if="checkboxActive === true" class="ml-1">
      <v-col md="3" class="py-3">
        <v-text-field
          dense
          v-model="reviewData.changed_metric"
          type="number"
          label="Aggregate Score "
          :disabled="hide"
        
        ></v-text-field>
      </v-col>
      <v-col md="6" class="py-3">
        <v-text-field
          dense
          v-model="reviewData.comment"
          label="Reason By RM"
          rows="1"
          auto-grow
         :disabled="hide"
        
        ></v-text-field> </v-col
      ><v-col class="text-left mt-1 py-1" md="1" >
        <div v-if="!getRole" class="mb-4 ">
        <v-btn
          small
          color="primary"
          v-if="this.$route.query.status != 'completed'"
          @click="addTeamQuarterlyReview"
          :disbled="getRole"
          
          >update</v-btn
        >
        </div> 
        </v-col
      ><v-col class="text-left py-1 mb-3">
       <v-snackbar
       min-width="0"
        app
        :color="color"
        right
        top
        v-model="toggleSnackbar"
        timeout="2000"
      >
        {{ text }}
      </v-snackbar> <period-reviews /> </v-col>
    </v-row>
  </div>
</template>

<script>
import PeriodReviews from "@/views/performance/reviews/quarterly/PeriodReviews.vue";
import axios from "@/axios";

export default {
  components: {
    PeriodReviews,
  },
  props: {
    kpi_id: {
      type: Number,
      required: true,
    },
    quarterReviewId: {
      required: true,
    },
  },
  data() {
    return {
      checkboxActive: false,
      toggleSnackbar: false,
      hide: false,
      reviewData: {
        performance_quarterly_review_id: null,
        performance_kpi_id: null,
        changed_metric: null,
        comment: null,
      },
      getReviewData: {
        performance_quarterly_review_id: null,
        performance_kpi_id: null,
      },
      role_id:null,
      test:2,
      aggregate_metric: null,
    };
  },
  computed:{
    getRole(){
      if(this.role_id == 1 || this.role_id == 64){
        return true
      }else{
        return false
      }
    }
  },

  mounted() {
    this.getMyteamQuarterlyReview();
    this.findRole();
    if (this.$route.query.status === "completed") {
      this.hide = true;
    }

  },

  methods: {
    getMyteamQuarterlyReview() {
      this.getReviewData.performance_quarterly_review_id = this.quarterReviewId;
      this.getReviewData.performance_kpi_id = this.kpi_id;
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .post(
            "quarterlyreviewkpi-list",
            this.getReviewData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            // console.log(response.data.results[0]);
            // this.reviewData = response.data.results[0];
            if (response.data.results[0]) {
              this.reviewData = response.data.results[0];
              this.aggregated_metric =
                response.data.results[0].aggregated_metric;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    findRole(){
      this.role_id=localStorage.getItem('role_id')
    },

    addTeamQuarterlyReview() {
      this.reviewData.performance_quarterly_review_id =
        this.$route.query.quarterReviewId;
      this.reviewData.performance_kpi_id = this.kpi_id;
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .post(
            "quarterly-review-kpi-update",
            this.reviewData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data.results);
          //  this.$router.go();
            this.hide = true;
            this.toggleSnackbar = true;
            this.text = "Updated Successfully";
            this.color = "success";
            if (response.data.results) {
              this.reviewData.changed_metric =
                response.data.results.changed_metric;
              this.reviewData.comment = response.data.results.comment;
            }
          })
          .catch((error) => {
            console.log(error);
            this.toggleSnackbar = true;
            this.text = error.response.data.message;
            this.color = "error";
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>