<template>
  <v-container>
    <div class="d-flex align-items-center justify-content-between">
      <div class="mt-5 mb-2">
        <h5>Users List</h5>
      </div>
      <div class="">
        <v-btn rounded color="primary" small class="mb-4" @click="addModal">
          Add User
        </v-btn>
        <!-- <p>{{user}}</p> -->

        <v-dialog class="mr-12" v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title
              class="primary justify-content-between"
              v-if="addActive"
            >
              <span class="text-h6 text-white">Add User</span>
              <v-btn
                icon
                color="white"
                class="grey--lighten-7 px-5 caption"
                @click="closeModal"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-title class="primary" v-if="editActive">
              <span class="ttext-h6 text-white">Edit User</span>
            </v-card-title>
            <v-form lazy-validation ref="form" class="px-1">
              <v-card-text>
                <v-container>
                  <v-text-field
                    placeholder="Name"
                    v-model="userData.name"
                    :rules="[rules.required, rules.length]"
                    auto-grow
                    outlined
                    dense
                  ></v-text-field>
                  <!-- <v-col cols="12" sm="6" md="4">
                  <v-text-field label="Employee Number"></v-text-field>
                </v-col> -->

                  <v-text-field
                    placeholder="Email"
                    v-model="userData.email"
                    :rules="[rules.required, rules.email]"
                    auto-grow
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-if="addActive"
                    placeholder="Password"
                    v-model="userData.password"
                    :rules="[rules.required, rules.password, , rules.length]"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    auto-grow
                    outlined
                    dense
                  ></v-text-field>

                  <!-- <v-col cols="12" sm="6">
                  <v-select
                    :items="['Software', 'HR', 'Marketing', 'Accounts']"
                    label="Department"
                  ></v-select>
                </v-col> -->
                  <v-row>
                    <v-col cols="12" sm="6" class="pt-0">
                      <v-select
                        :items="allRoles"
                        item-value="id"
                        item-text="role_name"
                        v-model="userData.role_id"
                        :rules="[rules.required]"
                        placeholder="Role"
                        auto-grow
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0">
                      <v-select
                        v-if="user"
                        v-model="userData.rm_id"
                        :rules="[rules.required]"
                        :items="user"
                        item-value="id"
                        item-text="name"
                        placeholder="Reporting to"
                        auto-grow
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-5 px-5">
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  class="grey--lighten-7 px-5 caption"
                  @click="closeModal"
                >
                  Close
                </v-btn>
                <v-btn
                  v-if="addActive"
                  rounded
                  class="primary caption px-5"
                  color="blue darken-1"
                  @click="storeUser"
                >
                  Save
                </v-btn>
                <v-btn
                  v-if="editActive"
                  rounded
                  class="primary caption px-5"
                  color="blue darken-1"
                  @click="updateUser"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!-- delete modal start -->
        <div>
          <v-row justify="center">
            <v-dialog v-model="deleteDialog" persistent max-width="400">
              <v-card>
                <v-card-title class="primary justify-content-between">
                  <span class="text-h6 text-white">Confirmation</span>
                  <v-btn
                    icon
                    color="white"
                    class="grey--lighten-7 px-5 caption"
                    @click="deleteDialog = false"
                  >
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <div class="subtitle-1 mt-5">
                    Are you sure you want to delete this user ?
                  </div>
                </v-card-text>
                <v-card-actions class="pb-5">
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    color="error"
                    class="caption px-5"
                    @click="deleteUser(deleteId)"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <!-- delete modal ends -->
      </div>
    </div>

    <div class="my-2">
      <v-card outlined class="mx-auto border-0">
        <div class="years-bar">
          <!-- <h6 class="mb-4 mx-auto">Users List</h6> -->
          <div md="4" class="table-search mb-5">
            <v-text-field
              outlined
              dense
              class="pt-0"
              append-icon="mdi-magnify"
              placeholder="Search User"
              v-model="search"
            ></v-text-field>
          </div>
        </div>

        <v-data-table
          v-if="user"
          id="itemList"
          :headers="headers"
          :items="user"
          :search="search"
          :sort-by="['name', 'email', 'role.role_name', 'rm.name']"
          class="elevation-0 table-common"
          
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <div class="options-btns role-options">
                <!-- <p>{{item.name}}</p> -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="icon-box edit-btn"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small @click="editModal(item)">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit User</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="icon-box delete-btn"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small @click="deleteModal(item.id)"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Delete User</span>
                </v-tooltip>
              </div>
              <router-link
                :to="{
                  path: '/performance/kra-kpi',
                  query: {
                    userKraKpiFlag: true,
                    name: item.role.role_name,
                    user_name: item.name,
                    user_id:item.id,
                    role_id:item.role.id
                  },
                }"
              >
                <v-btn
                  rounded
                  color="primary"
                  x-small
                  class="text-white"
                  @click="getKraKpisForUsers(item.role_id)"
                  >KRA KPI VIEW</v-btn
                ></router-link
              >
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <!-- <v-pagination/>
              v-model="page"
              :length="take"
              @input="onPageChange"
            /> -->

          <!-- <b-pagination
          v-model="pagination.page"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          aria-controls="itemList"
          @input="onPageChange"
        /> -->
          
        </div>
        <!-- <v-simple-table height="400">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Email</th>
                <th class="text-left">Role Name</th>
                <th class="text-left">Reporting to</th>
                <th class="text-left">actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in user.data" :key="item.id" class="text-left">
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.role.role_name }}</td>
                <td v-if="item.rm">{{ item.rm.name }}</td>
                <td v-else>--</td>
                <td>
                  <v-icon small class="mr-2" @click="editModal(item)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small class="mr-2" @click="deleteModal(item.id)"
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table> -->
      </v-card>
      <v-snackbar
        min-width="0"
        app
        :color="color"
        right
        top
        v-model="toggleSnackbar"
        timeout="2000"
      >
        {{ text }}
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios";

export default {
  // CODE_REVIEW: Name the component for easier debugging using Vue Devtools
  data: () => ({
    dialog: false,
    deleteDialog: false,
    addActive: false,
    editActive: false,
    showPassword: false,
    toggleSnackbar:null,
    totalPage: 0,
    user: [],
    search: "",
    color:"",
    text:'',
  

    rules: {
      required: (value) => !!value || "Required",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "E-mail must be valid";
      },
      password: (value) =>
        (value && value.length >= 6) || "minimum 6 characters",
      length: (value) =>
        (value && value.length <= 50) || "Maximum 50 characters",
    },
    user_id: null,
    headers: [
      { text: "Name", value: "name" },
      { text: "Email ", value: "email" },
      { text: "Role Name", value: "role.role_name" },
      { text: "Reporting to", value: "rm.name" },

      { text: "Actions", value: "actions" },
    ],
    userData: {
      name: null,
      email: null,
      password: null,
      role_id: null,
      rm_id: null,
    },
    data:{
      role_id:null,
      performance_year_id:null
    }
  }),
  computed: {
    ...mapGetters([
      "allRoles",
      "allKraKpi",
      "SuccessSnackbar",
      "ErrorSnackbar",
    ]),
    roleIdUser() {
      return this.role_id_user;
    },
    // onPageChange() {
    //   return this.page
    // },
  },
  watch: {
    // onPageChange() {
    //   this.getUsers()
    // }
  },
  mounted() {
    this.getUsers();
    // this.$store.dispatch("loadUsers");
    this.$store.dispatch("getRoles");
  },

  methods: {
    // getUsers() {
    //   const token = localStorage.getItem("accessToken");
    //   if (token) {
    //     axios
    //       .get(
    //         "user-view?page=" + this.pagination.page,

    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //         }
    //       )
    //       .then((response) => {
    //         console.log(response.data.results);
    //         this.user = response.data.results;
    //         this.pagination.itemsPerPage = Number(
    //           response.data.results.per_page
    //         );
    //         this.pagination.page = Number(response.data.results.current_page);
    //         this.totalPage = Number(response.data.results.last_page);
    //         console.log("total", this.pagination.total);
    //       });
    //   }
    // },
    getUsers() {
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .get(
            "user-view",

            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data.results);
            this.user = response.data.results;
          });
      }
    },
    onPageChange() {
      this.getUsers();
    },
    addModal() {
      this.dialog = true;
      this.addActive = true;
    },

    closeModal() {
      this.dialog = this.addActive = this.editActive = false;
      this.userData.name = null;
      this.userData.email = null;
      this.userData.password = null;
      this.userData.role_id = null;
      this.userData.rm_id = null;
      this.$refs.form.reset();
    },

    editModal(item) {
      this.dialog = true;
      this.editActive = true;
      this.user_id = item.id;
      this.userData.name = item.name;
      this.userData.email = item.email;
      this.userData.role_id = item.role_id;
      this.userData.rm_id = item.rm_id;
    },
    deleteModal(id) {
      this.deleteDialog = true;
      this.deleteId = id;
    },

    storeUser() {
      if (this.$refs.form.validate()) {
        const payload = { ...this.userData };
        this.$store.dispatch("storeUser", payload).then(() => {
          if (this.SuccessSnackbar) {
            this.dialog = false;
            this.toggleSnackbar = this.SuccessSnackbar;
            this.color = "success";
            this.text = "User successfully added";
            this.addActive = false;
            this.userData.name = null;
            this.userData.email = null;
            this.userData.password = null;
            this.userData.role_id = null;
            this.userData.rm_id = null;
          } else if (this.kraErrorSnackbar) {
            this.text = "Error in addition";
            this.color = "error";
            this.toggleSnackbar = this.ErrorSnackbar;
          }
          this.getUsers();
          this.$refs.form.reset();
        });
      }
    },

    updateUser() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.editActive = false;
        const payload = { id: this.user_id, formData: this.userData };
        this.$store.dispatch("updateUser", payload).then(() => {
          if (this.SuccessSnackbar) {
            this.userData.name = null;
            this.userData.email = null;
            this.userData.password = null;
            this.userData.role_id = null;
            this.userData.rm_id = null;
            this.toggleSnackbar = this.SuccessSnackbar;
            this.text = "successfully edited";
            this.color = "success";
          } else if (this.kraErrorSnackbar) {
            this.toggleSnackbar = this.kraErrorSnackbar;
            this.text = "Error in updation";
            this.color = "error";
          }
          this.getUsers();
        });
      }
    },
    deleteUser(id) {
      this.deleteDialog = false;
      this.$store
        .dispatch("deleteUser", id)
        .then(() => {
          if (this.SuccessSnackbar) {
            this.toggleSnackbar = this.SuccessSnackbar;
            this.color = "success";
            this.text = "successfully deleted";
          }
          this.getUsers();
        })
        .catch(() => {
          if (this.ErrorSnackbar) {
            this.toggleSnackbar = this.ErrorSnackbar;
            this.text = "Error in deletion";
            this.color = "error";
          }
        });
    },
    getKraKpisForUsers(item) {
      this.data.role_id = item;
      this.$store.dispatch("getKraKpis", this.data);
    },
  },
};
</script>
