<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$route.query.quarterlyAddReviewFlag != true"
          text
          color="info "
          v-bind="attrs"
          v-on="on"
          small
          @click="periodReviews"
        >
          detail view
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="primary">
          <span class="text-h6 text-white">Period Reviews</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-card
              outlined
              class="mx-auto my-2"
              color="grey lighten-4"
              v-for="(review, index) in reviewsMonthly"
              :key="index"
            >
              <v-card-title
                class="overline text-uppercase text-decoration-underline py-1 ml-5"
                >{{ review.month }}</v-card-title
              >
              <v-card-text
                v-for="(item, index) in review.self_review_kpis"
                :key="index"
              >
                <v-col class="caption py-1" md="">
                  <div
                    class="custom-transform-class text-left font-weight-bold text-none"
                  >
                    Comment : {{ item.comment }}
                   
                  </div>
                </v-col>
                <v-row class="ml-1">
                  <!-- <v-col class="caption py-1" md="">
                    <div
                      class="
                        custom-transform-class text-left
                        font-weight-bold
                        text-none"
                    >
                      Comment : {{ item.comment }}
                    </div>
                  </v-col> -->
                  <v-col class="caption text-left py-1" md="4">
                    <div
                      class="custom-transform-class font-weight-bold text-none"
                    >
                      Expected : {{ item.expectation }}
                    </div>
                  </v-col>
                  <v-col class="caption text-left py-1" md="4">
                    <div
                      class="custom-transform-class font-weight-bold text-none"
                    >
                      Achivement : {{ item.metric }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5 px-5">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="grey--lighten-7 px-5 caption"
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "@/axios";
export default {
  data() {
    return {
      dialog: false,
      reviewData: {
        performance_kpi_id: "",
        quarter: "",
      },
      reviewsMonthly: [
        // {
        //   month: "April",
        //   expected: "5000",
        //   achivement: "1000",
        //   comment:
        //     "a verbal or written remark expressing an opinion or reaction.",
        // },
        // {
        //   month: "May",
        //   expected: "2000",
        //   achivement: "800",
        //   comment: "a whole formed by combining several separate elements.",
        // },
        // {
        //   month: "june",
        //   expected: "3000",
        //   achivement: "1500",
        //   comment: "a whole formed by combining several separate elements.",
        // },
      ],
    };
  },
  props: {
    kpi_id: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  mounted() {},
  methods: {
    periodReviews() {
      this.reviewData.performance_kpi_id = this.kpi_id;
      this.reviewData.user_id = this.$route.query.userId;
      this.reviewData.quarter = this.$route.query.quarter;
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .post("detail_review", this.reviewData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.reviewsMonthly = response.data.results;
          })
          .catch(() => {});
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>
<style scoped>
/* .comment {
  margin: 0;
  padding: 0;
  text-align: justify;
} */
</style>
