<template>
  <v-app class="login-app">
    <v-content>
      <v-card max-width="475" class="mx-auto my-16 pt-6 loginbox" elevation="20">
        <v-card-title> 
          <h6 class="subtitle-1 font-weight-bold text-center w-100 my-2">Please enter OTP to verify your account</h6> </v-card-title>
        <v-card-text class="pb-0 px-5">
          An OTP has been send to {{ $route.query.email }}
          <div>
            <router-link to="/forgot-password">Wrong email ?</router-link>
          </div>
          <v-col>
            <v-otp-input
              :disabled="loading"
              @finish="onFinish"
              length="6"
            ></v-otp-input>
            <v-overlay absolute :value="loading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-overlay>
          </v-col>
        </v-card-text>

        <div>
          <v-btn
            @click="delay"
            :disabled="disabled"
           class="px-5 caption" rounded color="primary"
          >
            Resend OTP
          </v-btn>
          <div><router-link to="/login">Login</router-link></div>
        </div> </v-card
      ><v-snackbar
      min-width="0"
        app
        right
        top
        :color="color"
        elevation="24"
        shaped
        v-model="show"
        :timeout="5000"
        >{{ text }}</v-snackbar
      >
    </v-content>
  </v-app>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      loading: false,
      disabled: false,
      timeout: null,
      mail: {
        email: "",
      },
      show: false,
      text: "",
      color: "",
      data: {
        token: "",
      },
      secret: "123#$%",
      pvtData: "",
    };
  },

  methods: {
    delay() {
      this.disabled = true;
      this.timeout = setTimeout(() => {
        this.disabled = false;
      }, 3000);
      this.resentOtp();
    },

    onFinish(res) {
      this.data.token = res;
      axios
        .post("verify-otp", this.data)
        .then((response) => {
          console.log(response);
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.$router.push(
              {
                name: "Change Password",
              },
              3000
            );
          });
          var CryptoJS = require("crypto-js");
          var data = CryptoJS.AES.encrypt(
            this.data.token,
            this.secret
          ).toString();
          localStorage.setItem("otp", data);
        })
        .catch((error) => {
          console.log(error);
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.show = true;
            this.text = "Invalid OTP";
            this.color = "red accent-2";
            // CODE_REVIEW: Use component name to push to
            this.$router.push("/one-time-password");
          }, 3500);
        });
    },

    resentOtp() {
      this.mail.email = this.$route.query.email;
      axios
        .post("forgot", this.mail)
        .then(() => {
          this.show = true;
          this.text = "Resent OTP successfull";
        });
    },
  },
};
</script>
