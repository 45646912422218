<template>
  <div><router-view/></div>
</template>

<script>

export default {
  components: {
  },
  data(){
    return{
      yearShow:false
    }
  }
};
</script>
