import axios from "@/axios";
import router from '@/router'



const state = {
  kpiRolesList: [],
  // CODE_REVIEW: Initialize this value with a Boolean since that is the type of value that is used
  kpiRoleSuccessSnackbar: false,
  kpiRoleErrorSnackbar: false,
  kpiRoleErrorMessage: []
};

const getters = {
  allKpiRoles: state => state.kpiRolesList,
  kpiRoleSuccessSnackbar: state => state.kpiRoleSuccessSnackbar,
  kpiRoleErrorSnackbar: state => state.kpiRoleErrorSnackbar,
  kpiRoleErrorMessage: state => state.kpiRoleErrorMessage,
};

const mutations = {
  ADD_KPI_ROLE(state, data) {
    state.kpiRolesList.push(data);
    state.kpiRoleSuccessSnackbar = true;
  },
  GET_KPI_ROLES(state, data) {
    state.kpiRolesList = data;
  },
  UPDATE_KPI_ROLE(state, data) {
    const index = state.kpiRolesList.findIndex(kpiRolesList => kpiRolesList.id === data.id);
    if (index !== -1) {
      state.kpiRolesList.splice(index, 1, data);
    }
    state.kpiRoleSuccessSnackbar = true;
  },
  DELETE_KPI_ROLE(state, id) {
    state.kpiRolesList = state.kpiRolesList.filter(
      (kpiRolesList) => kpiRolesList.id !== id
    );
    state.kpiRoleSuccessSnackbar = true;
  }
};

const actions = {
  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async addKpiRole({ commit, state }, KpiRolesData) {
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    state.kpiRoleErrorMessage = []
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .post(
          "performance-role-kpi-store",
          KpiRolesData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // coverting first letter of a string in JSON response to caps starts
          const capsKpiRoles = response.data.results;

          let userObj = JSON.stringify(capsKpiRoles, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });

          var parsed = JSON.parse(userObj);

          // coverting first letter of a string in JSON response to caps ends
          commit("ADD_KPI_ROLE", parsed);
        })
        .catch((error) => {
          // CODE_REVIEW: The request failure status is not handled properly here.
          // Error handler here only just logs the error and will not pass error exception to the caller.
          // So the request always appears to succeed to the caller. Need to rethrow error if want it to be propagated to caller.
          console.log(error);
          state.kpiRoleErrorSnackbar = true;
          state.kpiRoleErrorMessage = error.response.data.message
          throw new Error(error)
        });
    } else {
      router.push({ path: "/login" });
    }
  },
  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js    lines - 38
  async getKpiRoles({ commit, state }, data) {
    const token = localStorage.getItem("accessToken");
    state.kpiRolesList = []
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .get(
          "performance-role-kpi-show/" +
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // coverting first letter of a string in JSON response to caps starts
          const capsKpiRoles = response.data.results;

          let userObj = JSON.stringify(capsKpiRoles, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });

          var parsed = JSON.parse(userObj);

          // coverting first letter of a string in JSON response to caps ends
          commit("GET_KPI_ROLES", parsed);
        })
        .catch((response) => {
          console.log(response.message);
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async updateKpiRole({ commit }, payload) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .put(
          "performance-role-kpi-update/" +
          payload.id,
          payload.formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // coverting first letter of a string in JSON response to caps starts
          const getAllYears = response.data.results;
          let userObj = JSON.stringify(getAllYears, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });
          console.log(userObj);
          var parsed = JSON.parse(userObj);
          console.log(parsed);
          // coverting first letter of a string in JSON response to caps ends
          commit("UPDATE_KPI_ROLE", parsed);
        })
        .catch((error) => {
          // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 71
          console.log(error);
          state.kpiRoleErrorSnackbar = true;
          throw new Error(error)
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async deleteKpiRole({ commit }, id) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .delete(
          "performance-role-kpi-delete/" +
          id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          commit("DELETE_KPI_ROLE", id);
        })

        .catch((error) => {
          // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 71
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },
};

export default { state, mutations, actions, getters };


