const state = {
   query: {},
   queryKra: {},
   queryKpi: {}
  };

  const mutations = {
    SET_QUERY_PARAMS(state, data) {
      state.query = data
      console.log("data from bread-crumb.js SET_QUERY_PARAMS", data)
    },
    SET_QUERY_PARAMS_KRA(state, data) {
      state.queryKra = data
      console.log("data from bread-crumb.js SET_QUERY_PARAMS_KRA", data)
    },
    SET_QUERY_PARAMS_KPI(state, data) {
      state.queryKpi = data
      console.log("data from bread-crumb.js SET_QUERY_PARAMS_KPI", data)
    },

  };
  const getters = {
    allQuery: state => state.query,
    allQueryKra: state => state.queryKra,
    allQueryKpi: state => state.queryKpi
  };

  
  export default { namespaced: true, state, mutations, getters };
