<template>
  <v-container class="py-0">
    <div class="d-flex align-items-center justify-content-between">
      <div class="mt-5 mb-2">
        <h5 class="my-1">Role List</h5>
      </div>
      <div class="">
        <v-btn rounded color="primary" class="mb-2" small @click="addModal">
          Add Roles
        </v-btn>

        <!-- modal to add and edit role -->

        <!-- <v-dialog class="mr-12" v-model="dialog" max-width="400">
          <v-card>
            <v-card-title class="primary" v-if="addActive">
              <span class="text-h6 text-white">Add a role</span>
            </v-card-title>
            <v-card-title class="primary" v-if="editActive">
              <span class="text-h6 text-white">Edit role details</span>
            </v-card-title>
            <v-form lazy-validation ref="form">
              <v-card-text>
                <v-text-field
                  label="Role Name"
                  v-model="roleData.role_name"
                  :rules="[rules.required, rules.length]"
                  required
                ></v-text-field>
              </v-card-text>
              <v-card-actions class="pb-5 px-5">
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  class="grey--lighten-7 px-5 caption"
                  @click="closeModal"
                >
                  Close
                </v-btn>
                <v-btn
                  v-if="addActive"
                  rounded
                  class="primary caption px-5"
                  color="blue darken-1"
                  @click="addRole"
                >
                  Save
                </v-btn>
                <v-btn
                  v-if="editActive"
                  rounded
                  class="primary caption px-5"
                  color="blue darken-1"
                  @click="updateRole"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog> -->

        <!-- modal to add and edit role -->
        <v-dialog class="mr-12" v-model="dialog" persistent max-width="400">
          <v-card>
            <v-card-title
              class="primary justify-content-between"
              v-if="addActive"
            >
              <span class="text-h6 text-white">Add a role</span>
              <v-btn
                icon
                color="white"
                class="grey--lighten-7 px-5 caption"
                @click="closeModal"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-title
              class="primary justify-content-between"
              v-if="editActive"
            >
              <span class="text-h6 text-white">Edit role details</span>
              <v-btn
                icon
                color="white"
                class="grey--lighten-7 px-5 caption"
                @click="closeModal"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-form lazy-validation ref="form" class="pt-4 px-3">
              <v-card-text>
                <v-text-field
                  placeholder="Role Name"
                  v-model="roleData.role_name"
                  :rules="[rules.required, rules.length]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-card-text>
              <v-card-actions class="pb-5 px-5">
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  class="grey--lighten-7 px-5 caption"
                  @click="closeModal"
                >
                  Close
                </v-btn>
                <v-btn
                  v-if="addActive"
                  rounded
                  class="primary caption px-5"
                  color="blue darken-1"
                  @click="addRole"
                >
                  Save
                </v-btn>
                <v-btn
                  v-if="editActive"
                  rounded
                  class="primary caption px-5"
                  color="blue darken-1"
                  @click="updateRole"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!-- delete modal start -->
        <div>
          <v-row justify="center">
            <v-dialog v-model="deleteDialog" persistent max-width="400">
              <v-card>
                <v-card-title class="primary d-flex justify-content-between">
                  <span class="text-h6 text-white">Confirmation</span>
                  <v-btn
                    icon
                    color="white"
                    class="grey--lighten-7 px-5 caption"
                    @click="deleteDialog = false"
                  >
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <div class="subtitle-1 mt-5">
                    Are you sure you want to delete this KPI?
                  </div>
                </v-card-text>
                <v-card-actions class="pb-5 px-5">
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    color="error"
                    class="caption px-5"
                    @click="deleteRole(deleteId)"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <!-- delete modal ends -->
      </div>
    </div>
    <div>
      <v-row>
        <v-col
          md="4"
          outlined
          class="my-2 py-0"
          v-for="roles in allRoles"
          :key="roles.role_name"
        >
          <div class="bg-light role-boxes">
            <div class="role-name text-left">
              {{ roles.role_name }}
            </div>
            <div>
              <div class="text-right mr-4">
                <!-- <v-btn
                  class="icon-box"
                  icon
                  :to="{
                    path: '/settings/roles/roles-per-kra-kpi',
                    query: { id: roles.id },
                  }"
                >
                  <v-icon small>mdi-eye</v-icon>
                </v-btn> -->
                <!-- <v-btn
                  class="icon-box"
                  icon
                  :to="{
                    path: '/settings/roles/employee-access-page',
                    query: { id: roles.id, name: roles.role_name },
                  }"
                >
                  <v-icon small>mdi-key-variant</v-icon>
                </v-btn> -->

                <div class="button-box d-flex">
                  <v-btn
                    rounded
                    color="primary"
                    class="my-2 mr-2"
                    :to="{
                      path: '/settings/roles/roles-per-kra-kpi',
                      query: { role_id: roles.id,name: roles.role_name },
                    }"
                    small
                    elevation="0"
                    >View</v-btn
                  >
                  <v-btn
                    rounded
                    color="white"
                    class="my-2 text-dark"
                    small
                    :to="{
                      path: '/settings/roles/employee-access-page',
                      query: { id: roles.id, name: roles.role_name },
                    }"
                    elevation="0"
                    >Give Access</v-btn
                  >
                </div>
                <div class="options-btns role-options">
                  <v-btn icon class="icon-box edit-btn">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon small @click="editModal(roles)"
                            >mdi-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn icon class="icon-box delete-btn">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon small @click="deleteModal(roles.id)"
                            >mdi-delete</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Delete </span>
                    </v-tooltip>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div>
        <router-view></router-view>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: { ...mapGetters(["allRoles"]) },

  mounted() {
    this.$store.dispatch("getRoles");
  },

  data() {
    return {
      deleteDialog: false,
      dialog: false,
      addActive: false,
      deleteId: null,
      editActive: false,
      roleId: null,
      roleData: {
        role_name: null,
      },
      rules: {
        required: (value) => !!value || "Required",
        length: (value) =>
          (value && value.length <= 250) || "Maximum 250 characters",
      },
    };
  },

  methods: {
    addModal() {
      this.dialog = true;
      this.addActive = true;
    },

    editModal(item) {
      this.dialog = true;
      this.editActive = true;
      this.roleId = item.id;
      this.roleData.role_name = item.role_name;
    },
    deleteModal(id) {
      this.deleteDialog = true;
      this.deleteId = id;
    },

    closeModal() {
      this.dialog = this.addActive = this.editActive = false;
      this.roleData.role_name = null;
      this.$refs.form.reset();
    },

    addRole() {
      if (this.$refs.form.validate()) {
        const payload = { ...this.roleData };
        this.$store.dispatch("addRole", payload).then(() => {
          this.dialog = false;
          this.addActive = false;
          this.roleData.role_name = null;
          this.$store.dispatch("getRoles");
          this.$refs.form.reset();
        });
      }
    },

    updateRole() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.editActive = false;
        const payload = { id: this.roleId, formData: this.roleData };
        this.$store.dispatch("updateRole", payload).then(() => {
          this.roleData.role_name = null;
        });
      }
    },

    deleteRole(id) {
      this.deleteDialog = false;
      // CODE_REVIEW: Make sure delete operation succeeds
      this.$store.dispatch("deleteRole", id);
    },
  },
};
</script>
