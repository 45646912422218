import axios from "@/axios";
import router from '@/router'

const state = {
  quarterList: [],
};

const getters = {
  quartersList: state => state.quarterList,
};

const mutations = {
  GET_QUARTER_CARD(state, data) {
    state.quarterList = data;
  },

};

const actions = {
  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async getQuarterCard({ commit, state }, data) {
    state.quarterList = []
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .post(
          "quarterlyreview-list",
          { user_id: data },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log(response.data.results);
          commit("GET_QUARTER_CARD", response.data.results);
        })
        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },
};

export default { state, mutations, actions, getters };