import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/login/Login.vue";
import DashboardLayout from "../views/DashboardLayout.vue";
import Dashboard from "../views/Dashboard.vue";
import QuarerReviewPending from "../views/dashboard/QuarerReviewPending.vue";
import performance from "@/views/Performance.vue";
import MyTeam from "../views/MyTeam.vue";
import Users from "../views/Users.vue";
//import Settings from "../views/Settings.vue";
import UserProfile from "../views/users/UserProfile.vue";
// import userKraKpiView from "../views/users/userKraKpiView.vue";
import ForgotPassword from "../views/login/ForgotPassword.vue";
import OneTimePassword from "../views/login/OneTimePassword.vue";
import ChangePassword from "../views/login/ChangePassword.vue";
import CheckUserEmail from "../views/login/CheckUserEmail.vue";

import TestTeams from "../views/users/TestTeams.vue";
import MonthCard from "../views/performance/reviews/monthly/MonthCard.vue";
import QuarterlyCard from "../views/performance/reviews/quarterly/QuarterlyCard.vue";
import MonthlyKraKpiReview from "../views/performance/reviews/monthly/MonthlyKraKpiReview.vue";
import QuarterlyKraKpiReview from "../views/performance/reviews/quarterly/QuarterlyKraKpiReview.vue";
import PeriodReviews from "../views/performance/reviews/quarterly/PeriodReviews.vue";
import DashboardTab from "../views/performance/DashboardTab.vue";
import MyTeamDashboard from "../views/my-team/MyTeamDashboard.vue";
import KraKpiTab from "../views/performance/KraKpiTab.vue";
import MainReviewsTab from "../views/performance/MainReviewsTab.vue";
import AdminTab from "../views/performance/AdminTab.vue";
import Year from "@/views/performance/admin/Year.vue";
// import MonthlyTeamLeadView from "../views/my-team/monthly/MonthlyTeamLeadView.vue";
import QuarterlyReview from "../views/my-team/quarterly/QuarterlyReview.vue";
import Kras from "../views/performance/admin/Kras.vue";
import Kpis from "../views/performance/admin/Kpis.vue";
import KpiRoles from "../views/performance/admin/assign-roles/KpiRoles.vue";
// import KpiRolesTable from "../views/performance/admin/assign-roles/KpiRolesTable.vue"
import MonthTabs from "../views/my-team/MonthTabs.vue";
import MonthlyReview from "../views/my-team/monthly/MonthlyReview.vue";
// import ReviewCard from "../views/my-team/quarter/ReviewCard.vue"
import SettingContents from "../views/settings/SettingContents.vue";
import SettingsTab from "../views/settings/SettingsTab.vue";
import AccessKey from "../views/settings/AccessKey.vue";
import RolesPreview from "../views/settings/RolesPreview.vue";
import NoAccess from "../views/users/NoAccess.vue";

Vue.use(VueRouter);

// CODE_REVIEW: Make the components import b  elow lazy loaded to allow it to be split into chunks fr better loading performance. Give related components the same webpackChunkName for them to be put in the same chunk
// eg: component: () => import(/* webpackChunkName: "auth" */ '../views/login/ForgotPassword.vue')
function guardMyroute(to, from, next) {
  var isAuthenticated = false;

  if (localStorage.getItem("LoggedUser")) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) {
    next();
  } else {
    next("/login");
  }
}
const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "",
    component: ForgotPassword,
  },
  {
    path: "/one-time-password",
    name: "One Time Password",
    component: OneTimePassword,
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: ChangePassword,
  },
  {
    path: "/no-access",
    name: "No Access",
    component: NoAccess,
  },
  {
    path: "/",
    name: "home",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        beforeEnter: guardMyroute,
        component: Dashboard,
        children: [
          {
            path: "quarter-review-pending",
            name: "Quarter review pending",
            component: QuarerReviewPending,
          },
        ],
      },
      
      {
        path: "performance",
        component: performance,
        // redirect: "/dashboard",
        children: [
          {
            path: "dashboard",
            name: "Performance dashboard",
            component: DashboardTab,
          },
          {
            path: "kra-kpi",
            name: "Kra kpi",
            component: KraKpiTab,
          },
          {
            path: "reviews",
            name: "reviews",
            component: MainReviewsTab,
            children: [
              {
                path: "monthly",
                name: "Monthly",
                component: MonthCard,
                meta: { value: "monthly" }
              },
              {
                path: "quarterly",
                name: "Quarterly",
                component: QuarterlyCard,
              },
             
              {
                path: "monthly/monthly-reviews",
                name: "monthly-reviews",
                component: MonthlyKraKpiReview,
                meta: { value: "monthly-reviews" }

              },
             
              {
                path: "quarterly/quarterly-reviews",
                name: "Quarterly KRA KPI Reviews",
                component: QuarterlyKraKpiReview,
              },
              
            ],
          },
          
          {
            path: "admin",
            name: "admin",
            component: AdminTab,
            children: [
              {
                path: "",
                name: "Year",
                component: Year,
                meta: { value: "year" },
              },
              {
                path: "year/kras",
                name: "kras",
                component: Kras,
                meta: { value: "year/kras" },
              },
              {
                path: "year/kras/kpis",
                name: "kpis",
                component: Kpis,
                meta: { value: "year/kras/kpis" },
              },
              {
                path: "year/kras/kpis/kpi-roles",
                name: "Assign Kpi Roles",
                component: KpiRoles,
                meta: { value: "year/kras/kpis/kpiroles" },
              },
            ],
          },
        ],
      },
      {
        path: "myteam",
        name: "My Team",
        component: MyTeam,
        children: [
          {
            path: "team-member-dashboard",
            name: "My Team Dashboard",
            component: MyTeamDashboard,
          },
          {
            path: "team-member-kra-kpi",
            name: "Team Member Kra-Kpi",
            component: KraKpiTab,
          },
          {
            path: "team-member-reviews",
            name: "Team Member Reviews",
            component: MonthTabs,
            children: [
              {
                path: "team-member-monthly",
                name: "Team Member Monthly",
                component: MonthCard,
              },
              {
                path: "team-member-monthly/team-member-monthly-reviews",
                name: "Team Member Monthly Reviews",
                component: MonthlyReview,
              },
              {
                path: "team-member-quarterly",
                name: "Team Member Quarterly",
                component: QuarterlyCard,
              },
              {
                path: "team-member-quarterly/team-member-quarterly-reviews",
                name: "Team Member Quarterly Review",
                component: QuarterlyReview,
              },
            ],
          },
        ],
      },
      {
        path: "users",
        name: "Users",
        component: Users,
      },
      {
        path:"check-user-email",
        name:"check-user-email",
        component:CheckUserEmail

      },
     
      {
        path: "user-profile",
        name: "User Profile",
        component: UserProfile,
      },
      {
        path: "settings",
        name: "Settings",
        component: SettingsTab,
        children: [
          {
            path: "roles",
            name: "Roles",
            component: SettingContents,
          },
          {
            path: "roles/employee-access-page",
            name: "Employee Access Page",
            component: AccessKey,
          },
          {
            path: "roles/roles-per-kra-kpi",
            name: "KRA-KPI Per Roles",
            component: RolesPreview,
          },
        ],
      },
      {
        path: "test-teams",
        name: "Test Team",
        component: TestTeams,
      },
      {
        path: "period-reviews",
        name: "Period Reviews",
        component: PeriodReviews,
      },
    ],
  },
  // {
  //   path: '/about',
  //   name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //   component: () => import(/ webpackChunkName: "about" / '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

export default router;
