<template>
  <div>
    <div class="">
      <rm-details />
    </div>
     <div class="kra-boxes" 
      v-for="roles in allKraKpi.performance_kras"
      :key="roles.id">
      <div class="kra-title">
        <h5 class="ttl">{{ roles.title }}</h5>
        <div class="weighage">
          <h5 class="my-2">Total Weightage: </h5>
        <h4 class="mb-0">{{ roles.weightage }}</h4> 
        </div>
      </div> 
       <key-performance-index
            class="mb-1"
            :performance-kpis="roles.performance_kpis"
            :userId="userId"
            @totalWeightageSum="sumWeightage"
          ></key-performance-index>
      </div>

    <!-- <div class="kra-boxes" v-for="(item,index) in allKraKpi.performance_kras" :key="index">
      <v-card
        elevation="0"
        class="font-weight-bold mx-auto color-light-grey"
      >
        <v-list-item class="dark--text mt-2 overline">
          {{ item.title }}<v-spacer></v-spacer>weightage: {{item.weightage}}
        </v-list-item>
      </v-card>
      <div>
        <div class="mt-1">
          <key-performance-index
            class="mb-1"
            :performance-kpis="item.performance_kpis"
            @totalWeightageSum="sumWeightage"
          ></key-performance-index> -->
  </div>
</template>

<script>
import RmDetails from "@/views/performance/reviews/quarterly/RmDetails.vue";
import KeyPerformanceIndex from "@/views/performance/kra-kpi/KeyPerformanceIndex.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    RmDetails,
    KeyPerformanceIndex,
  },
  computed: { ...mapGetters(["allKraKpi"]) },
  
  created() {
    this.role_id = localStorage.getItem("role_id");
  },
  mounted() {
    this.getKraKpis();
  },
  data() {
    return {
      role_id: null,
      isNext: true,
      isSubmit: false,
      kraIndex: 0,
      totalWeightageKra: ''
    };
  },
  methods: {
   
    getKraKpis() {
      this.$store.dispatch("getKraKpis", this.role_id);
    },
    sumWeightage(totalSum) {
      console.log("total sum from kra kpi", totalSum);
      this.totalWeightageKra = totalSum;
    },
  },
};
</script>