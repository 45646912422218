<template>
  <div v-if="pendingDetailsMonth.length">
     <div v-if="$route.query.tab == 'monthtab'">

    <h5 class="table-ttl" >Pending List</h5>
    <div class="table-common">
      <table class="w-100">
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" scope="col" class="text-start">
              <span>Employee Name</span>
            </th>
            <th role="columnheader" scope="col" class="text-start">
              <span>Email Address</span>
            </th>
            <th role="columnheader" scope="col" class="text-start">
              <span>Months Pending</span>
            </th>
          </tr>
        </thead>
        
        <tbody v-for="(item,index) in pendingDetailsMonth" :key="index">
          <tr class="">
            <td class="text-start" v-if="item">{{item.name}}</td>
            <td class="text-start" v-if="item">{{item.email}}</td>
            <td class="text-start" v-for="(subitem,index) in item.month" :key="index">{{subitem}}</td>
          </tr>
          <!-- <tr class="">
            <td class="text-start">Lekshmi</td>
            <td class="text-start">lekshmi@cubettech.com</td>
            <td class="text-start">February</td>
          </tr>
          <tr class="">
            <td class="text-start">Anju P Lekshmi</td>
            <td class="text-start">anju@cubettech.com</td>
            <td class="text-start">March</td>
          </tr> -->
        </tbody>
       
      </table>
    </div>
    </div>
  </div>

</template>

<script>
import axios from "@/axios";

export default {
 
  
  data: () => ({
    pendingData: {
     rm_id: '',
    },
    pendingDetailsMonth: ''
  
  }),

   mounted() {
     this.teamsPendingList();
   },

  created() {
    
  },

  methods: {
   teamsPendingList() {
      const token = localStorage.getItem("accessToken");
      this.pendingData.rm_id = localStorage.getItem("user_id")
      if (token) {
        axios
          .post(
            "pendingMonthlyReview",
            this.pendingData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log("pendinglist------",response.data.results);
            this.pendingDetailsMonth = response.data.results;
             this.$emit("pendingDetailsMonth", this.pendingDetailsMonth);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};


</script>
