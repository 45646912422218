<template>
  <v-app class="login-app">
    <v-main>
      <v-card max-width="475" class="mx-auto my-16 pt-6 loginbox" elevation="0">
        <v-card-title>
          <h6 class="subtitle-1 font-weight-bold text-center w-100 my-2">
            Change Password
          </h6></v-card-title
        >
        <v-form lazy-validation ref="form"
          ><v-card-text class="pb-0 px-5">
            <div>
              <v-text-field
                placeholder="New Password"
                v-model="formData.password"
                outlined
                :rules="[rules.required, rules.passwordRules]"
                :type="showPassword ? 'text' : 'password'"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                @change="touchInputField"
                id="newpassword"
              />
            </div>
            <div>
              <v-text-field
                placeholder="Confirm New Password"
                v-model="formData.confirm_password"
                outlined
                :rules="[rules.required, rules.confirmPasswordRules]"
                :type="showPassword ? 'text' : 'password'"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                @change="touchInputField"
                id="confirmpassword"
                max-width="30"
              />
            </div>
             </v-card-text
          ><v-row class="pb-5 px-1">
            <v-col class="text-left ml-5">
              <div><router-link to="/login">Back to login</router-link></div>
            </v-col>
            <v-col class="text-right mr-4">
              <div>
                <v-btn
                  :disabled="hide"
                  @click="changePassword"
                  class="px-5 caption"
                  rounded
                  color="primary"
                >
                  submit
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form> </v-card
      ><v-snackbar
      min-width="0"
        app
        right
        top
        color="success"
        elevation="24"
        shaped
        v-model="show"
        timeout="5000"
        >{{ text }}</v-snackbar
      >
    </v-main>
  </v-app>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      showPassword: false,
      disabled: false,
      display:false,
      timeout: null,
      show: false,
      text: "",
      hide: true,

      // CODE_REVIEW: Move secret to env file
      secret: "123#$%",
      formData: {
        token: null,
        password: null,
        confirm_password: null,
      },
      rules: {
        required: (value) => !!value || "Required",
        passwordRules: (value) =>
          (value && value.length >= 8) || "minimum 8 characters",
        confirmPasswordRules: (value) =>
          value === this.formData.password ||
          "The password confirmation does not match.",
      },
      // passwordRules: [
      //   (value) => !!value || "Required",
      //   (value) => (value && value.length >= 8) || "minimum 8 characters",
      // ],
      // confirmPasswordRules: [
      //   (value) => !!value || "Required",
      //   (value) =>
      //     value === this.formData.password ||
      //     "The password confirmation does not match.",
      // ],
    };
  },
  computed:{
     
  },

  methods: {
    delay() {
      this.disabled = true;
      this.timeout = setTimeout(() => {
        this.disabled = false;
      }, 3000);
      this.changePassword();
    },
    touchInputField() {
    
      this.hide = false;
    },

    changePassword() {
      var secretToken = localStorage.getItem("otp");
      var CryptoJS = require("crypto-js");
      var decToken = CryptoJS.AES.decrypt(secretToken, this.secret).toString(
        CryptoJS.enc.Utf8
      );

      if (this.$refs.form.validate()) {
        this.formData.token = decToken;
        axios
          .post("reset", this.formData)
          .then((response) => {
            console.log(response);
            this.show = true;
            this.text = "Password reset successful";
            setTimeout(() => {
              this.$router.push("/login");
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
