  <template>
   <div class="performance-scrore">
          <h6>Performance Score? {{this.totalScore.Total_score}}</h6>
          <!-- <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod
          </p> -->
        
           <div id="chart">
        <v-progress-circular
      
      :size="300"
      :width="30"
      :value="totalScore.Total_score"
      color="#9E8FFF"
    >
      {{ totalScore.Total_score }}
    </v-progress-circular>
      </div>
    
  <!-- <img src="@/assets/images/progress-img.png" alt="" class="mt-3" /> -->
        </div>

         <!-- <div class="text-center ">
    <v-progress-circular
      
      :size="300"
      :width="25"
      :value="value"
      color="teal"
    >
      {{ value }}
    </v-progress-circular>
</div> -->


  </template>
  <script>
  export default {
  props: {
      totalScore: {
        type: Array
      }
  },
  data() {
    return {
      enabled: false,
       interval: {},
        value: 0,
    //   Total_Score: 75,
    //   series: this.Total_Score,
        //   chartOptions: {
        //     chart: {
        //       type: 'donut',
        //     },
        //     responsive: [{
        //       breakpoint: 480,
        //       options: {
        //         chart: {
        //           width: 200
        //         },
        //         legend: {
        //           position: 'bottom'
        //         }
        //       }
        //     }]
        //   },
    };
  },
    // beforeDestroy () {
    //   clearInterval(this.interval)
    // },
    mounted () {
    this.value = this.totalScore.Total_score
    
    },
  
 
};
  </script>