<template>
  <div>
    <v-dialog v-model="dialog" max-width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="mb-5" dark v-bind="attrs" v-on="on" small>
          Configure KRA-KPI 
        </v-btn>
      </template>
         <v-card v-if="kraStatus === 'active'">
        <v-card-title class="primary justify-content-between">
          <span class="text-h6 text-white">Warning</span>
          <v-btn
            icon
            color="white"
            class="grey--lighten-7 px-5 caption"
            @click="dialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="subtitle-1 mt-5">
            Cannot be edited since the year is active!!!
          </div>
        </v-card-text>
        <v-card-actions class="pb-5 px-5">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      
      <v-card flat v-else>
      <v-form ref="form">
        <v-card-title class="primary justify-content-between">
          <span class="text-h6 text-white">Configure KRA-KPI</span>
           <v-btn
                icon
                color="white"
                class="grey--lighten-7 px-5 caption"
                @click="closeModal"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
        </v-card-title>
        <div v-for="kra in kraKpiList.performance_kras" :key="kra.id" class="configure-popup px-3">
          <v-card-text class="text-left">
            <h6 class="subtitle-2">{{ kra.title }}</h6>
            <div v-for="kpi in kra.performance_kpis" :key="kpi.id">
              <configure-kras :kpi="kpi"/>
            </div>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="grey--lighten-7 px-5 caption mb-4"
            @click="closeModal"
          >
            Close
          </v-btn>
        </v-card-actions>
         </v-form>
      </v-card>
     
    </v-dialog>
  </div>
</template>



<script>
import ConfigureKras from "./ConfigureKras.vue";
import axios from "@/axios";

export default {
  components: {
    ConfigureKras,
  },
  props:{
     kraStatus: {
      type: [String],
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      showButton: "true",
      // year_id: 139,
      kraKpiList: null,
    };
  },
  mounted() {
    this.getKrasAndKpisBasedOnYear();
  },

  methods: {
      closeModal() {
      this.dialog = false;
      // window.location.reload();
      this.$refs.form.reset();
    },
    getKrasAndKpisBasedOnYear() {
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .get(
            "year-kra-kpi",
           
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            this.kraKpiList = response.data.results[0];
            console.log("response.data.results",response.data.results[0])
            this.kraId = response.data.results.performance_kras;
            
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>



