<template>

  <div class="tab-custom">
    <div class="back">
      <div v-if="$route.query.user_name">
        <v-btn rounded color="primary" small class="mb-4" @click="backToUsers">
          Back
        </v-btn>
      </div>
      <div class="heading">
        <h5
          v-if="$route.query.userKraKpiFlag"
          color="primary"
          class="mb-4 mx-auto"
        >
          Kra - Kpi of {{ $route.query.name }}-{{ $route.query.user_name }}
        </h5>
      </div>
      <div class="month-release" v-if="$route.query.userKraKpiFlag">
        <v-btn
          rounded
          color="primary"
          small
          class="mb-4"
          @click="dialog = true"
        >
          Month Release
        </v-btn>
      </div>
    </div>
    <v-tabs
      v-if="!$route.query.userKraKpiFlag"
      height="40"
      class="mx-0 customtab-head"
      slider-color="cyan"
      flat
    >
      <v-tab
        :to="{
          path:'/performance/dashboard',
          query:{dashBoardFlag:true}
          }"
        class="caption custom-transform-class text-none titles"
      >
        Dashboard
      </v-tab>
      <v-tab
        :to="{
          path: '/performance/kra-kpi',
          query: { initiativeFlag: true },
        }"
        class="caption custom-transform-class text-none titles"
      >
        KRA-KPI
      </v-tab>
      <!-- <v-tab
        @click="moveToKra"
        class="caption custom-transform-class text-none titles"
      >
        KRA-KPI
      </v-tab> -->
            <div v-if="$route.path === '/performance/reviews/quarterly'">
      <v-tab
        :to="{
          path: '/performance/reviews/monthly' ,
          query: { performanceFlag: true },
        }"
        class="caption custom-transform-class text-none titles v-tab--active v-tab"
      >
        Reviews
      </v-tab>
      </div>
      <div v-else-if="$route.path === '/performance/reviews/monthly/monthly-reviews'">
      <v-tab
        :to="{
          path: '/performance/reviews/monthly' ,
          query: { performanceFlag: true },
        }"
        class="caption custom-transform-class text-none titles v-tab--active v-tab"
      >
        Reviews
      </v-tab>
      </div>
      <div v-else>
      <v-tab
        :to="{
          path: '/performance/reviews/monthly' ,
          query: { performanceFlag: true },
        }"
        class="caption custom-transform-class text-none titles"
      >
        Reviews
      </v-tab>
      </div>

      <v-tab
        v-if="displayAdmin"
        to="/performance/admin"
        class="caption custom-transform-class text-none titles"
      >
        Admin
      </v-tab>
    </v-tabs>
    <!-- Modal -->
    <v-row>
      <v-col>
        <v-dialog max-width="300px" persistent v-model="dialog">
          <!-- <template v-slot:activator="{ on: { click } }">
            <v-text-field v-on:click="click" v-model="month_release.month" label="Date" required></v-text-field>
          </template> -->
          <v-date-picker
            v-model="month_release.month"
            scrollable
            actions
            type="month"
          >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialog = false">Cancel</v-btn>
              <v-btn color="primary" @click="monthRelease">OK</v-btn>
            </v-card-actions>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-snackbar
      min-width="0"
      app
      :color="color"
      right
      top
      v-model="toggleSnackbar"
      timeout="2000"
    >
      {{ text }}
    </v-snackbar>

    <!-- Anju -->

    <v-container class="py-0">
      <router-view></router-view>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "@/axios";

export default {
  data() {
    return {
      userId: null,
      dialog: false,
      selectedMonth: null,
      selectedYear: null,
      toggleSnackbar: false,
      text: "",
      color: "",

      month_release: {
        user_id: null,
        year: null,
        month: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
    };
  },
  computed: {
    ...mapGetters(["getPermissions"]),
    displayAdmin() {
      return this.getPermissions.find(
        (item) => item.permission_name === "add_year"
      );
    },
  },

  methods: {
    backToUsers() {
      this.$router.push("/users");
    },
    monthRelease() {
      this.month_release.user_id = this.$route.query.user_id;
      var month = this.month_release.month.split("");
      var reqMonth = month[5] + month[6];
      var year = this.month_release.month.split("");
      var reqYear = year[0] + year[1] + year[2] + year[3];
      this.month_release.month = parseInt(reqMonth);
      this.month_release.year = parseInt(reqYear);
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .post("selfreview-request", this.month_release, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            this.dialog = false;
            this.toggleSnackbar = true;
            this.text = "Month Successfully released";
            this.color = "success";
          })
          .catch(() => {
            this.toggleSnackbar = true;

            this.text = "Month Card already exist";
            this.color = "error";
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
     moveToKra() {
    this.$router.push({
      path: "/performance/kra-kpi",
      query: { initiativeFlag: true },
    });
  },
   moveToReviews() {
    this.$router.push({
      path: "/performance/kra-kpi",
      query: { initiativeFlag: true },
    });
  },
  },
 
  // mounted() {
  //   this.userId = localStorage.getItem("user_id");
  // },
};
</script>
<style scoped>
.back {
  display: flex;
  justify-content: space-between;
  /* margin-left:20px; */
}
.manual-style{
  background: #fff;
    border: solid 1px #5138ee;
    color: #5138ee !important;
    border-radius: 100px
}
@media screen and (max-width: 560px) {
  .back {
    flex-direction: column;
    align-items: flex-start;
  }
  .back .heading h5 {
    text-align: left;
  }
}
</style>
