import axios from "@/axios";
import router from '@/router'

const state = {
  reviewList: [],
  monthlyList: [],
  kraKpiSelfReviewList: [],
  performanceKras: [],
  // selfReviewKpiShow: [],
  // CODE_REVIEW: Initialize this value with a Boolean since that is the type of value that is used
  selfReviewSuccessSnackbar: false,
  selfReviewErrorSnackbar: false
};

const getters = {
  monthsList: (state) => state.monthlyList,
  // selfReviewKpiShow: (state) => state.selfReviewKpiShow,
  selfReviewSuccessSnackbar: state => state.selfReviewSuccessSnackbar,
  selfReviewErrorSnackbar: state => state.selfReviewErrorSnackbar,
  kraKpiSelfReviewList: state => state.kraKpiSelfReviewList,
  performanceKras: state => state.performanceKras
};

const mutations = {
  ADD_REVIEW(state, data) {
    state.reviewList.push(data);
    state.selfReviewSuccessSnackbar = true;
  },
  GET_MONTHS(state, data) {
    state.monthlyList = data;
  },
  GET_KRA_KPI_SELF_REVIEWS(state, data) {
    state.kraKpiSelfReviewList = data;

  },
  GET_KRA_KPI_PERFORMANCE_KRAS(state, data) {
    state.performanceKras = data
  }
};

const actions = {
  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async addReview({ commit, state }, payload) {
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .post(
          "selfreviewkpi_store/" +
          payload.id,
          payload.formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          commit("ADD_REVIEW", response.data.results);
        })
        .catch((error) => {
          // CODE_REVIEW: The request failure status is not handled properly here.
          // Error handler here only just logs the error and will not pass error exception to the caller.
          // So the request always appears to succeed to the caller. Need to rethrow error if want it to be propagated to caller.
          console.log(error);
          state.selfReviewErrorSnackbar = true
          throw new Error(error)
        });
    } else {
      router.push({ path: "/login" });
    }
  },
  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async getMonths({ commit, state }, data) {
    state.monthlyList = []
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .post(
          "selfreview-list",
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          // coverting first letter of a string in JSON response to caps starts
          const capsMonthList = response.data.results;
          // CODE_REVIEW: Extract the replacer function of JSON.stringify to separate function to avoid repetition
          let userObj = JSON.stringify(capsMonthList, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });
          var parsed = JSON.parse(userObj);
          // coverting first letter of a string in JSON response to caps ends
          commit("GET_MONTHS", parsed);
        })
        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  async getKraKpisSelfReviews({ commit, state }, formData) {
    state.kraKpiSelfReviewList = []
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    // console.log(formData)
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .post(
          "self-review-kpi-list",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          // console.log(response.data.results.performance_year)
          // coverting first letter of a string in JSON response to caps starts
          const capsMonthList = response.data.results;
          // CODE_REVIEW: Extract the replacer function of JSON.stringify to separate function to avoid repetition
          let userObj = JSON.stringify(capsMonthList, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });
          var parsed = JSON.parse(userObj);
          // coverting first letter of a string in JSON response to caps ends
          commit("GET_KRA_KPI_SELF_REVIEWS", parsed);
          commit("GET_KRA_KPI_PERFORMANCE_KRAS", parsed.performance_year.performance_kras)
        })
        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },
};

export default { state, mutations, actions, getters };
