<template>
  <div>
    <div>
      <v-row>
        <v-col>
          <v-card  max-width="350" class="mt-5 ml-5">
            <v-card-title class="body-2 text-left font-weight-regular"
              >Teams</v-card-title
            >

            <v-list three-line>
              <template v-for="(item, index) in items">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item v-else :key="item.title">
                  <v-list-item-content>
                    <v-card elevation="0" class="mt-n4">
                      <div class="text-left">
                        <v-list-item-avatar>
                          <v-img :src="item.avatar"></v-img>
                        </v-list-item-avatar>
                      </div>
                      <div class="mt-n16 ">
                        <v-card-text
                          class="mt-n12 ml-5"
                          v-html="item.title"
                        ></v-card-text>
                        <v-card-text
                          class="mt-n9 ml-5"
                          v-html="item.subtitle"
                        ></v-card-text>
                      </div>

                   
                    </v-card>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-col>


       
      </v-row>
    </div>
  </div>
</template>

<script>

export default {
  
  data() {
    return {
     

      items: [
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Asif Habeeb",
          subtitle: "Junior Software Engineer",
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: "Achuthan V S",
          subtitle: "Junior Software Engineer",
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "Aishwyra R",
          subtitle: "Junior Software Engineer",
        },
      ],
    };
  },
};
</script>
