<template>
  <div>
    <!-- {{ quartersList }} -->
    
    <!-- <div v-if="quarterlyCardFlag">
      <v-card
        outlined
        class="mx-auto my-2"
        color="grey lighten-2"
        v-for="quarter in quartersList"
        :key="quarter.id"
      >
        <div
          class="d-flex align-items-center justify-content-between py-2 px-1"
        >
          <div class="subtitle-2 text-left d-flex">
            <div class="date">
              <div
                class="
                  custom-transform-class
                  text-uppercase
                  font-weight-bold
                  text-none
                  ml-4
                "
              >
                {{ quarter.user.rm.name }}
              </div>
              <div
                v-if="quarter.quarter == 1"
                class="caption custom-transform-class text-none ml-4"
              >
                {{ quarter.year }} Jan-Mar
              </div>
              <div
                v-if="quarter.quarter == 2"
                class="caption custom-transform-class text-none ml-4"
              >
                {{ quarter.year }} Apr-Jun
              </div>
              <div
                v-if="quarter.quarter == 3"
                class="caption custom-transform-class text-none ml-4"
              >
                {{ quarter.year }} July-Sep
              </div>
              <div
                v-if="quarter.quarter == 4"
                class="caption custom-transform-class text-none ml-4"
              >
                {{ quarter.year }} Oct-Dec
              </div>
              <div class="caption custom-transform-class text-none ml-4">
                Quarter {{ quarter.quarter }}
              </div>
            </div>
            <v-btn
              x-small
              class="ml-4"
              v-if="quarter.status === 'active'"
              color="primary"
            >
              Active
            </v-btn>
            <v-btn
              x-small
              class="ml-4"
              v-if="quarter.status === 'inprogress'"
              color="error"
            >
              Inprogress
            </v-btn>
            <v-btn
              x-small
              class="ml-4"
              v-if="quarter.status === 'completed'"
              color="success"
            >
              Completed
            </v-btn>
          </div>
         
          <div>
            <div class="text-right mr-4">
              <v-btn
                v-if="$route.query.performanceFlag"
                :to="{
                  path: '/performance/reviews/quarterly/quarterly-reviews',
                  query: {
                    quarterlyGetReviewFlag: true,
                    quarterly_id: quarter.id,
                    rm_name: quarter.user.rm.name,
                    rm_mail: quarter.user.rm.email,
                    status: quarter.status,
                    quarter: quarter.quarter,
                  },
                }"
                icon
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn icon v-if="$route.query.myTeamFlag">
                <v-icon @click="getQuarterlyReview(quarter)">mdi-eye</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <quarterly-review v-if="!quarterlyCardFlag"></quarterly-review> -->

<!-- anju new design start -->
<div v-if="quarterlyCardFlag">
  <!-- <p v-if="quartersList.length==0">Self review not completed</p> -->
   <!-- <v-alert  outlined
      type="warning"
      prominent
      border="left" v-if="quartersList.length==0">
    Self review is not complete
    </v-alert> -->
    <div v-if="quartersList.length > 0">
    <v-row>
      <v-col md="6" 
        v-for="quarter in quartersList"
        :key="quarter.id">
        <div class="review-boxes">
          <p class="rm">Reporting Manager:</p>
          <h5 class="month">{{ quarter.user.rm.name }}</h5>
          <span class="date"  v-if="quarter.quarter == 1"
          >{{ quarter.from_date | formatDate }} Apr-Jun</span>
           <span class="date"  v-if="quarter.quarter == 2"
          >{{ quarter.year | formatDate }} Jul-Sep</span>
           <span class="date"  v-if="quarter.quarter == 3"
          >{{ quarter.year | formatDate }} Oct-Dec </span>
           <span class="date"  v-if="quarter.quarter == 4"
          >{{ quarter.year | formatDate }} Jan-Mar</span>
          <h6 class="mt-2 mb-0">Quarter: {{ quarter.quarter }}</h6>
          <div class="view-det">
           <v-btn elevation="0"
                v-if="$route.query.performanceFlag"
                :to="{
                  path: '/performance/reviews/quarterly/quarterly-reviews',
                  query: {
                    quarterlyGetReviewFlag: true,
                    quarterly_id: quarter.id,
                    rm_name: quarter.user.rm.name,
                    rm_mail: quarter.user.rm.email,
                    status: quarter.status,
                    quarter: quarter.quarter,
                    userId: quarter.user.id,
                  },
                }"
              >
               View Details
              </v-btn>
              <v-btn elevation="0" v-if="$route.query.myTeamFlag" @click="getQuarterlyReview(quarter)">
                View Details
              </v-btn>
            <!-- <a href="#">View Details</a> -->
          </div>
          <div class="status">
            <span class="badge badge-primary" v-if="quarter.status === 'active'">Active</span>
            <span class="badge badge-primary" v-if="quarter.status === 'inprogress'">In Progress</span>
            <span class="badge badge-primary" v-if="quarter.status === 'completed'">Completed</span>
          </div>
        </div>
      </v-col>

      <!-- <v-col md="6">
        <div class="review-boxes">
          <p class="rm">Reporting Manager:</p>
          <h5 class="month">NIYAS</h5>
          <span class="date">2022 April - June</span>
          <h6 class="mt-2 mb-0">Quarter 2</h6>
          <div class="view-det">
            <a href="#">View Details</a>
          </div>
          <div class="status">
            <span class="badge badge-primary">View Initiative</span>
          </div>
        </div>
      </v-col>

      <v-col md="6">
        <div class="review-boxes">
          <p class="rm">Reporting Manager:</p>
          <h5 class="month">NIYAS</h5>
          <span class="date">2022 July - September</span>
          <h6 class="mt-2 mb-0">Quarter 3</h6>
          <div class="view-det">
            <a href="#">View Details</a>
          </div>
          <div class="status">
            <span class="badge badge-primary">View Initiative</span>
          </div>
        </div>
      </v-col>

      <v-col md="6">
        <div class="review-boxes">
          <p class="rm">Reporting Manager:</p>
          <h5 class="month">NIYAS</h5>
          <span class="date">2022 October - December</span>
          <h6 class="mt-2 mb-0">Quarter 3</h6>
          <div class="view-det">
            <a href="#">View Details</a>
          </div>
          <div class="status">
            <span class="badge badge-primary">View Initiative</span>
          </div>
        </div>
      </v-col> -->

    </v-row>
    </div>
    <div v-else>
  <v-alert
      dense
      outlined
      type="error"
    >
       <strong>Self review is not complete</strong>
    </v-alert>
    </div>
  </div>
  
    
   <quarterly-review v-if="!quarterlyCardFlag"></quarterly-review>


    <!-- anju new desgn end -->


  </div>
</template>

<script>
import QuarterlyReview from "@/views/my-team/quarterly/QuarterlyReview.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    QuarterlyReview,
  },
  data() {
    return {
      user_id: null,
      team_user_id: null,
      quarterlyCardFlag: true,
      myTeamFlag: null,
    };
  },

  created() {
    this.user_id = localStorage.getItem("user_id");
  },

  mounted() {
    this.callApis();
  },

  computed: { ...mapGetters(["quartersList"]) },

  watch: {
    $route: {
      immediate: true,
      handler() {
        // console.log(this.$route.query.myTeamFlag);
        if (this.$route.query.myTeamFlag) {
          this.myTeamFlag = true;
        }
        if (this.$route.query.quarterlyCardFlag) {
          this.quarterlyCardFlag = true;
        }
      },
    },
  },

  methods: {
    callApis() {
      if (this.myTeamFlag) {
        this.getQuarterCardForTeam();
      } else this.getQuarterCard();
    },

    getQuarterlyReview(quarter) {
      this.quarterlyCardFlag = false;
      this.$router.push({
        name: "Team Member Quarterly Review",
        query: {
          quarterReviewId: quarter.id,
          tab: "reviews",
          quarterlyAddReviewFlag: true,
          roleId: this.$route.query.roleId,
          userId: this.$route.query.userId,
          rmName: quarter.user.rm.name,
          rmMail: quarter.user.rm.email,
          status: quarter.status,
          quarter: quarter.quarter
        },
      });
    },

    getQuarterCard() {
      this.$store.dispatch("getQuarterCard", this.user_id);
    },
    getQuarterCardForTeam() {
      this.team_user_id = this.$route.query.userId;
      this.$store.dispatch("getQuarterCard", this.team_user_id);
    },
  },
};
</script>
