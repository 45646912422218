<template>
  <div>
    <!-- {{ $route.query.id }}{{ $route.query.userId }} -->
    <h5 class="mt-4">Performance Dashboard</h5>
    <!-- {{ allKraKpi.performance_kras }} -->
    <!-- <div v-for="roles in allKraKpi.performance_kras" :key="roles.id"> -->
    <div>
      <!-- <p>{{allKraKpi}}</p> -->
      <!-- <kra-kpi/> -->
      <div class="kra-boxes">
        <!-- <div class="kra-title"> -->
          <!-- <h5 class="ttl">{{ roles.title }}</h5> -->
          <!-- <div class="weighage"> -->
            <!-- <h5 class="my-2">Total Weightage:</h5> -->
            <!-- <h4 class="mb-0">{{ roles.weightage }}</h4> -->
          <!-- </div> -->
        <!-- </div> -->
        <!-- <dash-board-table :data="roles.performance_kpis" :number="allKraKpi.performance_kras"/> -->
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Title</th>
                <th class="text-left">Weightage</th>

                <th class="text-left">Expectation</th>
                <!-- <th class="text-left">Achievement</th> -->
                <th class="text-left">Mid Year</th>
                <th class="text-left">Achievement</th>

                <th class="text-left">Result</th>

                <th class="text-left">Ach</th>
                <th class="text-left">Ach</th>
                <th class="text-left">Score</th>
              </tr>
            </thead>
            <tbody  v-for="roles in tempData" :key="roles.id">
              <!-- <tr> -->
              <td class="text-left pl-4 pt-3"><b>{{roles.title}}</b></td>
              <td class="text-left pl-4 pt-3"><b>{{roles.weightage}}</b></td>
              <!-- </tr> -->
              <tr v-for="subItem in roles.performance_kpis" :key="subItem.id">
                
                <td class="text-left">{{ subItem.title }}</td>

                <td class="text-left">{{ subItem.weightage }}</td>
                <td class="text-left">{{ subItem.expectation }}</td>
                <td class="text-left">{{ subItem.mid_year }}</td>
                <td class="text-left">{{ subItem.achievement }}</td>
                <td class="text-left">{{ subItem.result }}</td>
                <td class="text-left">{{ subItem.Ach1 }}</td>
                <td class="text-left">{{ subItem.Ach2 }}</td>
                <td class="text-left">{{ subItem.Score }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
// import KraKpi from "./kra-kpi/KraKpi.vue";
//  import DashBoardTable from './DashboardTable.vue'
// import { mapGetters } from "vuex";

export default {
  components: {
    // KraKpi
    // DashBoardTable
  },
  data() {
    return {
      listData: { role_id: null, performance_year_id: null },
      search: "",
      calories: "",
      tempStore: [],
      tempData: [
        {
          id: 520,
          title: "Finance",
          weightage: 50,
          performance_kpis: [
            {
              id: 320,
              performance_kra_id: 31,
              title: "Billability",
              weightage: 10,
              expectation: 20,
              mid_year: 45,
              achievement: 25,
              result: 30,
              Score: 30,
              Ach1: ">75",
              Ach2: "≤ 140>",
            },
            {
              id: 321,
              performance_kra_id: 31,
              title: "Hours Saved",
              weightage: 10,
              expectation: 20,
              achievement: 25,

              mid_year: 45,
              result: 30,
              Ach1: ">75",
              Ach2: "≤ 140>",
              Score: 30,
            },
          ],
        },
        {
          id: 521,
          title: "Development",
          weightage: 50,
          performance_kpis: [
            {
              id: 322,
              performance_kra_id: 31,
              title: "Time Spent in Certification",
              weightage: 10,
              expectation: 20,
              achievement: 25,

              mid_year: 45,
              result: 30,
              Ach1: ">75",
              Ach2: "≤ 140>",
              Score: 30,
            },
            {
              id: 323,
              performance_kra_id: 31,
              title: "Self Training per Quarter",
              achievement: 25,

              weightage: 10,
              expectation: 20,
              mid_year: 45,
              result: 30,
              Ach1: ">75",
              Ach2: "≤ 140>",
              Score: 30,
            },
            {
              id: 324,
              performance_kra_id: 31,
              title: "Participation in events",
              achievement: 25,

              weightage: 10,
              expectation: 20,
              mid_year: 45,
              result: 30,
              Ach1: ">75",
              Ach2: "≤ 140>",
              Score: 30,
            },
          ],
        },
      ],
    };
  },

  computed: {
    // ...mapGetters(["allKraKpi"]),
    getData() {
      return this.allKraKpi.performance_kras;
    },
  },
  created() {
    this.listData.role_id = localStorage.getItem("role_id");
  },

  mounted() {
    // this.getKraKpis();
    // this.getFinalData();
  },
  methods: {
    // getKraKpis() {
    //   this.$store.dispatch("getKraKpis", this.listData);
    // },
  },
};
</script>
