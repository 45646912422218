import Vue from 'vue'
import Vuex from 'vuex'
import AdminYears from "../store/modules/admin-years.js"
import AdminKra from "../store/modules/admin-kra.js"
import AdminKpi from "../store/modules/admin-kpi.js"
import AdminKpiRoles from "../store/modules/admin-kpi-roles"
import User from "./modules/user"
import SettingsRoles from "../store/modules/settings-roles"
import KraKpi from "../store/modules/kra-kpi"
import myTeam from "../store/modules/my-team"
import monthlyReviews from "../store/modules/monthly-reviews"
import quarterlyReviews from "../store/modules/quarterly-reviews"
import breadCrumb from "../store/modules/bread-crumb"

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    isLoading: false,
  },
  mutations: {
    SET_DATA (state, { id, data }) {
      state[id] = data
    },
  },
  actions: {},
  modules: {
    AdminYears,
    AdminKra,
    AdminKpi,
    AdminKpiRoles,
    User,
    SettingsRoles,
    KraKpi,
    myTeam,
    monthlyReviews,
    quarterlyReviews,
    breadCrumb
  }
})
