<template>
  <div>
    <v-checkbox :label="kpi.title" color="blue" @click="on = !on"></v-checkbox>
    <div v-if="on">
      <v-card elevation="0">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              dense
              placeholder="Weightage"
              v-model="kpiRolesData.weightage"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              dense
              placeholder="Expectation"
              @change="enableSave"
              v-model="kpiRolesData.expectation"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              dense
              placeholder="Initiatives"
              v-model="kpiRolesData.initiative"
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              rounded
              small
              class="primary caption mt-2"
              color="blue darken-1"
              @click="addKpiRole"
              :disabled="hide"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-snackbar
      min-width="0"
        app
        right
        top
        :color="color"
        elevation="24"
        shaped
        v-model="show"
        :timeout="5000"
        >{{ text }}</v-snackbar
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    kpi: {
      type: Object,
      required: true,
    },
     krasAndKpis: {
      type: [Array],
      required: true,
    },
    // kraId: {
    //   type: Number,
    //   required: true,
    // },
  },
  computed: { ...mapGetters(["allKpi", "snackBar"]) },
  data() {
    return {
      on: false,
      hide: true,
      show: false,
      text: "",
      dialog: false,
      color: "",
      loading: false,
      timeout: null,

      kpiRolesData: {
        performance_kra_id: null,
        performance_kpi_id: null,
        role_id: null,
        weightage: null,
        expectation: null,
        initiative: null,
        type: "Default",
        addActive: false,
      },
    };
  },
  mounted() {
    //  console.log("kraId",this.kpi.performance_kra_id)
    //  console.log("kpi id", this.kpi)
  },
  methods: {
    addKpiRole() {
      this.kpiRolesData.performance_kra_id = this.kpi.performance_kra_id;
      this.kpiRolesData.performance_kpi_id = this.kpi.id;
      this.kpiRolesData.role_id = this.$route.query.id;
      const payload = { ...this.kpiRolesData };
      this.$store
        .dispatch("addKpiRole", payload)
        .then(() => {
          this.dialog = false;
        
          this.toggleSnackbar = this.snackBar;
          this.color = "green darken-2";
          this.text = "KPI Roles Added Successfully";
          window.location.reload();
          this.addActive = false;
          this.kpiRolesData.role_id = null;
          this.kpiRolesData.weightage = null;
          this.kpiRolesData.expectation = null;
          this.kpiRolesData.initiative = null;
          this.addActive = true;
          setTimeout(() => {
            this.loading = false;
            this.show = true;
            this.text = "Successfully Added";
            this.color = "success";
          }, 3500);
        })
        .catch((error) => {
          console.log(error.message.error);
           console.log("##",error);
          setTimeout(() => {
            this.loading = false;
            this.show = true;
            this.text = "Selected Kpi already assigned";
            this.color = "error";
           window.location.reload();
          }, 3500);
        });
    },
    enableSave() {
      this.hide = false;
    },
  },
};
</script>
