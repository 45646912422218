<template>
  <v-container>
    <h5 class="text-left my-4">Access Permission - {{ roleName }}</h5>
    <!-- accorien design started -->
    <v-expansion-panels accordion class="mb-4 custom-accordian">
      <v-expansion-panel
        v-for="(item, index) in allAccess.permission_types"
        :key="index"
      >
        <div class="expansion">
          <v-expansion-panel-header
            >{{ item.type + "" }}
            <div
              class="font-weight-bold my-2 mr-2 ml-5"
              v-for="(name, index) in item.permissions"
              :key="index"
            >
              <div class="permission">
                <!-- <span
                  class="permissions"
                  style="color: primary"
                  v-if="permissionData.permission_id.includes(name.id)"
                  >{{ "" + name.display_name }}</span
                > -->
              </div>
            </div>
          </v-expansion-panel-header>
        </div>
        <v-expansion-panel-content>
          <v-checkbox
            label="select all"
            @click="selectAll(item.permissions)"
            v-model="allSelected"
          ></v-checkbox>
          <v-row>
            <!-- <v-col md="12" class="py-1">
              <v-checkbox
                class="mt-n3"
                label="select all"
                v-model="selectAll"
                @click="select"
              ></v-checkbox>
            </v-col> -->
            <v-col
              md="2"
              class="py-1"
              v-for="subItem in item.permissions"
              :key="subItem.id"
            >
              <v-checkbox
                class="mt-0 custom-checkbox"
                v-model="permissionData.permission_id"
                :label="subItem.display_name"
                :value="subItem.id"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- accorien design ended -->
    <div class="text-right">
      <v-btn rounded color="primary" small class="mb-4" @click="addAccess"
        >Assign Permissions</v-btn
      >
    </div>
    <div>
      <v-snackbar
        min-width="0"
        app
        :color="color"
        right
        top
        v-model="toggleSnackbar"
        timeout="2000"
      >
        {{ text }}
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      permissionData: {
        permission_id: [],
        // role_id: null,
      },
      // loading: false,
      toggleSnackbar: false,
      color: "",
      text: "",
      allSelected: false,
      permissionName: [],
      roleName: "",
    };
  },
  computed: { ...mapGetters(["allAccess", "assignSuccessSnackbar"]) },

  mounted() {
    this.getAccess();
    this.getRoleName();
  },
  methods: {
    getAccess() {
      // this.role_id = this.$route.query.role_id;
      const payload = { id: this.$route.query.id };
      this.$store.dispatch("getAccess", payload).then(() => {
        this.allAccess.permissions_assigned.forEach((item) => {
          this.permissionData.permission_id.push(item.permission_id);
          this.permissionName.push(item.permissions);
        });
      });
    },
    addAccess() {
      const payload = {
        id: this.$route.query.id,
        formData: this.permissionData,
      };
      this.$store.dispatch("addAccess", payload).then(() => {
        if (this.assignSuccessSnackbar) {
          console.log("this.assignSuccessSnackbar", this.assignSuccessSnackbar);
          this.toggleSnackbar = this.assignSuccessSnackbar;
          this.color = "success";
          this.text = "Successfully Assigned";
          // this.loading = false;
        }
      });
    },
    getRoleName() {
      const { name } = this.$route.query;
      this.roleName = name;
    },
    selectAll(permissions) {
      const newSet = new Set(this.permissionData.permission_id);
      console.log("permissions", permissions);
      if (this.allSelected == true) {
        permissions.forEach((item) => {
          console.log("item", item);
          // eslint-disable-next-line no-debugger
          newSet.add(item.id);
        });
      }
      if (this.allSelected == false) {
        permissions.forEach((item) => {
          console.log("item", item);
          newSet.delete(item.id);
        });
      }
      this.permissionData.permission_id = Array.from(newSet);
    },
  },
};
</script>
<style scoped>
.permissions {
  margin: 0;
  padding: 0;
  text-align: justify;
}
</style>
