var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-custom"},[_c('div',{staticClass:"back"},[(_vm.$route.query.user_name)?_c('div',[_c('v-btn',{staticClass:"mb-4",attrs:{"rounded":"","color":"primary","small":""},on:{"click":_vm.backToUsers}},[_vm._v(" Back ")])],1):_vm._e(),_c('div',{staticClass:"heading"},[(_vm.$route.query.userKraKpiFlag)?_c('h5',{staticClass:"mb-4 mx-auto",attrs:{"color":"primary"}},[_vm._v(" Kra - Kpi of "+_vm._s(_vm.$route.query.name)+"-"+_vm._s(_vm.$route.query.user_name)+" ")]):_vm._e()]),(_vm.$route.query.userKraKpiFlag)?_c('div',{staticClass:"month-release"},[_c('v-btn',{staticClass:"mb-4",attrs:{"rounded":"","color":"primary","small":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Month Release ")])],1):_vm._e()]),(!_vm.$route.query.userKraKpiFlag)?_c('v-tabs',{staticClass:"mx-0 customtab-head",attrs:{"height":"40","slider-color":"cyan","flat":""}},[_c('v-tab',{staticClass:"caption custom-transform-class text-none titles",attrs:{"to":{
        path:'/performance/dashboard',
        query:{dashBoardFlag:true}
        }}},[_vm._v(" Dashboard ")]),_c('v-tab',{staticClass:"caption custom-transform-class text-none titles",attrs:{"to":{
        path: '/performance/kra-kpi',
        query: { initiativeFlag: true },
      }}},[_vm._v(" KRA-KPI ")]),(_vm.$route.path === '/performance/reviews/quarterly')?_c('div',[_c('v-tab',{staticClass:"caption custom-transform-class text-none titles v-tab--active v-tab",attrs:{"to":{
        path: '/performance/reviews/monthly' ,
        query: { performanceFlag: true },
      }}},[_vm._v(" Reviews ")])],1):(_vm.$route.path === '/performance/reviews/monthly/monthly-reviews')?_c('div',[_c('v-tab',{staticClass:"caption custom-transform-class text-none titles v-tab--active v-tab",attrs:{"to":{
        path: '/performance/reviews/monthly' ,
        query: { performanceFlag: true },
      }}},[_vm._v(" Reviews ")])],1):_c('div',[_c('v-tab',{staticClass:"caption custom-transform-class text-none titles",attrs:{"to":{
        path: '/performance/reviews/monthly' ,
        query: { performanceFlag: true },
      }}},[_vm._v(" Reviews ")])],1),(_vm.displayAdmin)?_c('v-tab',{staticClass:"caption custom-transform-class text-none titles",attrs:{"to":"/performance/admin"}},[_vm._v(" Admin ")]):_vm._e()],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"max-width":"300px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-date-picker',{attrs:{"scrollable":"","actions":"","type":"month"},model:{value:(_vm.month_release.month),callback:function ($$v) {_vm.$set(_vm.month_release, "month", $$v)},expression:"month_release.month"}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.monthRelease}},[_vm._v("OK")])],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"min-width":"0","app":"","color":_vm.color,"right":"","top":"","timeout":"2000"},model:{value:(_vm.toggleSnackbar),callback:function ($$v) {_vm.toggleSnackbar=$$v},expression:"toggleSnackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('v-container',{staticClass:"py-0"},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }