<template>
  <div>
    <v-form lazy-validation ref="form" class="submitforms">
      <v-row class="pl-3 pr-5">
        <v-col cols="12" md="6" class="pb-0">
          <p class="text-left mb-2">Outcome expected in this month</p>

          <div>
            <v-text-field
              type="number"
              v-model="reviewData.expectation"
              placeholder="Expectation"
              oninput="this.value"
              auto-grow
              outlined
              dense
              rows="1"
              :rules="[rules.required, rules.length]"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="pb-0">
          <div v-if="unit === 'Percent'">
            <p class="text-left mb-2">Where we are on it?</p>

            <v-text-field
              dense
              placeholder="Achievement"
              type="number"
              v-model="reviewData.metric"
              oninput="this.value"
              outlined
              :disabled="compMonth"
              :rules="[rules.weightageValue]"
            ></v-text-field>
          </div>
          <div v-else>
            <p class="text-left mb-2">Where we are on it?</p>
            <v-text-field
              dense
              placeholder="Achievement"
              type="number"
              v-model="reviewData.metric"
              outlined
              :disabled="compMonth"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row class="pl-3 pr-5 mt-1">
        
        <v-col md="12" class="">
          <v-textarea
            dense
            placeholder="Comment"
            v-model="reviewData.comment"
            rows="1"
            auto-grow
            outlined
            :disabled="compMonth"
          ></v-textarea>
        </v-col>
        <div
          class="text-left mt-n7 mb-4"
          v-if="$route.query.status != 'Completed'"
        >
          <v-col class="px-0" v-if="self_review_kpi != null || saveUpdateFlag === true">
            <v-btn small color="primary" @click="updateSelfReviewKpi"
              >update</v-btn
            >
          </v-col>
          <v-col class="px-0" v-else>
            <v-btn small color="primary" @click="addReview">save</v-btn>
          </v-col>
        </div>
      </v-row>

      <v-snackbar
        min-width="0"
        app
        :color="color"
        right
        top
        v-model="toggleSnackbar"
        timeout="2000"
      >
        {{ text }}
      </v-snackbar>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios";

export default {
  data() {
    return {
      color: "",
      text: "",
      toggleSnackbar: false,
      saveUpdateFlag: false,
      reviewDataResult: [],
      statusFlag: null,
      progress: 0,
      reviewData: {
        performance_kpi_id: "",
        expectation: "",
        metric: "",
        comment: "",
      },
      current_month: "",
      disableFields: true,
      // getReviewData: {
      //   performance_kpi_id: null,
      //   performance_self_review_id: null,
      // },
      rules: {
        required: (value) => !!value || "Required",
        length: (value) =>
          (value && value.length <= 250) || "Maximum 250 characters",
        weightageValue: (value) => value <= 140 || "Maximum <= 140",
      },
    };
  },
  props: ["kpi_id", "self_review_id", "self_review_kpi", "unit"],

  computed: {
    ...mapGetters([
      "selfReviewSuccessSnackbar",
      "selfReviewErrorSnackbar",
      "allKpiCount",
    ]),
    kpiId() {
      return this.kpi_id;
    },
    compMonth() {
      if (this.current_month === this.$route.query.month) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    kpiId() {
      this.saveUpdateFlag = false;
      this.reviewData.expectation = this.self_review_kpi
        ? this.self_review_kpi.expectation
        : "";
      this.reviewData.metric = this.self_review_kpi
        ? this.self_review_kpi.metric
        : "";
      this.reviewData.comment = this.self_review_kpi
        ? this.self_review_kpi.comment
        : "";
    },
  },

  mounted() {
    this.reviewData.expectation = this.self_review_kpi
      ? this.self_review_kpi.expectation
      : "";
    this.reviewData.metric = this.self_review_kpi
      ? this.self_review_kpi.metric
      : "";
    this.reviewData.comment = this.self_review_kpi
      ? this.self_review_kpi.comment
      : "";
    this.getCurrentMonth();
  },
  methods: {
    addReview() {
      if (this.$refs.form.validate()) {
        console.log("kpi id", this.kpi_id);
        this.reviewData.performance_kpi_id = this.kpi_id;
        const payload = { formData: this.reviewData, id: this.self_review_id };
        this.$store
          .dispatch("addReview", payload)
          .then(() => {
            this.toggleSnackbar = this.selfReviewSuccessSnackbar;
            this.text = "Self review successfully added";
            this.color = "success";
            this.saveUpdateFlag = true;
            this.$emit("update-progress");
          })
          .catch(() => {
            this.toggleSnackbar = this.selfReviewErrorSnackbar;
            this.color = "error";
            this.text = "Error in adding";
          });
      }
    },
    getCurrentMonth() {
      const currentMonth = new Date().getMonth() + 1;
      console.log(currentMonth);

      const nameOfMonth = new Date().toLocaleString("default", {
        month: "short",
      });
      var setMonth = nameOfMonth.split("");
      var testMonth = "";
      for (let i = 0; i < 3; i++) {
        testMonth += setMonth[i];
      }
      this.current_month = testMonth;
    },

    // getSelfReviewKpi() {
    //   this.getReviewData.performance_self_review_id = this.self_review_id;
    //   this.getReviewData.performance_kpi_id = this.kpi_id;
    //   const token = localStorage.getItem("accessToken");
    //   if (token) {
    //     axios
    //       .post(
    //         this.getReviewData,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //         }
    //       )
    //       .then((response) => {
    //         console.log(response);
    //         this.reviewDataResult = response.data.results[0];
    //         this.reviewData.metric = this.reviewDataResult
    //           ? this.reviewDataResult.metric
    //           : "";
    //         this.reviewData.expectation = this.reviewDataResult
    //           ? this.reviewDataResult.expectation
    //           : "";
    //         this.reviewData.comment = this.reviewDataResult
    //           ? this.reviewDataResult.comment
    //           : "";
    //         this.statusFlag = response.data.results[0].metric;

    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   } else {
    //     this.$router.push({ path: "/login" });
    //   }
    // },

    updateSelfReviewKpi() {
      this.reviewData.performance_kpi_id = this.kpi_id;
      this.reviewData.performance_self_review_id = this.self_review_id;
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .post("self-review-kpi-update", this.reviewData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response.data.results);
            this.$emit("update-progress");

            this.toggleSnackbar = true;
            this.text = "Self review successfully edited";
            this.color = "success";
          })
          .catch(() => {
            this.toggleSnackbar = true;
            this.text = "Error in editing";
            this.color = "error";
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>
