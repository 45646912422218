<template>
  <div>
    <performance-nav-bar></performance-nav-bar>
  </div>
</template>

<script>
// CODE_REVIEW: This component seems redundant. No logic or styles here in this component.
import PerformanceNavBar from "../views/performance/nav-bar/PerformanceNavBar.vue";

export default {
  components: {
    PerformanceNavBar,
  },
};
</script>

