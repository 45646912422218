var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"text-center font-weight-bold subtitle-1 mt-2"},[_vm._v(" "+_vm._s(_vm.$route.query.month)+" - Self reviews KPI ")]),_c('div',[_c('div',{staticClass:"prograss-custom"},[_c('v-progress-linear',{staticClass:"mt-4 mb-5 w-100",attrs:{"color":"indigo","height":"30","rounded":"","striped":"","value":_vm.progressValue},on:{"click":function($event){$event.preventDefault();return _vm.test(_vm.progressValue)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.submitDialog),callback:function ($$v) {_vm.submitDialog=$$v},expression:"submitDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary justify-content-between"},[_c('span',{staticClass:"text-h6 text-white"},[_vm._v("Confirmation")]),_c('v-btn',{staticClass:"grey--lighten-7 px-5 caption",attrs:{"icon":"","color":"white"},on:{"click":function($event){_vm.submitDialog = false}}},[_c('v-icon',[_vm._v("mdi-window-close")])],1)],1),_c('v-card-text',[_c('div',{staticClass:"subtitle-1 mt-5"},[_vm._v(" Are you sure you want to submit your details ? ")])]),_c('v-card-actions',{staticClass:"pb-5 px-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"caption px-5",attrs:{"rounded":"","color":"primary"},on:{"click":function($event){return _vm.changeStatusToCompleted(_vm.submitId)}}},[_vm._v(" Ok ")])],1)],1)],1),_c('div',{staticClass:"kra-boxes"},[_c('div',{staticClass:"kra-title"},[_c('h5',{staticClass:"ttl"},[_vm._v(" "+_vm._s(_vm.kraKpiSelfReviewList.performance_year.performance_kras[_vm.kraIndex] .title)+" ")]),_c('div',{staticClass:"weighage"},[_c('h5',{staticClass:"my-2"},[_vm._v("Weightage:")]),_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.kraKpiSelfReviewList.performance_year.performance_kras[_vm.kraIndex] .weightage)+" ")])])]),_c('div',[_c('div',{staticClass:"mt-1"},_vm._l((_vm.kraKpiSelfReviewList.performance_year
              .performance_kras[_vm.kraIndex].performance_kpis),function(item,index){return _c('v-card',{key:index,staticClass:"mb-6 kpi-boxes-outer",attrs:{"elevation":"0"}},[_c('div',{staticClass:"kpi-boxes"},[_c('div',{staticClass:"content-area d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"title"},[_c('h6',{staticClass:"font-weight-bold my-2 mr-2"},[_vm._v(_vm._s(item.title))]),_c('initiative-modal',{attrs:{"kpi_id":item.id,"performance_role_kpi_id":item.performance_role_kpis[0].id,"userId":_vm.userId}})],1),_vm._l((item.performance_role_kpis),function(subitem,index){return _c('div',{key:index,staticClass:"kpi-det pt-1"},[_c('h6',[_vm._v(" Weightage : "),_c('span',[_vm._v(" "+_vm._s(subitem.weightage))])]),_c('h6',[_vm._v(" KPI/Unit of measure : "),_c('span',[_vm._v(_vm._s(item.measurement_unit))])]),_c('h6',[_vm._v(" Expectation : "),_c('span',[_vm._v(" "+_vm._s(subitem.expectation))])])])})],2),_c('p',{staticClass:"description text-left mb-0"},[_vm._v(" "+_vm._s(item.description)+" ")])]),(item.self_review_kpis.length)?_c('div',_vm._l((item.self_review_kpis),function(subitemnew,index){return _c('div',{key:index,staticClass:"mt-1 pl-3 text-caption"},[_c('div',[_c('performance-add-reviews',{attrs:{"kpi_id":item.id,"self_review_id":_vm.$route.query.id,"self_review_kpi":subitemnew,"unit":item.measurement_unit},on:{"update-progress":_vm.updateprogressbar}})],1)])}),0):_c('div',[_c('div',[_c('performance-add-reviews',{attrs:{"kpi_id":item.id,"self_review_id":_vm.$route.query.id,"unit":item.measurement_unit},on:{"update-progress":_vm.updateprogressbar}})],1)])])}),1)])]),_c('div',{staticClass:"d-flex justify-content-between"},[(_vm.isPrev === true && _vm.kraIndex != 0)?_c('v-btn',{attrs:{"align":"left","color":"primary"},on:{"click":function($event){return _vm.moveToBack()}}},[_c('v-icon',[_vm._v("mdi-arrow-left-box")]),_vm._v("Back ")],1):_vm._e(),(
          _vm.isNext === true &&
          _vm.kraIndex !=
            this.kraKpiSelfReviewList.performance_year.performance_kras
              .length -
              1
        )?_c('v-btn',{staticStyle:{"margin-left":"auto"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.moveToNext()}}},[_vm._v("Next"),_c('v-icon',[_vm._v("mdi-arrow-right-box")])],1):(
          (_vm.$route.query.status === 'Active' ||
            _vm.$route.query.status === 'InProgress') &&
          _vm.progressValue === 100
        )?_c('v-btn',{staticStyle:{"margin":"0 auto"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.submitModal(_vm.$route.query.id)}}},[_vm._v("SUBMIT ")]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }