import axios from "@/axios";
import router from '@/router'

const state = {
  rolesList: [],
  accessList: [],
  assignSuccessSnackbar: false,

};

const getters = {
  allRoles: (state) => state.rolesList,
  allAccess: (state) => state.accessList,
  assignSuccessSnackbar: state => state.assignSuccessSnackbar,

};

const mutations = {
  ADD_ROLE(state, data) {
    state.rolesList.push(data);
  },
  ADD_ACCESS(state, data) {
    state.assignSuccessSnackbar = true
    state.accessList.push(data);
   
  },

  GET_ROLES(state, data) {
    state.rolesList = data;
  },
  GET_ACCESS(state, data) {
    state.accessList = data;
  },
  UPDATE_ROLE(state, data) {
    const index = state.rolesList.findIndex(
      (rolesList) => rolesList.id === data.id
    );
    if (index !== -1) {
      state.rolesList.splice(index, 1, data);
    }
  },
  DELETE_ROLE(state, id) {
    state.rolesList = state.rolesList.filter(
      (rolesList) => rolesList.id !== id
    );
  },
};

const actions = {
  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async addRole({ commit }, roleData) {
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .post("roles", roleData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          commit("ADD_ROLE", response.data.results);
        })
        .catch((error) => {
          // CODE_REVIEW: The request failure status is not handled properly here.
          // Error handler here only just logs the error and will not pass error exception to the caller.
          // So the request always appears to succeed to the caller. Need to rethrow error if want it to be propagated to caller
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async getRoles({ commit, state }) {
    state.rolesList = []
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .get("roles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          commit("GET_ROLES", response.data.results.roles);
        })
        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log('jerje', error.message);
          if (error.message === "Request failed with status code 403") {
            console.log("hiiiiiiiiiiii")
            //  alert('not accessible for you')
            // this.$router.push({path:"/no-access"})


          }
          // if(response.code === 403){
          //   this.$router.push({path:"/no-access"})
          // }
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async updateRole({ commit }, payload) {
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .put(
          "roles/" + payload.id,
          payload.formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          commit("UPDATE_ROLE", response.data.results);
        })
        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          // CODE_REVIEW: The request failure status is not handled properly here.
          // Error handler here only just logs the error and will not pass error exception to the caller.
          // So the request always appears to succeed to the caller. Need to rethrow error if want it to be propagated to caller
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async deleteRole({ commit }, id) {
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .delete("roles/" + id, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          commit("DELETE_ROLE", id, result.data);
        })

        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          // CODE_REVIEW: The request failure status is not handled properly here.
          // Error handler here only just logs the error and will not pass error exception to the caller.
          // So the request always appears to succeed to the caller. Need to rethrow error if want it to be propagated to caller
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  async getAccess({ commit, state }, payload) {
    state.accessList = []
    const token = localStorage.getItem("accessToken");
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    if (token) {
      return axios
        .get("roles/" + payload.id, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          commit("GET_ACCESS", response.data.results);
        })
        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  async addAccess({ commit }, payload) {
    const token = localStorage.getItem("accessToken");
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    if (token) {
      return axios
        .post(
          "roles/" + payload.id + "/assign-permissions",
          payload.formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          commit("ADD_ACCESS", response.data.results);
        })
        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },

};

export default { state, mutations, actions, getters };
