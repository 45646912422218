<template>
  <v-app class="login-app">
    <v-main>
      <!-- <v-card max-width="475" class="mx-auto my-16 pt-6 loginbox" elevation="0">
        <div>
          <img src="@/assets/images/cubet-logo-dark.svg" alt="" width="120" />
        </div>
        <v-card-title>
          <h6 class="subtitle-1 font-weight-bold text-center w-100 my-2">
            LOGIN
          </h6>
        </v-card-title>
        <v-form lazy-validation ref="form"
          ><v-card-text class="pb-0 px-5">
            <v-text-field
              placeholder="Username"
              type="email"
              outlined
              v-model="formData.email"
              :rules="[rules.required, rules.email]"
              prepend-inner-icon="mdi-account-circle"
            ></v-text-field>
            <v-text-field
            @keydown.enter="delay()"
              placeholder="Password"
              :rules="[rules.required, rules.password]"
              outlined
              :type="showPassword ? 'text' : 'password'"
              prepend-inner-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              v-model="formData.password"
            />
          </v-card-text>
          <div>
            <v-row class="pb-5 px-1">
              <v-col class="text-left ml-5">
                <router-link to="/forgot-password"
                  >Forgot Password ?</router-link
                >
              </v-col>
              <v-col class="text-right mr-4">
                <v-btn
                  class="px-5 caption"
                  rounded
                  color="primary"
                  @click="delay"
                  :disabled="disabled"
                  >Let's Go</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card> -->

      <v-row class="h-100 login-row">
        <v-col cols="12" md="12" lg="6" class="leftside-login">
          <div class="login-girl">
            <div class="login-text">
              <h5 class="mt-2">Login for better platform..!</h5>
            </div>
            <div class="girl">
              <img src="@/assets/images/girl.png" alt="" />
            </div>
            <div class="lock-icon">
              <img src="@/assets/images/icons8-lock.gif" alt="" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="12" lg="6">
          <div class="rightside-login">
            <div class="box">
              <div class="title">
                <h5>Login</h5>
              </div>
              <v-form lazy-validation ref="form" class="w-100"
                ><v-card-text class="pb-0 px-5">
                  <div class="textfield">
                    <v-text-field
                      placeholder="Username"
                      type="email"
                      outlined
                      prepend-inner-icon="mdi-account-circle"
                      v-model="formData.email"
                      :rules="[rules.required, rules.email]"
                    ></v-text-field>
                    <v-text-field
                      @keydown.enter="delay()"
                      placeholder="Password"
                      :rules="[rules.required, rules.password]"
                      outlined
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      prepend-inner-icon="mdi-lock"
                      @click:append="showPassword = !showPassword"
                      v-model="formData.password"
                    />
                    <div class="loginicon">
                      <v-icon> mdi-account-circle </v-icon>
                    </div>
                    <div class="passwordicon">
                      <v-icon> mdi-lock </v-icon>
                    </div>
                  </div>
                </v-card-text>
                <div>
                  <v-row class="pb-5 px-5">
                    <v-col
                      cols="12"
                      sm="6"
                      class="text-left d-flex align-items-center pt-0 second-order"
                    >
                      <router-link to="/forgot-password" class="forgot"
                        >Forgot Password ?</router-link
                      >
                    </v-col>
                    <v-col cols="12" sm="6" class="text-right pt-0 first-order">
                      <v-btn
                        class="login-btn caption"
                        rounded
                        color="primary"
                        @click="delay"
                        :disabled="disabled"
                        :loading="loading"
                        >Let's Go</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-snackbar
        min-width="0"
        app
        right
        top
        :color="color"
        elevation="24"
        shaped
        v-model="show"
        :timeout="5000"
        >{{ text }}</v-snackbar
      >
    </v-main>
  </v-app>
</template>

<script>
import axios from "@/axios";
// import Swal from "sweetalert2";
export default {
  data() {
    return {
      showPassword: false,
      show:false,
      text: "",
      color: "",
      disabled: false,
      loading: false,
      timeout: null,
      accessToken: "",
      formData: {
        email: null,
        password: null,
        error_email: null,
        error_password: null,
      },
      rules: {
        required: (value) => !!value || "Required",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "E-mail must be valid";
        },
        password: (value) =>
          (value && value.length >= 8) || "minimum 8 characters",
      },
    };
  },
  mounted() {},
  methods: {
    delay() {
      this.disabled = true;
      this.timeout = setTimeout(() => {
        this.disabled = false;
      }, 3000);
      this.login();
    },

    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        axios
          .post("login", this.formData)
          .then((response) => {
            const accessToken = response.data.results[1];
            if (accessToken != "") {
              localStorage.setItem("accessToken", accessToken);
              localStorage.setItem("name", response.data.results[0].name);
              localStorage.setItem("LoggedUser", response.data.results[0]);

              localStorage.setItem("email", response.data.results[0].email);
              localStorage.setItem("role_id", response.data.results[0].role_id);
              localStorage.setItem("user_id", response.data.results[0].id);
              localStorage.setItem(
                "allData",
                JSON.stringify(response.data.results[0])
              );
              localStorage.setItem(
                "permissions",
                JSON.stringify(
                  response.data.results[0].role.role_has_permissions
                )
              );

              this.$store.commit(
                "ADD_PERMISSIONS",
                response.data.results[0].role.role_has_permissions
              );
              this.$store.commit("GET_DETAILS", response.data.results[0]);
              // Swal.fire({
              //   title: "Logged In",
              //   icon: "success",
              //   showConfirmButton: false,
              //   timer: 1500,
              // });
              this.$router.push({
                path: "/",
              });
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            setTimeout(() => {
              this.loading = false;
              this.show = true;
              this.text = "Invalid Credentials! Please enter valid credentials";
              this.color = "red accent-2";
              this.$router.push("/login");
            }, 3500);
            // Swal.fire({
            //   title: "Alert",
            //   icon: "error",
            //   text: "Incorrect Password or Email",
            //   showConfirmButton: false,
            //   timer: 3000,
            // });
          });
      }
    },
  },
};
</script>

<style scoped>
.color {
  color: blue;
}
.textfield {
  position: relative;
}
.loginicon {
  position: absolute;
  top: 4px;
  left: 5px;
  bottom: 5vh;
}
.passwordicon {
  position: absolute;
  top: 80px;
  left: 5px;
  bottom: 5vh;
}
</style>
