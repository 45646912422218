<template>
  <div>
    <!-- {{ userId }}-{{ roleId }} -->
    Team Dashboard
  </div>
</template>

<script>

export default {
  props: {
    userId: {
      required: true,
    },
    roleId: {
      required: true,
    },
  },
};
</script>
