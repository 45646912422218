<template>
  <v-app>
    <!-- <h1>{{getPermissions}}</h1> -->
    <v-app-bar
      elevation="0"
      class="py-2"
      color="#fff"
      dense
      dark
      app
      height="55"
    >
      <div class="menuicon" @click.stop="drawer = !drawer">
        <img src="@/assets/images/hamburger-icon.svg" alt="" width="20" />
      </div>

      <v-spacer></v-spacer>
      <profile-menu />
    </v-app-bar>
    <v-navigation-drawer
      class="outer-leftside"
      v-model="drawer"
      app
      width="110"
      color="#5138ee
"
    >
      <v-card color="transparent" dark elevation="0"
        ><div class="text-caption">
          <v-list dense>
            <v-list-item class="text-body-1">
              <img src="@/assets/images/logo.svg" alt="" />
            </v-list-item>
            <v-divider></v-divider>
            <!-- CODE_REVIEW: Named component in router are preferable to being hardcoded like in here  -->
            <v-list-item to="/dashboard" class="d-block py-2">
              <v-icon small>mdi-view-dashboard</v-icon
              ><v-list-item-title class="py-3 font-weight-light caption"
                >Dashboard</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="moveToDashboard" class="d-block py-2">
              <v-icon small>mdi-account-box</v-icon
              ><v-list-item-title class="py-3 font-weight-light caption"
                >Performance</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              :to="{
                name: 'My Team',
              }"
              class="d-block py-2"
              v-if="displayMyteams"
            >
              <v-icon small>mdi-account-box-multiple</v-icon
              ><v-list-item-title class="py-3 font-weight-light caption"
                >My Team</v-list-item-title
              >
            </v-list-item>
            <v-list-item to="/users" class="d-block py-2" v-if="displayUsers">
              <v-icon small>mdi-account-group</v-icon
              ><v-list-item-title class="py-3 font-weight-light caption"
                >Users</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              to="/settings/roles"
              class="d-block py-2"
              v-if="displaySettings"
            >
              <v-icon small>mdi-cog-stop</v-icon
              ><v-list-item-title class="py-3 font-weight-light caption"
                >Setting</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </v-navigation-drawer>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <loading :active="isLoading" :is-full-page="fullPage" />
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ProfileMenu from "./ProfileMenu.vue";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    ProfileMenu,
    Loading,
  },
  data() {
    return {
      fullPage: false,
      drawer: true,
      userId: null,
      permission: [],
      // firstMemberUserId: null,
      // firstMemberRoleId: null,
    };
  },
  computed: {
    ...mapState(["isLoading"]),
    ...mapGetters(["getPermissions"]),
    displaySettings() {
      return this.getPermissions.find(
        (item) => item.permission_name === "list_roles"
      );
    },
    displayUsers() {
      return this.getPermissions.find(
        (item) => item.permission_name === "list_users"
      );
    },
    displayMyteams() {
      return this.getPermissions.find(
        (item) => item.permission_name === "team_members_list"
      );
    },
  },
  methods:{
       moveToDashboard(){
      this.$router.push({
        path:'/performance/dashboard',
        query:{dashBoardFlag:true}
      })
    }
  }

  // created() {
  //   this.firstMemberUserId = this.myTeam[0].id;
  //   this.firstMemberRoleId = this.myTeam[0].role_id;
  // },
};
</script>
