<template>
  <b-breadcrumb class="mt-2">
    <b-breadcrumb-item
      v-for="(item, index) in items"
      :key="index"
      :to="{ name: item.to.name, query: item.query }"
    >
      <small>{{ item.text }}</small>
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script>
// CODE_REVIEW: Why including every component in views folder? The convention is to include the root page component in the views folder and the components in the components folder.
// CODE_REVIEW: Import the needed bootstrap breadcrumb component here locally to avoid including the whole component bundle
export default {
  data() {
    return {};
  },
  props: {
    items: {
      type: Array,
      required: true,
      // CODE_REVIEW: Shorthand for returning object or array is "defualt: () => ({})" or "default: () => []"
      default: function () {
        return [];
      },
    },
  },
};
</script>
<style scoped>
.breadcrumb-item a {
  text-decoration: none;
  color: #56688d;
  font-size: 14px;
}
</style>
