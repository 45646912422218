<template>
  <div>
    <div class="mx-auto">
      <rm-details :rmName="$route.query.rmName" :rmMail="$route.query.rmMail" />
    </div>
    <div
      class="kra-boxes"
      v-for="(item, index) in allKraKpi.performance_kras"
      :key="index"
    >
      <!-- <v-card elevation="0" class="font-weight-bold mx-auto color-light-grey">
        <v-list-item class="dark--text mt-2 overline">
          {{ item.title }}<v-spacer></v-spacer>Total Weightage: {{item.weightage}}
        
        </v-list-item>
      </v-card> -->
      <div class="kra-title">
        <h5 class="ttl">{{ item.title }}</h5>
        <div class="weighage">
          <h5 class="my-2">Total Weightage:</h5>
          <h4 class="mb-0">{{ item.weightage }}</h4>
        </div>
      </div>

      <div class="mt-1">
        <key-performance-index
          class="mb-1"
          :performance-kpis="item.performance_kpis"
          :quarterlyAddReviewFlag="quarterlyAddReviewFlag"
          :quarterReviewId="$route.query.quarterReviewId"
          :userId="$route.query.userId"
        ></key-performance-index>
      </div>
    </div>
    <!-- Submit modal starts -->
    <v-dialog v-model="submitDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h6 text-white">Confirmation</span>
        </v-card-title>
        <v-card-text>
          <div class="subtitle-1 mt-5">
            Are you sure you want to submit your details ?
          </div>
        </v-card-text>
        <v-card-actions class="pb-5 px-5">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="grey--lighten-7 px-5 caption"
            @click="submitDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            rounded
            color="primary"
            class="caption px-5"
            @click="changeStatusToCompleted($route.query.quarterReviewId)"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Submit modal ends -->
    <div v-if="!getRole">
    <v-btn
      v-if="$route.query.status != 'completed'"
      rounded
      color="primary"
      class="caption px-5"
      @click="submitDialog = true"
      >Submit</v-btn
    >
    </div>

    <v-snackbar
     min-width="0"
      app
      :color="color"
      right
      top
      v-model="toggleSnackbar"
      timeout="2000"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import RmDetails from "@/views/performance/reviews/quarterly/RmDetails.vue";
import KeyPerformanceIndex from "@/views/performance/kra-kpi/KeyPerformanceIndex.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    RmDetails,
    KeyPerformanceIndex,
  },

  data() {
    return {
      quarterlyAddReviewFlag: null,
      submitDialog: false,
      toggleSnackbar: false,
      totalWeightageKra: "",
      role_id: null,
    };
  },

  computed: {
    ...mapGetters(["allKraKpi"]),
    getRole() {
      if (this.role_id == 1 || this.role_id == 64) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.quarterlyAddReviewFlag) {
          this.quarterlyAddReviewFlag = true;
        }
      },
    },
  },

  mounted() {
    this.getKraKpis();
    this.changeStatusToCompleted();
    this.findRole()
  },

  methods: {
    getKraKpis() {
      this.$store.dispatch("getKraKpis", this.$route.query.roleId);
    },
    findRole(){
      this.role_id=localStorage.getItem('role_id')
    },

    // submitModal(id) {
    //   this.submitDialog = true;
    //   this.submitId = id;
    // },

    changeStatusToCompleted(id) {
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .put(
            "quarterly-review-status-update/" + id,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            this.toggleSnackbar = true;
            this.text = "Submitted Successfully";
            this.color = "success";
            window.location.reload();
            // this.$router.go(-1);
            console.log("hansns", this.$refs);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>
