<template>
  <div>
<div>   
   
    <v-tabs height="40" class="mx-0 sub-maintab" slider-color="cyan" flat>
       <div v-if="$route.path === '/performance/reviews/monthly/monthly-reviews'">
      <v-tab
        :to="{
          path: '/performance/reviews/monthly',
          query: { performanceFlag: true },
        }"
        class="custom-transform-class text-none caption v-tab--active v-tab"
      >
        Monthly
      </v-tab>
      </div>
      <div v-else>
      <v-tab
        :to="{
          path: '/performance/reviews/monthly',
          query: { performanceFlag: true },
        }"
        class="custom-transform-class text-none caption "
      >
        Monthly
      </v-tab>
      </div>
      <v-tab
        :to="{
          path: '/performance/reviews/quarterly',
          query: { performanceFlag: true },
        }"
        class="custom-transform-class text-none caption"
      >
        Quarterly
      </v-tab>
    </v-tabs>
</div>
<div>
    <router-view></router-view>
</div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>
<style scoped>
.manula-style{
  background-color: rgba(0, 0, 0, 0.54)
}
</style>