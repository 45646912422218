<template>
  <div>
    <!-- <div v-if="monthCardFlag">
      <v-card
        outlined
        class="mx-auto my-2"
        color="grey lighten-2"
        v-for="month in monthsList"
        :key="month.id"
      >
        <div
          class="d-flex align-items-center justify-content-between py-2 px-1"
        >
          <div class="subtitle-2 text-left d-flex">
            <div class="date">
              <div
                class="
                  custom-transform-class
                  text-uppercase
                  font-weight-bold
                  text-none
                  ml-4
                "
              >
                {{ month.month }}
              </div>
              <div class="caption custom-transform-class text-none ml-4">
                {{ month.date }}
              </div>
            </div>

            <v-btn
              v-if="month.status === 'Active'"
              x-small
              class="ml-4"
              color="primary"
              >Active</v-btn
            >
            <v-btn
              v-if="month.status === 'InProgress'"
              x-small
              class="ml-4"
              color="error"
              >Inprogress</v-btn
            >
            <v-btn
              v-if="month.status === 'Completed'"
              x-small
              class="ml-4"
              color="success"
              >Completed</v-btn
            >
          </div>
          <div>
            <div class="text-right mr-4">
              <v-btn
                v-if="$route.query.performanceFlag"
                icon
                :to="{
                  path: '/performance/reviews/monthly/monthly-reviews',
                  query: { id: month.id, status: month.status },
                }"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn icon v-if="$route.query.myTeamFlag">
                <v-icon @click="getMonthlyReview(month.id)">mdi-eye</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </div> -->
    <!-- <div v-else>
      <monthly-review ref="monthly_review"></monthly-review>
    </div> -->

    <!-- anju new design start -->

    <v-row v-if="monthCardFlag">
      <!-- <v-alert  outlined
      type="warning"
      prominent
      border="left" v-show="monthsList.length==0">
    Self review is not complete
    </v-alert> -->
      <v-col md="4" v-for="month in monthsList" :key="month.id">
       <div class="review-boxes">
          <h5 class="month">
            {{
              new Date(month.date).toLocaleDateString("en-us", {
                month: "long",
              })
            }}
          </h5>
          <!-- <span class="date"> {{ month.date  }}</span> -->
          <span class="date"> {{ current_year }}</span>
        
   <div class="view-det">
           <v-btn elevation="0"
                v-if="$route.query.performanceFlag"
                :to="{
                  path: '/performance/reviews/monthly/monthly-reviews',
                  query: { id: month.id, status: month.status, month: month.month },
                }"
              >
            View Details
                <!-- <v-icon>mdi-eye</v-icon> -->
              </v-btn>
              <v-btn elevation="0" v-if="$route.query.myTeamFlag" @click="getMonthlyReview(month.id)">View Details
                <!-- <v-icon @click="getMonthlyReview(month.id)">mdi-eye</v-icon> -->
              </v-btn>
          </div>

          <div class="status">
            <span class="badge badge-success" v-if="month.status === 'Active'"
              >Active</span
            >
            <span
              class="badge badge-success"
              v-if="month.status === 'Completed'"
              >Completed</span
            >
            <span
              class="badge badge-success"
              v-if="month.status === 'InProgress'"
              >In Progress</span
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-else>
      <monthly-review ref="monthly_review"></monthly-review>
    </div>

    <!-- anju new design end -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MonthlyReview from "@/views/my-team/monthly/MonthlyReview.vue";

export default {
  components: {
    MonthlyReview,
  },
  props: {
    performanceFlag: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      user_id: null,
      team_user_id: null,
      monthCardFlag: true,
      myTeamFlag: null,
      current_year: null,
      userData:{
        user_id:null
      },
      teamData:{
        user_id:null
      }
    };
  },
  computed: { ...mapGetters(["monthsList"]) },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        // console.log(this.$route.query.myTeamFlag);
        // console.log("monthCardFlag", this.$route.query.monthCardFlag);
        if (this.$route.query.myTeamFlag) {
          this.myTeamFlag = true;
        }
        if (this.$route.query.monthCardFlag) {
          this.monthCardFlag = true;
        }
      },
    },
  },

  created() {
    this.userData.user_id = localStorage.getItem("user_id");
    this.getCurrentYear();
  },

  mounted() {
    this.callApis();
    // console.log("asdda", this.$route.query.monthCardFlag);
    if (this.$route.query.monthCardFlag === "false") {
      this.monthCardFlag = false;
      // console.log(this.monthCardFlag);
    }
  },

  methods: {
    callApis() {
      if (this.myTeamFlag) {
        this.getMonthsForTeam();
      } else this.getMonths();
    },
    getCurrentYear() {
      this.current_year = new Date().getFullYear();
    },

    getMonthlyReview(selfReviewId) {
      // console.log(selfReviewId);
      this.monthCardFlag = false;
      this.$router.push({
        name: "Team Member Monthly Reviews",
        query: {
          selfReviewId: selfReviewId,
          tab: "reviews",
          monthlyGetReviewFlag: true,
          monthCardFlag: false,
          roleId: this.$route.query.roleId,
          userId: this.$route.query.userId,
        },
      });
    },

    getMonths() {
      this.$store.dispatch("getMonths", this.userData);
    },

    getMonthsForTeam() {
      this.teamData.user_id= this.$route.query.userId;
      this.$store.dispatch("getMonths", this.teamData);
    },
  },
};
</script>
