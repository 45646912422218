 <template>
 <div>
 <h5 class="table-ttl">Balance Score Card</h5>
        <div class="table-common">
          <table class="w-100">
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" scope="col" class="text-start">
                  <span>Objective/Task</span>
                </th>
                <th role="columnheader" scope="col" class="text-start">
                  <span>Weight</span>
                </th>
                <th role="columnheader" scope="col" class="text-start">
                  <span>Expectation</span>
                </th>
                <th role="columnheader" scope="col" class="text-start">
                  <span>Achievement</span>
                </th>
                <th role="columnheader" scope="col" class="text-start">
                  <span>Score</span>
                </th>
              </tr>
            </thead>
            <tbody  v-for="(item,index) in ResultsList.yearly_review" :key="index">
              <tr class="">
                <td class="text-start">{{item.title}}</td>
                <td class="text-start">{{item.weightage}}%</td>
                <td class="text-start">{{item.total_expectation}}</td>
                <td class="text-start">{{item.total_achievement}}</td>
                <td class="text-start">{{item.score}}</td>
              </tr>
              <!-- <tr class="">
                <td class="text-start">
                  Productivity of junior Resources post training
                </td>
                <td class="text-start">10%</td>
                <td class="text-start">160</td>
                <td class="text-start">-</td>
                <td class="text-start">0.00</td>
              </tr>
              <tr class="">
                <td class="text-start">Customer Satisfaction</td>
                <td class="text-start">5%</td>
                <td class="text-start">9.50</td>
                <td class="text-start">-</td>
                <td class="text-start">0.00</td>
              </tr>
              <tr class="">
                <td class="text-start">Acceptance of code by Customers</td>
                <td class="text-start">10%</td>
                <td class="text-start">100</td>
                <td class="text-start">-</td>
                <td class="text-start">0.00</td>
              </tr> -->
            </tbody>
          </table>
        </div>
    </div>
</template>
<script>
import axios from "@/axios";

export default {
 
  
  data: () => ({
     yearData: {
      user_id: null,
      performance_year_id: null,
    },
    ResultsList: ''
  
  }),
  props: {
      userId: {
          type: Number,
          required: true,
      }
  },

   mounted() {
     this.getYearlyReview();
   },

  created() {
    
  },

  methods: {
   getYearlyReview() {
      const token = localStorage.getItem("accessToken");
    this.yearData.user_id= this.userId;
      if (token) {
        axios
          .post(
            "yearly_review_show",
            this.yearData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            this.ResultsList = response.data.results;
            this.$emit("yearReviewResults", this.ResultsList);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>