<template>
  <v-container fluid>
    <v-card elevation="5" width="200">
      <div>
        <img height="200" width="200" :src="getDetails.profile_photo_url" />
      </div>
    </v-card>
    <v-card style="margin: -200px 0 0 200px" height="200px" width="1350px">
      <div class="text-left ml-5 mb-1">
        <h2>{{getDetails.name}}</h2>
      </div>
      <v-card elevation="0" width="1350px" height="50" class="text-left mt-2">
        <v-row>
          <v-col class="caption ml-14">
            <v-icon Medium class="mb-2">mdi-arch</v-icon> Cochin
          </v-col>
          <v-col class="caption ">
            <v-icon Medium class="mb-2">mdi-email</v-icon> {{getDetails.email}}
          </v-col>
          <v-col class="caption ">
            <v-icon Medium class="mb-1">mdi-phone</v-icon>Not Available
          </v-col>
        </v-row>
      </v-card>

      <v-card elevation="0" width="1350px" height="50" class="text-left mt-2">
        <v-row>
          <v-col class="caption ml-5">
            <v-card-subtitle class="overline ml-5">JOB-TITLE</v-card-subtitle>
            <div class="ml-6">{{getDetails.role.role_name}}</div>
          </v-col>
          <v-col class="caption">
            <v-card-subtitle class="overline ml-n6">DEPARTMENT</v-card-subtitle>
            Software
          </v-col>
          <v-col class="caption">
            <v-card-subtitle class="overline ml-n5"
              >REPORTING TO</v-card-subtitle
            >
            Akhilesh P V
          </v-col>
          <v-col class="caption">
            <v-card-subtitle class="overline ml-n6">EMP NO</v-card-subtitle>
            C752
          </v-col>
        </v-row>
      </v-card>
    </v-card>

    <!-- <v-tabs color="red" slider-color="blue">
      <v-tab> ABOUT </v-tab> -->
      <!-- <v-tab-item> -->
        <!-- <v-card height="100%" width="700px" outlined class="mt-5">
          <v-card-title class="body-2 text-left font-weight-regular"
            >Professional Summary
            <v-spacer></v-spacer>
            <div>
              <v-btn @click="editSummary" color="grey" outlined small dark>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div></v-card-title
          >
          <v-col>
            <v-textarea outlined v-if="flag" label=""> </v-textarea>
            <div class="text-right">
              <v-btn
                rounded
                color="primary"
                small
                class="mb-4"
                v-if="flag"
                @click="updateSummary"
              >
                Update
              </v-btn>
            </div>
          </v-col>
        </v-card> -->
        <!-- <div>
          <v-row>
            <v-col cols="12" sm="6" offset-sm="3"> </v-col>
            <v-card class="about-me">
              <v-card-title class="body-2 text-left font-weight-regular"
                >Personal Summary</v-card-title
              >
              <v-list two-line>
                <v-subheader>
                  <v-card-text class="body-2 text-left font-weight-regular"
                    >Blood Group</v-card-text
                  >
                </v-subheader>
                <v-divider></v-divider>
                 <v-subheader>
                  <v-card-text class="body-2 text-left font-weight-regular"
                    >Date of Birth</v-card-text
                  >
                </v-subheader>
                <v-divider></v-divider>
                <v-subheader>
                  <v-card-text class="body-2 text-left font-weight-regular"
                    >Maritial Status</v-card-text
                  >
                </v-subheader>
                <v-divider></v-divider>
                 <v-subheader>
                  <v-card-text class="body-2 text-left font-weight-regular"
                    >Total Experiance(in years)</v-card-text
                  >
                </v-subheader>
                <v-divider></v-divider>
              </v-list>
            </v-card>
          </v-row>
        </div> -->
      <!-- </v-tab-item> -->

      <!-- <v-tab>Assigned Assets</v-tab> -->
    <!-- </v-tabs> -->
  </v-container>
</template>

<script>
import { mdiPencil } from "@mdi/js";
import { mapGetters } from 'vuex';
// import UserTeams from "./UserTeams.vue";

export default {
  // components: { UserTeams },
  data() {
    return {
      icons: {
        mdiPencil,
      },
      flag: false,
      allData:null,
    };
  },
  computed:{
    ...mapGetters(['getDetails'])
  },
  methods: {
    editSummary() {
      this.flag = true;
    },
    updateSummary() {
      this.flag = false;
    },
    
  },
};
</script>
<style scoped>
.about-me {
  width: 30%;
}
</style>
