<template>
  <v-container class="py-0">
    <loading :active="isLoading" />

    <h5 class="role">{{ this.$route.query.name }}</h5>
    <!-- <p>{{kraKpiListNew}}</p> -->
    <v-row>
      <v-col>
        <perfomance-sheet-modal :kraStatus="kraStatus" />
      </v-col>
    </v-row>
    <div>
      <h5 class="weightage">Total weightage:{{ defaultWeightage }}</h5>
    </div>
    <div>
      <div
        class="kra-boxes"
        v-for="roles in kraKpiListNew.performance_kras"
        :key="roles.id"
      >
        <div class="kra-title">
          <h5 class="ttl">{{ roles.title }}</h5>
          <div class="weighage">
            <h5 class="my-2">Total Weightage:</h5>
            <h4 class="mb-0">{{ roles.weightage }}</h4>
          </div>
        </div>
        <!-- <v-card elevation="0" class="font-weight-bold mx-auto color-light-grey">
          <v-list-item class="dark--text mt-2 overline">
            {{ roles.title }}<v-spacer></v-spacer>Weightage: {{ roles.weightage }}
          </v-list-item>
        </v-card> -->
        <div>
          <AddKpiModal
            :performance-kpis="roles.performance_kpis"
            :kpiNotAssigned="kpiNotAssigned"
            :kraStatus="kraStatus"
          />
          <!-- <key-performance-index
            @add-details="addDetails"
            :kraStatus="kraStatus"
            :performance-kpis="roles.performance_kpis"
            :buttonFlag="$route.query.id"
            :editFlag="$route.query.id"
          ></key-performance-index> -->
          <key-performance-index
            :kraStatus="kraStatus"
            :performance-kpis="roles.performance_kpis"
            :buttonFlag="$route.query.id"
            :editFlag="$route.query.id"
          ></key-performance-index>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import PerfomanceSheetModal from "../settings/PerfomanceSheetModal.vue";
import KeyPerformanceIndex from "@/views/performance/kra-kpi/KeyPerformanceIndex.vue";
import AddKpiModal from "../settings/AddKpiModal.vue";
import Loading from "vue-loading-overlay";

import axios from "@/axios";
// import KraKpiList from "../settings/KraKpiList.vue";
// import { mapGetters } from "vuex";

export default {
  components: {
    PerfomanceSheetModal: PerfomanceSheetModal,
    KeyPerformanceIndex,
    AddKpiModal,
    Loading,
    // KraKpiList: KraKpiList,
  },
  // computed: { ...mapGetters(["allKraKpi"]) },

  data() {
    return {
      activeYear: "false",
      role_id: null,
      kraKpiListNew: "",
      kpiNotAssigned: null,
      testArray: [],
      kraStatus: null,
      defaultWeightage: 0,
      testValue: 0,
      isLoading: false,
    };
  },

  methods: {
    // getKraKpis() {
    //   this.$store.dispatch("getKraKpis", this.role_id);
    // },
    getKrasAndKpisBasedOnYear() {
            this.isLoading = true;

      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .post(
            "kra-kpi-role_settings_list",
            {
              performance_year_id: this.year_id,
              role_id: this.$route.query.role_id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.kraKpiListNew =
              response.data.results.year_kras_kpis_initiatives;
            this.kraId = response.data.results.performance_kras;
            this.kraStatus =
              response.data.results.year_kras_kpis_initiatives.status;
            for (let item of response.data.results.year_kras_kpis_initiatives
              .performance_kras) {
              console.log("helloo", item.performance_kpis);
              // this.testValue=item.performance_kpis
              for (let subitem of item.performance_kpis) {
                // this.testValue=subitem.performance_role_kpis
                for (let testitem of subitem.performance_role_kpis) {
                  this.testValue += testitem.weightage;
                  this.defaultWeightage = this.testValue;
                }
              }
            }
                  this.isLoading = false;

          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    getKpiNotAssigned() {
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .post(
            "kpiNotAssigned",
            {
              role_id: this.$route.query.role_id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            // this.kpiNotAssignedTest = response.data.results;

            this.testArray = response.data.results;
            let unique = {};
            for (let itemKpi of this.testArray) {
              unique[itemKpi.title] = itemKpi;
            }
            let arrayNew = Object.values(unique);
            this.kpiNotAssigned = arrayNew;
            // eslint-disable-next-line no-debugger

            console.log("kpiNotAssigned", this.kpiNotAssigned);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
  mounted() {
    this.getKrasAndKpisBasedOnYear();
    this.getKpiNotAssigned();
    this.role_id = this.$route.query.role_id;

    // this.getKraKpis();
  },
};
</script>
<style scoped>
.test {
  position: relative;
}
.weightage {
  text-align: right;
  padding-right: 52px;
}
</style>
