<template>
  <div class="text-left caption">
    <!-- {{ reviewData }} -->
    <div>
      <v-row class="ml-1"
        >Aggregate score: {{ reviewData.aggregated_metric }}</v-row
      >
    </div>
    <div class="mt-3" v-if="reviewData.changed_metric != null">
      <v-row class="ml-1"
        >Aggregate score by RM: {{ reviewData.changed_metric }}</v-row
      >
      <v-row class="ml-1">Reason by RM: {{ reviewData.comment }} </v-row>
    </div>
    <v-col md="1" class="text-left">
      <period-reviews :kpi_id="getReviewData.performance_kpi_id" :userId="userId"/>
    </v-col>
  </div>
</template>

<script>
import PeriodReviews from "@/views/performance/reviews/quarterly/PeriodReviews.vue";
import axios from "@/axios";

export default {
  components: {
    PeriodReviews,
  },
  props: {
    kpi_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      getReviewData: {
        performance_quarterly_review_id: null,
        performance_kpi_id: null,
      },
      reviewData: null,
      changed_metric: null,
      comment: null,
    };
  },

  mounted() {
    this.getPerformanceQuarterlyReview();
    const userId = localStorage.getItem("user_id");
    console.log("userId",userId)
  },

  methods: {
    getPerformanceQuarterlyReview() {
      this.getReviewData.performance_quarterly_review_id =
        this.$route.query.quarterly_id;
      this.getReviewData.performance_kpi_id = this.kpi_id;
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .post(
            "quarterlyreviewkpi-list",
            this.getReviewData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data.results);
            if (response.data.results[0]) {
              this.reviewData = response.data.results[0];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>