import axios from "@/axios";
import router from '@/router'

const state = {
  kpiList: [],
  // CODE_REVIEW: Initialize this value with a Boolean since that is the type of value that is used
  kpiSuccessSnackbar: false,
  kpiErrorSnackbar: false,

};

const getters = {
  allKpi: state => state.kpiList,
  kpiSuccessSnackbar: state => state.kpiSuccessSnackbar,
  kpiErrorSnackbar: state => state.kpiErrorSnackbar,

};

const mutations = {
  ADD_KPI(state, data) {
    state.kpiList.push(data);
    state.kpiSuccessSnackbar = true;
  },
  GET_KPIS(state, data) {
    state.kpiList = data;
  },
  UPDATE_KPI(state, data) {
    const index = state.kpiList.findIndex(kpiList => kpiList.id === data.id);
    if (index !== -1) {
      state.kpiList.splice(index, 1, data);
    }
    state.kpiSuccessSnackbar = true;
  },
  DELETE_KPI(state, id) {
    state.kpiList = state.kpiList.filter(
      (kpiList) => kpiList.id !== id
    );
    state.kpiSuccessSnackbar = true;
  }
};

const actions = {
  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async addKpi({ commit, state }, kpiData) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .post(
          "performance-kpis-store",
          kpiData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // coverting first letter of a string in JSON response to caps starts
          const getAllYears = response.data.results;
          // CODE_REVIEW: Extract the replacer function of JSON.stringify to separate function to avoid repetition
          let userObj = JSON.stringify(getAllYears, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });
          var parsed = JSON.parse(userObj);
          // coverting first letter of a string in JSON response to caps ends
          commit("ADD_KPI", parsed);
        })
        .catch((error) => {
          // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 71
          console.log(error);
          state.kpiErrorSnackbar = true;
          throw new Error(error)
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async getKpis({ commit, state }, data) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40,
    const token = localStorage.getItem("accessToken");
    state.kpiList = []
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .post(
          "performance-kpis-show",
          { performance_kra_id: data },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log(response.data.results);
          // coverting first letter of a string in JSON response to caps starts
          const getAllYears = response.data.results;
          // CODE_REVIEW: Extract the replacer function of JSON.stringify to separate function to avoid repetition
          let userObj = JSON.stringify(getAllYears, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });
          var parsed = JSON.parse(userObj);
          // coverting first letter of a string in JSON response to caps ends
          commit("GET_KPIS", parsed);
        })
        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },
  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async updateKpi({ commit }, payload) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .put(
          "performance-kpis-update/" +
          payload.id,
          payload.formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // coverting first letter of a string in JSON response to caps starts
          const getAllYears = response.data.results;
          // CODE_REVIEW: Extract the replacer function of JSON.stringify to separate function to avoid repetition
          let userObj = JSON.stringify(getAllYears, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });
          console.log(userObj);
          var parsed = JSON.parse(userObj);
          console.log(parsed);
          // coverting first letter of a string in JSON response to caps ends
          commit("UPDATE_KPI", parsed);
        })
        .catch((error) => {
          // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 71
          console.log(error);
          state.kpiErrorSnackbar = true;
          throw new Error(error)
        });
    } else {
      router.push({ path: "/login" });
    }
  },
  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async deleteKpi({ commit, state }, id) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .delete(
          "performance-kpis-delete/" + id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          console.log(result.data.id);
          commit("DELETE_KPI", id);
        })

        .catch((error) => {
          // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 71
          console.log(error.response.data.message);
          if (error.response.data.message === "cannot be deleted because role kpi is exist") {
            state.kpiErrorSnackbar = true;
          }
          throw new Error(error)


        });
    } else {
      router.push({ path: "/login" });
    }
  },
};

export default { state, mutations, actions, getters };

