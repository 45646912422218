import axios from "@/axios";
import router from '@/router'

const state = {
  userList: [],
  permissions:[],
  allDetails:[],
  SuccessSnackbar: false,
  kraErrorSnackbar: false, 
};

const getters = {
  user: state => state.userList,
  getPermissions:state=>state.permissions,
  getDetails:state=>state.allDetails,
  SuccessSnackbar: state => state.SuccessSnackbar,
  ErrorSnackbar: state => state.ErrorSnackbar,
};

const mutations = {
  STORE_USER(state, data) {
    state.userList.push(data);
    state.SuccessSnackbar = true;
  },
  LOAD_USER(state, data) {
    state.userList = data;
  },
  UPDATE_USER(state, data) {
    const index = state.userList.findIndex(userList => userList.id === data.id);
    if (index !== -1) {
      state.userList.splice(index, 1, data);
    }
    state.SuccessSnackbar = true;
  },
  DELETE_USER(state, id) {
    state.userList = state.userList.filter(
      (userList) => userList.id !== id
    );
    state.SuccessSnackbar = true;
  },
  ADD_PERMISSIONS(state,data){
    state.permissions=data
  },
  GET_DETAILS(state,data){
    state.allDetails=data
  }
};

const actions = {
  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async storeUser({ commit }, userData) {
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .post("user-create", userData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          commit("STORE_USER", response.data.results);
        })
        .catch((error) => {
          // CODE_REVIEW: The request failure status is not handled properly here.
          // Error handler here only just logs the error and will not pass error exception to the caller.
          // So the request always appears to succeed to the caller. Need to rethrow error if want it to be propagated to caller.
          console.log(error);
          state.ErrorSnackbar = true;
          throw new Error(error)
        });
    } else {
      this.$router.push({ path: "/login" });
    }
  },
  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async updateUser({ commit }, payload) {
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .post(
          "user-update/" + payload.id,
          payload.formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          commit("UPDATE_USER", response.data.results);
        })
        .catch((error) => {
          // CODE_REVIEW: The request failure status is not handled properly here.
          // Error handler here only just logs the error and will not pass error exception to the caller.
          // So the request always appears to succeed to the caller. Need to rethrow error if want it to be propagated to caller.
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async loadUsers({ commit, state }) {
    state.userList = []
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .get("user-view", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          commit("LOAD_USER", response.data.results);
        })
        .catch((error) => {
          // CODE_REVIEW: The request failure status is not handled properly here.
          // Error handler here only just logs the error and will not pass error exception to the caller.
          // So the request always appears to succeed to the caller. Need to rethrow error if want it to be propagated to caller.
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async deleteUser({ commit }, id) {
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .delete("user-delete/" + id, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          console.log(result.data.id);
          commit("DELETE_USER", id);
        })

        .catch((error) => {
          // CODE_REVIEW: The request failure status is not handled properly here.
          // Error handler here only just logs the error and will not pass error exception to the caller.
          // So the request always appears to succeed to the caller. Need to rethrow error if want it to be propagated to caller.
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },
};

export default { state, mutations, actions, getters };