<template>
  <v-app class="login-app">
    <v-content>
      <v-card max-width="475" class="mx-auto my-16 pt-6 loginbox" elevation="0">
        <v-card-title v-if="$route.query.flag === true">
          <h6 class="subtitle-1 font-weight-bold text-center w-100 my-2">
            Want to change Password ?
          </h6>
        </v-card-title>
        <v-card-title v-else>
          <h6 class="subtitle-1 font-weight-bold text-center w-100 my-2">
            Forgot Password??
          </h6>
        </v-card-title>
        <v-card-text class="pb-0 px-5">
          No worries!! Enter your email and we will sent you a reset code
          <v-col>
            <div @click="showSaveButton" class="textfield">
              <v-text-field
                placeholder="Enter your email address"
                outlined
                prepend-inner-icon="mdi-account-circle"
                :rules="[rules.required, rules.email]"
                v-model="formData.email"
              ></v-text-field>
              <div>
                <v-icon class="icon"> mdi-account-circle </v-icon>
              </div>
            </div>
          </v-col>
        </v-card-text>

        <div>
          <v-row class="pb-5 px-1">
            <v-col class="text-left ml-5">
              <router-link to="/login">Back to login ?</router-link>
            </v-col>
            <v-col class="text-right mr-4">
              <v-btn
                class="px-5 caption"
                rounded
                color="primary"
                @click="delay"
                :disabled="disabled || hide"
                :loading="loading"
              >
                get otp
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-snackbar
        min-width="0"
        app
        right
        top
        color="red accent-2"
        v-model="show"
        timeout="5000"
        >{{ text }}</v-snackbar
      >
    </v-content>
  </v-app>
</template>

<script>
import axios from "@/axios";
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid E-mail.";
        },
      },
      formData: {
        email: null,
      },
      hide: true,
      show: false,
      text: "",
      disabled: false,
      loading: false,
      timeout: null,
    };
  },
  methods: {
    delay() {
      this.disabled = true;
      this.timeout = setTimeout(() => {
        this.disabled = false;
      }, 3000);
      this.submitMail();
    },
    showSaveButton() {
      this.hide = false;
    },

    submitMail() {
      this.loading = true;
      axios
        .post("forgot", this.formData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          return this.$router.push({
            name: "One Time Password",
            query: { email: this.formData.email },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.show = true;
          console.log("##", error.response.data.message);
          if (error.response.data.errors) {
            this.text = error.response.data.errors.email[0];
          } else {
            this.text = "Something went wrong";
          }
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.textfield {
  position: relative;
}
.icon {
  position: absolute;
  top:2px;
  left: 5px;
  bottom:5vh;
}
</style>
