var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-tabs',{staticClass:"mx-0 sub-maintab",attrs:{"height":"40","slider-color":"cyan","flat":""}},[(_vm.$route.path === '/performance/reviews/monthly/monthly-reviews')?_c('div',[_c('v-tab',{staticClass:"custom-transform-class text-none caption v-tab--active v-tab",attrs:{"to":{
          path: '/performance/reviews/monthly',
          query: { performanceFlag: true },
        }}},[_vm._v(" Monthly ")])],1):_c('div',[_c('v-tab',{staticClass:"custom-transform-class text-none caption ",attrs:{"to":{
          path: '/performance/reviews/monthly',
          query: { performanceFlag: true },
        }}},[_vm._v(" Monthly ")])],1),_c('v-tab',{staticClass:"custom-transform-class text-none caption",attrs:{"to":{
          path: '/performance/reviews/quarterly',
          query: { performanceFlag: true },
        }}},[_vm._v(" Quarterly ")])],1)],1),_c('div',[_c('router-view')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }