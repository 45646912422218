<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <loading :active="isLoading" />

        <!-- <v-btn
          class="mt-n6 ml-2"
          x-small
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="getInitiatives"
          >Manage Initiative</v-btn
        > -->

        <span
          v-if="$route.query.initiativeFlag"
          class="badge badge-primary"
          ref="modal"
          v-bind="attrs"
          v-on="on"
          @click="getInitiatives"
          >Manage Initiative</span
        >
        <span
          v-else
          class="badge badge-primary"
          ref="modal"
          v-bind="attrs"
          v-on="on"
          @click="getInitiatives"
          >View Initiatives</span
        >
      </template>
      <v-card class="custom-modals">
        <v-card-title class="modal-titles justify-content-between">
          <span class="text-h6 text-white" v-if="$route.query.initiativeFlag"
            >Manage Initiatives</span
          >
          <span class="text-h6 text-white" v-else>Initiatives</span>
          <v-btn
            icon
            color="white"
            class="grey--lighten-7 px-5 caption"
            @click="dialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-2 px-3">
          <v-container>
            <div class="text-left">
              <v-form lazy-validation ref="form">
                <v-card elevation="0" v-if="$route.query.initiativeFlag">
                  <div v-if="addActive">Add Initiative</div>
                  <div v-if="editActive">Edit Initiative</div>
                  <v-textarea
                    filled
                    v-model="formData.initiative"
                    :rules="[rules.required, rules.length]"
                    label="Initiative"
                    rows="2"
                  ></v-textarea>
                  <div class="text-right mt-n5" v-if="addActive">
                    <v-btn
                      rounded
                      class="btn-primary caption px-5"
                      small
                      @click="addInitiative"
                      >Add
                    </v-btn>
                  </div>
                  <div class="text-right mt-n5" v-if="editActive">
                    <v-btn
                      rounded
                      class="primary caption px-5"
                      color="blue darken-1"
                      small
                      @click="closeModal"
                      >Cancel
                    </v-btn>
                    <v-btn
                      rounded
                      class="primary caption px-5"
                      color="blue darken-1"
                      small
                      @click="updateInitiative"
                      >Update
                    </v-btn>
                  </div>
                </v-card>
              </v-form>
              <div>
                <v-card
                  elevation="0"
                  width="100%"
                  class="mx-auto mt-2 table-common"
                >
                  <div v-if="$route.query.initiativeFlag" class="table">
                    <v-simple-table class="striped-table manage-initiative">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Initiatives</th>
                            <th
                              v-if="$route.query.initiativeFlag"
                              class="text-left"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in initiativeList" :key="item.id">
                            <td class="text-left initiative-text ml-3">
                              <p class="initiativecontent">
                                {{ item.initiative }}
                              </p>
                            </td>
                            <td class="options-btns">
                              <v-btn
                                v-if="$route.query.initiativeFlag"
                                class="edit-btn mr-2"
                                elevation="0"
                                ><v-icon @click="editModal(item)" small
                                  >mdi-pencil</v-icon
                                ></v-btn
                              >
                              <v-btn
                                v-if="$route.query.initiativeFlag"
                                class="delete-btn"
                                elevation="0"
                                ><v-icon
                                  @click="deleteInitiative(item.id)"
                                  small
                                  >mdi-delete</v-icon
                                ></v-btn
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                  <div v-else-if="$route.query.role_id">
                    <v-simple-table class="striped-table manage-initiative">
                      <template v-slot:default>
                        <thead>
                          <tr>
                          <th class="text-left">Initiatives</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- <tr v-for="item in initiativeList" :key="item.performance_role_kpi_id"> -->
                          <tr>
                            <td class="text-left">
                              <!-- <p>{{initiativeList}}</p> -->
                              <p class="initiativecontent">
                                {{ initiativeList.initiative }}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                  <div v-else>
                                      <v-simple-table class="striped-table manage-initiative">
                      <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Initiatives</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in initiativeList" :key="item.id">
                        <td class="text-left ml-3">
                          <p class="initiativecontent">{{ item.initiative }}</p>
                        </td>
                      </tr>
                    </tbody>
                      </template>
                                      </v-simple-table>
                  </div>
                </v-card>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5 px-5">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="grey--lighten-7 px-5 caption"
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      min-width="0"
      app
      :color="color"
      right
      top
      v-model="show"
      timeout="2000"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import Loading from "vue-loading-overlay";

export default {
  props: {
    performance_role_kpi_id: {
      required: true,
    },
    kpi_id: {
      required: true,
    },
    userId: {
      required: true,
    },
  },
  components: {
    Loading,
  },

  data: () => ({
    dialog: false,
    addActive: true,
    editActive: false,
    initiativeId: null,
    show: false,
    text:null,
    isLoading: false,
    color:null,
    formData: {
      performance_role_kpi_id: null,
      type: "User",
      initiative: null,
    },
    initiativeData: {
      user_id: null,
      performance_kpi_id: null,
      role_id: null,
    },
    initiativeList: [
      {
        id: null,
        initiative: null,
      },
    ],
    rules: {
      required: (value) => !!value || "Required",
      length: (value) =>
        (value && value.length <= 250) || "Maximum 250 characters",
    },
  }),

  // mounted() {
  //   this.getInitiatives();
  // },

  mounted() {
    this.initiativeData.user_id =
      this.userId || localStorage.getItem("user_id");
    this.initiativeData.role_id = this.$route.query.role_id
      ? this.$route.query.role_id
      : null;
  },

  methods: {
    editModal(item) {
      this.editActive = true;
      this.addActive = false;
      this.initiativeId = item.id;
      this.formData.initiative = item.initiative;
    },
    closeModal() {
      this.addActive = true;
      this.editActive = false;
      this.formData.initiative = null;
      this.$refs.form.reset();
    },
    getInitiatives() {
      this.isLoading = true;
      const token = localStorage.getItem("accessToken");
      this.initiativeData.performance_kpi_id = this.kpi_id;
      if (token) {
        axios
          .post("initiativesList", this.initiativeData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            this.isLoading = false;
            this.initiativeList = response.data.results;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },

    addInitiative() {
      if (this.$refs.form.validate()) {
        this.formData.performance_role_kpi_id = this.performance_role_kpi_id;
        const payload = { ...this.formData };
        this.$store.dispatch("addInitiative", payload).then(() => {
          this.show = true;
          this.text = "added successfully";
          this.color = "success";
          this.formData.initiative = null;
          this.getInitiatives();
          this.$refs.form.reset();
        });
      }
    },
    updateInitiative() {
      if (this.$refs.form.validate()) {
        this.addActive = true;
        this.editActive = false;
        this.formData.performance_role_kpi_id = this.performance_role_kpi_id;
        const payload = { id: this.initiativeId, formData: this.formData };
        this.$store.dispatch("updateInitiative", payload).then(() => {
          this.show = true;
          this.text = "updated successfully";
          this.color = "success";
          this.formData.initiative = null;
          this.getInitiatives();
          this.$refs.form.reset();
        });
      }
    },

    deleteInitiative(id) {
      this.$store.dispatch("deleteInitiative", id).then(() => {
        this.getInitiatives();
        this.show = true;
        this.text = "Successfully deleted";
        this.color = "success";
      });
    },
  },
};
</script>
<style scoped>
.initiativecontent {
  margin: 0;
  padding: 0;
  text-align: justify;
  /* -webkit-line-break:after-white-space; */
  /* font-style:inherit; */
  /* font-display:calc(); */
}
.table {
  /* height:200px; */
  overflow-y: hidden;
}
</style>
