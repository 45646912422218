<template>
  <div>
    <!-- {{ userId }}{{ roleId }} -->
    <!-- <div
      v-for="roles in allKraKpi.performance_kras"
      :key="roles.id"
      class="mb-5"
    >
      <v-card elevation="0" class="font-weight-bold mx-auto color-light-grey">
        <v-list-item class="dark--text mt-2 subtitle-1">
          {{ roles.title }} <v-spacer></v-spacer>weightage:
          {{ roles.weightage }}
        </v-list-item>
      </v-card>
      <div>
        <div class="mt-1">
          <key-performance-index
            class="mb-1"
            :performance-kpis="roles.performance_kpis"
            :userId="userId"
            @totalWeightageSum="sumWeightage"
          ></key-performance-index>
        </div>
      </div>
    </div>   -->

    <!-- new design started -->

      <div class="kra-boxes" 
      v-for="roles in allKraKpi.performance_kras"
      :key="roles.id">
      <div class="kra-title">
        <h5 class="ttl">{{ roles.title }}</h5>
        <div class="weighage">
          <h5 class="my-2">Total Weightage: </h5>
        <h4 class="mb-0">{{ roles.weightage }}</h4> 
        </div>
      </div> 
       <!-- <key-performance-index
            class="mb-1"
            :performance-kpis="roles.performance_kpis"
            :userId="userId"
            @totalWeightageSum="sumWeightage"
          ></key-performance-index> -->
                 <key-performance-index
            class="mb-1"
            :performance-kpis="roles.performance_kpis"
            :userId="userId"
          ></key-performance-index>

    <!-- new design ended -->

<!-- anju design -->

      <!-- <div class="kpi-boxes">
        <div class="content-area">
          <div class="title">
            <h6 class="font-weight-bold my-2 mr-2">Time</h6>
           <span class="badge badge-primary">View Initiative</span>
          </div>
          <div class="kpi-det">
            <h6>weightage : <span> 25</span></h6>
            <h6>KPI/Unit of measure : <span>Percent</span> </h6>
            <h6>Expectation : <span>75</span></h6>
          </div>
          <p class="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <div class="image-area">
        </div>
      </div> -->
    </div>
    
 <!-- anju design ended -->
  </div>
</template>

<script>
import KeyPerformanceIndex from "@/views/performance/kra-kpi/KeyPerformanceIndex.vue";
import { mapGetters } from "vuex";

export default {
  components: { KeyPerformanceIndex },
  props: {
    userId: {
      required: true,
    },
    roleId: {
      required: true,
    },
  },
  computed: { ...mapGetters(["allKraKpi"]) },

  data() {
    return {
      listData:{role_id: null,performance_year_id:null},
      teamData:{ role_id: null,performance_year_id:null},
      
     sumWeightage:null,
      flag: null,
      totalWeightageKra: "",
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.myTeamFlag) {
          // console.log("haaii");
          this.flag = true;
        }
      },
    },
  },

  created() {
    this.listData.role_id = localStorage.getItem("role_id");
  },

  mounted() {
    // CODE_REVIEW: Just checking if the teamFlag is on query is enough. No need to compare it to true value
    if(!this.$route.query.userKraKpiFlag) {
      this.callApis();
    }
  },

  methods: {
    callApis() {
      if (this.flag) {
        console.log("hai");
        this.getKraKpisForTeam();
      } else {
        console.log("hello");
        this.getKraKpis();
      }
    },
    getKraKpis() {
      this.$store.dispatch("getKraKpis", this.listData);
    },
    getKraKpisForTeam() {
      this.teamData.role_id = this.roleId || this.$route.query.roleId;
      this.$store.dispatch("getKraKpis", this.teamData);
    },

  },
};
</script>
<style lang="scss">
</style>
