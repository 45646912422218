<template>
  <div class="text-center d-flex align-items-center">
    <v-menu open-on-hover offset-y>
      <template v-slot:activator="{ on, attrs }">
        <!-- <p class="mr-2 my-1 text-primary">{{ user_name }}</p> -->

        <v-btn
          elevation="0"
          color="#efefef"
          dark
          width="35"
          height="35"
          class="text-primary fw-bold btnrounded mr-3"
          fab
        >
          <img src="@/assets/images/noti.svg" alt="" width="12" />
        </v-btn>

        <v-btn
          elevation="0"
          color="#efefef"
          dark
          v-bind="attrs"
          v-on="on"
          width="35"
          height="35"
          class="text-primary fw-bold btnrounded"
          fab
        >
          {{ user_name.slice(0, 1) }}
        </v-btn>
      </template>

      <v-list height="210" width="200" class="pt-1">
        <v-list-item >
          <v-row class="mt-0">
            <div class="dropdown-itemsnam mb-2 ">
              <v-btn
                elevation="0"
                color="#fff"
                dark
                width="35"
                height="35"
                class="text-primary fw-bold btnrounded mb-2"
                fab
              >
                {{ user_name.slice(0, 1) }}
              </v-btn>
              <h6 class="mb-0">{{user_name}}</h6>
            </div>
            <v-list-item-title class="px-0">
              <router-link
                :to="{
                  path: '/user-profile',
                  query: { flag : true}
                }"
              >
              <v-btn class="button overline" text color="primary">
                
                Profile
              </v-btn>
              </router-link>
              </v-list-item-title
            >
            <v-list-item-title class="px-0">
            <router-link
                :to="{
                  path: '/check-user-email',
                  query: { flag : true}
                }"
              >
              <v-btn class="button overline" text color="primary">
                Change Password
              </v-btn></router-link></v-list-item-title
            >
            <v-list-item-title class="px-0">
              <v-btn class=" button overline" text color="primary" @click="logout">
                Logout
              </v-btn></v-list-item-title
            >
          </v-row>

        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  data() {
    return {
      user_name: null,
      items: [
        { title: "Profile" },
        { title: "Change Password" },
        { title: "Logout" },
      ],
      closeOnClick: true,
    };
  },

  created() {
    this.user_name = localStorage.getItem("name");
  },

  methods: {
    logout() {
      // CODE_REVEIW: Maybe move the API call to store
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios
          .post(
            "logout",
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("name");
            localStorage.removeItem("email");
            localStorage.removeItem("user_id");
            localStorage.removeItem("otp");
            localStorage.removeItem("role_id");
            this.$router.push({ path: "/login" });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>
<style scoped>
.button{
  align-items: center;
    color: inherit;
    /* padding-right: 50px; */
    display: flex;
    flex: 1 0 auto;
    justify-content: left;
    transition-property: opacity;
}
</style>
