<template>
  <div>
    <!-- Amal bootstrap breadcrumb start -->
    <div class="text-left my-auto ml-n5">
      <bread-crumb :items="breadcrumbs" />
    </div>
    <!-- Amal bootstrap breadcrumb ends -->

    <!-- Vuetify \ breadcrumb start -->
    <!-- <div>
      <bread-crumb-component :items="items" />
    </div> -->
    <!-- Vuetify \ breadcrumb start -->
    <div class="d-flex align-items-center justify-content-between">
      <h5 v-if="!queryKra">Key Performance Index - {{ $route.query.kra }}</h5>
      <h5 else>Key Performance Index - {{ queryKra.kra }}</h5>

      <!-- Modal to add and edit kpi -->
      <div class="">
        <v-btn rounded class="mb-4" color="primary" @click="addModal" small
          >Add KPI</v-btn
        >

        <v-dialog v-model="dialog" persistent max-width="500">
          <v-card>
            <v-card-title
              class="primary justify-content-between"
              v-if="addActive"
            >
              <span class="text-h6 text-white">Add new KPI</span>
              <v-btn
                icon
                color="white"
                class="grey--lighten-7 px-5 caption"
                @click="closeModal"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-title
              class="primary justify-content-between"
              v-if="editActive"
            >
              <span class="text-h6 text-white">Edit KPI</span>
              <v-btn
                icon
                color="white"
                class="grey--lighten-7 px-5 caption"
                @click="closeModal"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-form lazy-validation ref="form">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      ><v-text-field
                        placeholder="New KPI"
                        v-model="kpiData.title"
                        :rules="[rules.required, rules.length]"
                        auto-grow
                        outlined
                        dense
                      ></v-text-field> </v-col
                    ><v-col
                      ><v-text-field
                        rows="1"
                        auto-grow
                        placeholder="Description"
                        v-model="kpiData.description"
                        :rules="[rules.required, rules.length]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col> </v-row
                  ><v-row>
                    <v-col
                      ><v-select
                        :items="measurement_unit_items"
                        placeholder="Measurement Unit"
                        item-text="measurement_unit"
                        v-model="kpiData.measurement_unit"
                        :rules="[rules.required]"
                        outlined
                        dense
                      ></v-select> </v-col
                    ><v-col
                      ><v-text-field
                        placeholder="Measurement Unit Description"
                        v-model="kpiData.measurement_desc"
                        :rules="[rules.required, rules.length]"
                        auto-grow
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                   
                     </v-row>
                     <v-row>
                       <v-col @keydown.enter="addKpi()"
                      ><v-select
                        :items="calculation_process_items"
                        item-text="calculation_process"
                        placeholder="Calculation Process"
                        v-model="kpiData.calculation_process"
                        :rules="[rules.required]"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-5 px-5">
                <v-spacer></v-spacer>
                <v-btn
                  v-if="addActive"
                  rounded
                  class="primary caption px-5"
                  color="blue darken-1"
                  @click="addKpi"
                >
                  Save
                </v-btn>
                <v-btn
                  v-if="editActive"
                  rounded
                  class="primary caption px-5"
                  color="blue darken-1"
                  @click="updateKpi"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <div class="my-2">
      <v-card outlined class="mx-auto border-0">
        <div class="years-bar">
          <h6 class="mb-4 mx-auto">Key Performance Index List</h6>
        </div>
        <v-data-table
          :headers="headers"
          :items="allKpi"
          class="elevation-0 table-common"
          :sort-by="[
            'title',
            'measurement_unit',
            'measurement_desc',
            'calculation_process',
          ]"
        >
          <template v-slot:[`item.actions`]="{ item }">
           <div class="options-btns role-options">
            <span
              @click="
                setQueryParamsKpi({
                  kra_id: item.performance_kra_id,
                  kpi_id: item.id,
                  title: item.title,
                  description: item.description,
                  status: $route.query.status,
                })
              "
            >
              <router-link
                :to="{
                  path: '/performance/admin/year/kras/kpis/kpi-roles',
                  query: {
                    kra_id: item.performance_kra_id,
                    kpi_id: item.id,
                    title: item.title,
                    description: item.description,
                    status: $route.query.status,
                  },
                }"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small class="mr-2">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View Details</span>
                </v-tooltip></router-link
              ></span
            >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="icon-box edit-btn" v-bind="attrs" v-on="on">
                  <v-icon small @click="updateModal(item)"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              <span>Edit Kpi</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="icon-box delete-btn" v-bind="attrs" v-on="on">
                  <v-icon small @click="deleteModal(item.id)"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              <span>Delete Kpi</span>
            </v-tooltip>
            </div>
          </template></v-data-table
        >
      </v-card>
    </div>
    <v-snackbar
      app
      :color="color"
      right
      top
      v-model="toggleSnackbar"
      timeout="3500"
    >
      {{ text }}
    </v-snackbar>
    <!-- delete modal start -->
    <v-dialog v-model="deleteDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="primary justify-content-between">
          <span class="text-h6 text-white">Confirmation</span>
          <v-btn
            icon
            color="white"
            class="grey--lighten-7 px-5 caption"
            @click="deleteDialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="subtitle-1 mt-5">
            Are you sure you want to delete this KPI ?
          </div>
        </v-card-text>
        <v-card-actions class="pb-5 px-5">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            small
            color="error"
            class="caption px-5"
            @click="deleteKpi(deleteId)"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete modal ends -->

    <!-- modal to show can't delete message start -->
    <v-dialog v-model="deleteDisableDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="primary justify-content-between">
          <span class="text-h6 text-white">Warning</span>
          <v-btn
            icon
            color="white"
            class="grey--lighten-7 px-5 caption"
            @click="deleteDisableDialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="subtitle-1 mt-5">
            Released year KPIs list can't be edited
          </div>
        </v-card-text>
        <v-card-actions class="pb-5 px-5">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- modal to show can't delete message ends -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import BreadCrumbComponent from "../../BreadCrumbComponent.vue";
import BreadCrumb from "../../BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
    // BreadCrumbComponent,
  },

  computed: {
    ...mapGetters(["allKpi", "kpiSuccessSnackbar", "kpiErrorSnackbar"]),
    query() {
      return this.$store.getters["breadCrumb/allQuery"];
    },
    queryKra() {
      return this.$store.getters["breadCrumb/allQueryKra"];
    },
    breadcrumbs() {
      return [
        {
          text: "Year List",
          query: {},
          to: {
            name: "Year",
          },
        },
        {
          text: "Key Results Areas",
          query: this.query,
          to: {
            name: "kras",
          },
        },
        {
          text: "Key Performance Index",
          query: this.queryKra,
          to: {
            name: "kpis",
          },
          active: true,
        },
      ];
    },
  },
  // watch: {
  //   breadCrumbs() {
  //      this.setQueryParamsKpi()
  //   }
  // },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      deleteDisableDialog: false,
      color: "",
      text: "",
      queries: {},
      toggleSnackbar: false,
      addActive: false,
      editActive: false,
      rules: {
        required: (value) => !!value || "Required",
        length: (value) =>
          (value && value.length <= 250) || "Maximum 250 characters",
      },
      // showDialog: false,
      kpi_id: null,
      measurement_unit_items: ["Percent", "Metric"],
      calculation_process_items: ["Add", "Avg"],
      headers: [
        { text: "Key Performance Index", value: "title" },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Measurement Unit",
          value: "measurement_unit",
        },
        

        {
          text: "Measurement Unit Description",
          value: "measurement_desc",
        },
        {
          text: "Calculation Process",
          value: "calculation_process",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      kpiData: {
        performance_kra_id: null,
        title: null,
        description: null,
        measurement_unit: null,
        measurement_desc: null,
        calculation_process: null,
      },
      performance_kra_id: null,

      // Amal  starts

      // Amal ends

      // vuetify satrts
      // items: [
      //   {
      //     text: "Year List",
      //     // disabled: false,
      //     to: {
      //       name: "Year",
      //     },
      //   },
      //   {
      //     text: "Key Results Areas",
      //     disabled: false,
      //     to: {
      //       name: "Kras",
      //     },
      //   },
      //   {
      //     text: "Key Performance Index",
      //     disabled: true,
      //     to: {
      //       name: "kpis",
      //     },
      //   },
      // ],
      // vuetify ends
    };
  },
  mounted() {
    this.getKpis();
  },

  methods: {
    setQueryParamsKpi(data) {
      this.$store.commit("breadCrumb/SET_QUERY_PARAMS_KPI", data);
    },
    getKpis() {
      this.performance_kra_id = this.$route.query.id || this.query.id;
      this.$store.dispatch("getKpis", this.performance_kra_id);
    },

    addModal() {
      if (
        this.$route.query.status
          ? this.$route.query.status === "Active"
          : this.query.status === "Active"
      ) {
        this.deleteDisableDialog = true;
      } else {
        this.dialog = true;
        this.addActive = true;
      }
    },

    deleteModal(id) {
      if (
        this.$route.query.status === "Active" ||
        this.query.status === "Active"
      ) {
        this.deleteDisableDialog = true;
      } else {
        this.deleteDialog = true;
        this.deleteId = id;
      }
    },

    updateModal(item) {
      if (
        this.$route.query.status
          ? this.$route.query.status === "Active"
          : this.query.status === "Active"
      ) {
        this.deleteDisableDialog = true;
      } else {
        this.dialog = true;
        this.editActive = true;
        this.kpi_id = item.id;
        this.kpiData.title = item.title;
        this.kpiData.description = item.description;
        this.kpiData.measurement_unit = item.measurement_unit;
        this.kpiData.measurement_desc = item.measurement_desc;
        this.kpiData.calculation_process = item.calculation_process;
      }
    },

    closeModal() {
      this.dialog = this.addActive = this.editActive = false;
      this.kpiData.description = null;
      this.kpiData.title = null;
      this.kpiData.measurement_unit = null;
      this.kpiData.measurement_desc = null;
      this.kpiData.calculation_process = null;
      this.$refs.form.reset();
    },

    addKpi() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.addActive = false;
        this.kpiData.performance_kra_id = this.$route.query.id
          ? this.$route.query.id
          : this.query.id;
        const payload = { ...this.kpiData };
        this.$store
          .dispatch("addKpi", payload)
          .then(() => {
            if (this.kpiSuccessSnackbar) {
              this.toggleSnackbar = this.kpiSuccessSnackbar;
              this.color = "success";
              this.text = "New KPI successfully added";
              this.kpiData.description = null;
              this.kpiData.title = null;
              this.kpiData.measurement_unit = null;
              this.kpiData.measurement_desc = null;
              this.kpiData.calculation_process = null;
            }
          })
          .catch(() => {
            if (this.kpiErrorSnackbar) {
              this.toggleSnackbar = this.kpiErrorSnackbar;
              this.color = "error";
              this.text = "Error";
            }
          });
        this.$refs.form.reset();
      }
    },

    updateKpi() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.editActive = false;
        this.dialog = false;
        this.kpiData.performance_kra_id = this.$route.query.id
          ? this.$route.query.id
          : this.query.id;
        const payload = { id: this.kpi_id, formData: this.kpiData };
        this.$store
          .dispatch("updateKpi", payload)
          .then(() => {
            if (this.kpiSuccessSnackbar) {
              this.kpiData.description = null;
              this.kpiData.title = null;
              this.kpiData.measurement_unit = null;
              this.kpiData.measurement_desc = null;
              this.kpiData.calculation_process = null;
              this.toggleSnackbar = this.kpiSuccessSnackbar;
              this.color = "success";
              this.text = "KPI successfully edited";
              // this.$store.dispatch(
              //   "getKpis",
              //   this.query.id ? this.query.id : this.$route.query.id
              // );
            }
          })
          .catch(() => {
            if (this.kpiErrorSnackbar) {
              this.toggleSnackbar = this.kpiErrorSnackbar;
              this.color = "error";
              this.text = "Error";
            }
          });
        this.$refs.form.reset();
      }
    },

    deleteKpi(id) {
      this.deleteDialog = false;
      // CODE_REVIEW: Need to chain a then block to make sure operation is successful. Need to handle failure clause.
      this.$store
        .dispatch("deleteKpi", id)
        .then(() => {
          if (this.kpiSuccessSnackbar) {
            this.toggleSnackbar = this.kpiSuccessSnackbar;
            this.color = "success";
            this.text = "KPI successfully deleted";
          }
        })
        .catch(() => {
          // console.log("hai", this.kpiErrorSnackbar);
          if (this.kpiErrorSnackbar) {
            this.toggleSnackbar = this.kpiErrorSnackbar;
            this.color = "error";
            this.text = "Assign roles exist, so KPI can't be deleted";
          }
        });
    },
  },
};
</script>
<style scoped>
.v-data-table a {
  text-decoration: none;
}
</style>
