import axios from "@/axios";
import router from '@/router'

const state = {
  year_id: [],
  yearList: [],
  // CODE_REVIEW: Initialize this value with a Boolean since that is the type of value that is used
  yearSuccessSnackbar: false,
  yearErrorSnackbar: false,
  yearErrorMessage: []
};

const getters = {
  allYear: state => state.yearList,
  yearSuccessSnackbar: state => state.yearSuccessSnackbar,
  yearErrorSnackbar: state => state.yearErrorSnackbar,
  yearErrorMessage: state => state.yearErrorMessage,
};

const mutations = {
  STORE_YEAR(state, data) {
    state.yearList.push(data);
    state.yearSuccessSnackbar = true;
  },
  LOAD_YEARS(state, data) {
    state.yearList = data;
  },
  DELETE_YEAR(state, id) {
    state.yearList = state.yearList.filter(
      (yearList) => yearList.id !== id
    );
    state.yearSuccessSnackbar = true;
  },
};

const actions = {
  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async storeYear({ commit, state }, yearData) {
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    state.yearErrorMessage = []
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .post(
          "performance-year-store",
          yearData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // coverting first letter of a string in JSON response to caps starts
          const getResponse = response.data.results;
          // CODE_REVIEW: Extract the replacer function of JSON.stringify to separate function to avoid repetition
          let userObj = JSON.stringify(getResponse, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });
          var parsed = JSON.parse(userObj);
          // coverting first letter of a string in JSON response to caps ends
          commit("STORE_YEAR", parsed);
        })
        .catch((error) => {
          // CODE_REVIEW: The request failure status is not handled properly here.
          // Error handler here only just logs the error and will not pass error exception to the caller.
          // So the request always appears to succeed to the caller. Need to rethrow error if want it to be propagated to caller.
          console.log(error);
          state.yearErrorSnackbar = true;
          if(error.response.data.errors.date_from) {
          state.yearErrorMessage = error.response.data.errors.date_from[0];
          }
          else {
            state.yearErrorMessage = error.response.data.errors.date_to[0];
          }

          throw new Error(error)
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async loadYears({ commit, state }) {
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs.
    const token = localStorage.getItem("accessToken");
    state.yearList = []
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .get("performance-year-list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          const getAllYears = response.data.results;

          // coverting first letter of a string in JSON response to caps starts
          // CODE_REVIEW: Extract the replacer function of JSON.stringify to separate function to avoid repetition
          let userObj = JSON.stringify(getAllYears, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });
          var parsed = JSON.parse(userObj);
          // coverting first letter of a string in JSON response to caps ends

          commit("LOAD_YEARS", parsed);
        })
        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log(error);
        });
    } else {
      router.push({ path: "/login" });
    }
  },
  // CODE_REVIEW: Need to mark the actions as async if depoending upon the Promise returned at the end of function execution like here
  async deleteYear({ commit, state }, id) {
    // CODE_REVIEW: These getting token from localStorage can be put in an axios interceptor to reduce repetition and cleanup code.
    // Authorization header can be given here as "true" or "false" to enable adding auth token in the axios interceptor.
    // Choose the default adding token behaviour according to your needs
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: need to use the "return" keyword here to actually return the async request properly
      return axios
        .delete(
          "performance-year-delete/" + id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          console.log(result.data.id);
          commit("DELETE_YEAR", id);
        })

        .catch((error) => {
          // CODE_REVIEW: The request failure status is not handled properly here.
          // Error handler here only just logs the error and will not pass error exception to the caller.
          // So the request always appears to succeed to the caller. Need to rethrow error if want it to be propagated to caller.
          console.log(error.response.data.message);
          // state.yearErrorSnackbar = true;
          //if (error.response.data.message === "Cannot be deleted because kras exist") {
            state.yearErrorSnackbar = true;
         // }
          throw new Error(error)
        });
    } else {
      router.push({ path: "/login" });
    }
  },
};

export default { state, mutations, actions, getters };
