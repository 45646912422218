var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center d-flex align-items-center"},[_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"text-primary fw-bold btnrounded mr-3",attrs:{"elevation":"0","color":"#efefef","dark":"","width":"35","height":"35","fab":""}},[_c('img',{attrs:{"src":require("@/assets/images/noti.svg"),"alt":"","width":"12"}})]),_c('v-btn',_vm._g(_vm._b({staticClass:"text-primary fw-bold btnrounded",attrs:{"elevation":"0","color":"#efefef","dark":"","width":"35","height":"35","fab":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.user_name.slice(0, 1))+" ")])]}}])},[_c('v-list',{staticClass:"pt-1",attrs:{"height":"210","width":"200"}},[_c('v-list-item',[_c('v-row',{staticClass:"mt-0"},[_c('div',{staticClass:"dropdown-itemsnam mb-2 "},[_c('v-btn',{staticClass:"text-primary fw-bold btnrounded mb-2",attrs:{"elevation":"0","color":"#fff","dark":"","width":"35","height":"35","fab":""}},[_vm._v(" "+_vm._s(_vm.user_name.slice(0, 1))+" ")]),_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.user_name))])],1),_c('v-list-item-title',{staticClass:"px-0"},[_c('router-link',{attrs:{"to":{
                path: '/user-profile',
                query: { flag : true}
              }}},[_c('v-btn',{staticClass:"button overline",attrs:{"text":"","color":"primary"}},[_vm._v(" Profile ")])],1)],1),_c('v-list-item-title',{staticClass:"px-0"},[_c('router-link',{attrs:{"to":{
                path: '/check-user-email',
                query: { flag : true}
              }}},[_c('v-btn',{staticClass:"button overline",attrs:{"text":"","color":"primary"}},[_vm._v(" Change Password ")])],1)],1),_c('v-list-item-title',{staticClass:"px-0"},[_c('v-btn',{staticClass:" button overline",attrs:{"text":"","color":"primary"},on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }