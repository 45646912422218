<template>
  <div class="dasboard-area">
    <div class="mt-5 mb-2 dashboard-title flex-wrap">
      <h5>Dashboard</h5>
      <div class="button-boxes">
        <!-- <v-btn rounded small class="mb-1 select-date mr-3">
          <img src="@/assets/images/calendar-1.svg" alt="" />
          Select Date
        </v-btn> -->
        <div class="autocomplete-year">
          <v-autocomplete
            class="v-list-item__content"
            :items="year_list"
            filled
            auto-select-first
            :loading="loading"
            item-value="id"
            item-text="finacial_year"
            v-model="performance_year_id"
            label="Select Finacial Year"
            @change="getApis"
            dense
          ></v-autocomplete>
        </div>
        <v-btn rounded color="primary" small class="mt-2" @click="clicked">
          Balance Score Card
        </v-btn>
      </div>
    </div>
    <!-- <apexchart
      width="500"
      type="line"
      :options="options"
      :series="series"
    ></apexchart>
    <div>
      <apexchart
        width="500"
        type="bar"
        :options="options"
        :series="series"
      ></apexchart>
    </div> -->
    <v-row>
      <v-col md="8" class="leftside-dashboard">
        <div class="tab-dasboard" v-if="displayMyteams">
          <v-row>
            <v-col md="6" class="pt-0">
              <div
                class="tab-items"
                :class="{ active: isActiveQuarter }"
                @click="quarterPending"
              >
                <span class="count" v-if="countQuarter">{{
                  countQuarter
                }}</span>
                <span class="count" v-else> 0 </span>
                <h6>Quarter Review Pending</h6>
              </div>
            </v-col>
            <v-col md="6" class="pt-0">
              <div
                class="tab-items"
                :class="{ active: isActiveMonth }"
                @click="monthPending"
              >
                <span class="count" v-if="countMonth">{{ countMonth }}</span>
                <span class="count" v-else> 0 </span>
                <h6>Team’s Pending Completion</h6>
              </div>
            </v-col>
          </v-row>
          <QuarerReviewPending
            @pendingDetailsQuarter="pendingDetailsQuarter"
            v-if="$route.query.tab == 'quartertab' || !$route.query.tab"
          />
          <TeamsPendingCompletion
            @pendingDetailsMonth="pendingDetailsMonth"
            v-if="$route.query.tab == 'monthtab' || !$route.query.tab"
          />
          <!-- <h5 class="table-ttl">Pending List</h5>
          <div class="table-common">
            <table class="w-100">
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" scope="col" class="text-start">
                    <span>Employee Name</span>
                  </th>
                  <th role="columnheader" scope="col" class="text-start">
                    <span>Email Address</span>
                  </th>
                  <th role="columnheader" scope="col" class="text-start">
                    <span>Quarter</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="">
                  <td class="text-start">Anju P Lekshmi</td>
                  <td class="text-start">anju@cubettech.com</td>
                  <td class="text-start">Quarter 1</td>
                </tr>
                <tr class="">
                  <td class="text-start">Lekshmi</td>
                  <td class="text-start">lekshmi@cubettech.com</td>
                  <td class="text-start">Quarter 1</td>
                </tr>
                <tr class="">
                  <td class="text-start">Anju P Lekshmi</td>
                  <td class="text-start">anju@cubettech.com</td>
                  <td class="text-start">Quarter 1</td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </div>

        <div class="graph-section">
          <div class="graph-boxes">
            <div class="graph-ttl">
              <h6>KPI Expected VS Monthly Progress</h6>
            </div>
            <div class="grapharea">
              <!-- sample search copy from other page., please use the correct one - started  -->

              <!-- <div md="4" class="table-search mb-5 mt-4">
                <div
                  class="v-input pt-0 v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder"
                >
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <fieldset aria-hidden="true">
                        <legend style="width: 0px">
                          <span class="notranslate">&ZeroWidthSpace;</span>
                        </legend>
                      </fieldset>
                      <div class="v-text-field__slot">
                        <input
                          id="input-918"
                          placeholder="Search KPI"
                          type="text"
                        />
                      </div>
                      <div class="v-input__append-inner">
                        <div class="v-input__icon v-input__icon--append">
                          <i
                            aria-hidden="true"
                            class="v-icon notranslate mdi mdi-magnify theme--light"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- sample search copy from other page., please use the correct one - ended -->

              <v-col style="text-align: left" class="d-flex" cols="12" sm="6">
                <!-- <v-autocomplete
                    :items="kraResult"
                    item-value="id"
                    item-text="performance_kpi.title"
                    v-model="kpiTitle"
                    label="Select kpi"
                    dense
                  ></v-autocomplete> -->
                <div class="autocomplete">
                  <v-autocomplete
                    class="v-list-item__content"
                    :items="kraResult"
                    auto-select-first
                    :loading="loading"
                    item-value="performance_kpi_id"
                    item-text="kpi_name"
                    v-model="kpiTitle"
                    label="Select KPI"
                    dense
                    @input="KpiMonthlyProgress()"
                  ></v-autocomplete>
                </div>
              </v-col>

              <!-- <v-select
                :items="kraResultQuarter"
                item-value="id"
                item-text="KPI"
                v-model="performance_kpi_id"
                label="Select KPI"
                hide-details
              ></v-select> -->

              <!-- <img src="@/assets/images/graph-1.png" alt="" /> -->
              <div id="chart">
                <apexchart
                  ref="apex"
                  type="line"
                  height="350"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>

        <div class="graph-section">
          <div class="graph-boxes">
            <div class="graph-ttl">
              <h6>KPI Entered per Quarter/Month</h6>
            </div>
            <div class="grapharea">
              <!-- sample search copy from other page., please use the correct one - started  -->
              <!-- <div md="4" class="table-search mb-5 mt-4">
                <div
                  class="v-input pt-0 v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder"
                >
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <fieldset aria-hidden="true">
                        <legend style="width: 0px">
                          <span class="notranslate">&ZeroWidthSpace;</span>
                        </legend>
                      </fieldset>
                      <div class="v-text-field__slot">
                        <input
                          id="input-918"
                          placeholder="Search KPI"
                          type="text"
                        />
                      </div>
                      <div class="v-input__append-inner">
                        <div class="v-input__icon v-input__icon--append">
                          <i
                            aria-hidden="true"
                            class="v-icon notranslate mdi mdi-magnify theme--light"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <v-col class="d-flex" cols="12" sm="6">
                <div class="autocomplete">
                  <v-autocomplete
                    :items="kraResultQuarter"
                    item-value="performance_kpi_id"
                    item-text="kpi_name"
                    v-model="kraQuarterTitle"
                    label="Select KPI"
                    dense
                    hide-details
                    @change="KpiQuarterlyProgress"
                  ></v-autocomplete>
                </div>
              </v-col>

              <!-- sample search copy from other page., please use the correct one - ended -->
              <!-- <img src="@/assets/images/graph-2.jpg" alt="" /> -->
              <apexchart
                width="800"
                type="bar"
                :options="chartOptionsBar"
                :series="seriesBar"
              ></apexchart>
            </div>
          </div>
        </div>

        <BalanceScoreCardTable
          v-if="enabled"
          :userId="user_id"
          @yearReviewResults="yearReviewList"
        />
        <!-- <h5 class="table-ttl">Balance Score Card</h5>
        <div class="table-common">
          <table class="w-100">
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" scope="col" class="text-start">
                  <span>Objective/Task</span>
                </th>
                <th role="columnheader" scope="col" class="text-start">
                  <span>Weight</span>
                </th>
                <th role="columnheader" scope="col" class="text-start">
                  <span>Expectation</span>
                </th>
                <th role="columnheader" scope="col" class="text-start">
                  <span>Achievement</span>
                </th>
                <th role="columnheader" scope="col" class="text-start">
                  <span>Score</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="">
                <td class="text-start">Business Revenue</td>
                <td class="text-start">10%</td>
                <td class="text-start">250000</td>
                <td class="text-start">-</td>
                <td class="text-start">0.00</td>
              </tr>
              <tr class="">
                <td class="text-start">
                  Productivity of junior Resources post training
                </td>
                <td class="text-start">10%</td>
                <td class="text-start">160</td>
                <td class="text-start">-</td>
                <td class="text-start">0.00</td>
              </tr>
              <tr class="">
                <td class="text-start">Customer Satisfaction</td>
                <td class="text-start">5%</td>
                <td class="text-start">9.50</td>
                <td class="text-start">-</td>
                <td class="text-start">0.00</td>
              </tr>
              <tr class="">
                <td class="text-start">Acceptance of code by Customers</td>
                <td class="text-start">10%</td>
                <td class="text-start">100</td>
                <td class="text-start">-</td>
                <td class="text-start">0.00</td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </v-col>
      <v-col md="4" class="rightside-dashboard">
        <div v-if="yearResults.length">
          <PerformanceScore :totalScore="yearResults" />
        </div>
        <!-- <div class="performance-scrore">
          <h6>Performance Score?</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod
          </p>
          <img src="@/assets/images/progress-img.png" alt="" class="mt-3" />
        </div> -->

        <div class="monthly-review">
          <h5 class="table-ttl mt-1 mb-4">Monthly Review Status</h5>
          <!-- <p>{{monthsList}}</p> -->
          <div class="table-common" v-if="monthsList.length > 0">
            <table class="w-100 table-striped">
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" scope="col" class="text-start">
                    <span>Month</span>
                  </th>
                  <th role="columnheader" scope="col" class="text-start">
                    <span>Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="(item, index) in monthsList" :key="index">
                  <td class="text-start">
                    {{
                      new Date(item.date).toLocaleDateString("en-us", {
                        year: "numeric",
                        month: "long",
                      })
                    }}
                  </td>
                  <td class="text-start">
                    <div class="status-btn">
                      <span
                        class="completed"
                        @click="moveToMonth(item)"
                        v-if="item.status === 'Completed'"
                        >{{ item.status }}</span
                      >
                      <span
                        class="inprogress"
                        @click="moveToMonth(item)"
                        v-if="item.status === 'InProgress'"
                        >{{ item.status }}</span
                      >
                      <span
                        class="active"
                        @click="moveToMonth(item)"
                        v-if="item.status === 'Active'"
                        >{{ item.status }}</span
                      >
                    </div>
                  </td>
                </tr>
                <!-- <tr class="">
                  <td class="text-start">February</td>
                  <td class="text-start">
                    <div class="status-btn">
                      <span class="pending">Pending</span>
                    </div>
                  </td>
                </tr>
                <tr class="">
                  <td class="text-start">March</td>
                  <td class="text-start">
                    <div class="status-btn">
                      <span class="completed">Completed</span>
                    </div>
                  </td>
                </tr>
                <tr class="">
                  <td class="text-start">April</td>
                  <td class="text-start">
                    <div class="status-btn">
                      <span class="pending">Pending</span>
                    </div>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <div v-else>
            <p class="text-left">
              Month Cards are not generated for this year.
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import QuarerReviewPending from "../views/dashboard/QuarerReviewPending.vue";
import BalanceScoreCardTable from "../views/dashboard/BalanceScoreCardTable.vue";
import PerformanceScore from "../views/dashboard/PerformanceScore.vue";
import TeamsPendingCompletion from "../views/dashboard/TeamsPendingCompletion.vue";
// import VueApexCharts from 'vue-apexcharts';
import axios from "@/axios";

export default {
  components: {
    QuarerReviewPending,
    BalanceScoreCardTable,
    PerformanceScore,
    TeamsPendingCompletion,
  },
  data() {
    return {
      kpiList: {
        performance_year_id: null,
      },
      year_list: null,
      value: null,
      activeId: null,
      performance_year_id: null,
      resultKra: "",
      kraResult: [],
      defaultKra: null,
      defaultQuarter: null,
      isActiveMonth: false,
      isActiveQuarter: true,
      yearResults: "",
      kraQuarterTitle: "",
      enabled: false,
      monthArray: [],
      countQuarter: "",
      kpiTitle: "",
      loading: false,
      yearData: {
        role_id: "",
        user_id: "",
        performance_kpi_id: "",
        performance_year_id: null,
      },
      yearDataQuarter: {
        role_id: "",
        user_id: "",
        performance_kpi_id: "",
        performance_year_id: "",
      },
      kraResultQuarter: [],
      countMonth: "",
      series: [
        {
          name: "KPI Expected",
          data: [],
        },
        {
          name: "Monthly Progress",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          // dropShadow: {
          //   enabled: true,
          //   color: "#000",
          //   top: 18,
          //   left: 7,
          //   blur: 10,
          //   opacity: 0.2,
          // },
          // toolbar: {
          //   show: false,
          // },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true,
        },
        // stroke: {
        //   curve: "smooth",
        // },
        title: {
          text: "KPI Expected Vs Monthly progress",
          align: "left",
        },
        // grid: {
        //   borderColor: "#e7e7e7",
        //   row: {
        //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns

        //   },
        // },

        // markers: {
        //   size: 1,
        // },
        xaxis: {
          convertedCatToNumeric: false,
          tickPlacement: "between",
          type: "category",

          categories: [],
          //categories: ["april","may","june","july","august","september","october","november","december","january","february","march"],

          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "KPI",
          },
          min: 10,
          max: 200,
        },
        // legend: {
        //   position: "top",
        //   horizontalAlign: "right",
        //   floating: true,
        //   offsetY: -25,
        //   offsetX: -2.5,
        // },
      },

      seriesBar: [
        {
          name: "KPI Expected",
          data: [],
        },
        {
          name: "Monthly Progress",
          data: [],
        },
      ],
      options: [
        {
          value: "",
          label: "",
        },
      ],
      chartOptionsBar: {
        chart: {
          height: 350,
          type: "line",
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            },
          },
          // dropShadow: {
          //   enabled: true,
          //   color: "#000",
          //   top: 18,
          //   left: 7,
          //   blur: 10,
          //   opacity: 0.2,
          // },
          // toolbar: {
          //   show: false,
          // },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true,
        },
        // stroke: {
        //   curve: "smooth",
        // },
        title: {
          text: "KPI Expected Vs Monthly progress",
          align: "left",
        },
        // grid: {
        //   borderColor: "#e7e7e7",
        //   row: {
        //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        //     opacity: 0.5,
        //   },
        // },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
          title: {
            text: "Quarter",
          },
        },
        yaxis: {
          title: {
            text: "KPI",
          },
          min: 0,
          max: 200,
        },

        // legend: {
        //   position: "top",
        //   horizontalAlign: "right",
        //   floating: true,
        //   offsetY: -25,
        //   offsetX: -2.5,
        // },
      },
    };
  },
  computed: {
    ...mapGetters([
      "monthsList",
      "getPermissions",
      "kraKpiSelfReviewList",
      "allYear",
    ]),
    displayMyteams() {
      return this.getPermissions.find(
        (item) => item.permission_name === "team_members_list"
      );
    },

    //  expectation() {
    //   return this.kraResult.find(
    //     (item) => item.expectation
    //   );
    // },
  },
  watch: {
    // months() {
    //   return this.monthsList.forEach((item) => {
    //     this.monthArray.push(item.month);
    //     this.chartOptions.xaxis.categories[0].push(this.monthArray);
    //   });
    // },
  },
  created() {
    this.user_id = localStorage.getItem("user_id");
    this.defaultKra = this.kraResult[0];
    this.$store.dispatch("loadYears");
    for (let item of this.allYear) {
      if (item.status === "active") {
        // console.log("enterred2.....")

        this.activeId = item.id;
      }
    }

    // this.categories = [this.months.month]
    // console.log("tgis.months.month", this.monthArray);
  },
  mounted() {
    this.demo();
    this.getMonths();
    this.KpiMonthlyProgress();
    this.KpiQuarterlyProgress();
    this.getYears();
  },
  methods: {
    //  getKraKpisSelfReviews() {
    //   this.loading = true;
    //   this.formData.user_id = this.user_id;
    //   this.formData.performance_self_review_id = 3368;
    //   const payload = { ...this.formData };
    //   this.$store.dispatch("getKraKpisSelfReviews", payload);
    //   this.loading = false;
    // },
    quarterPending() {
      this.isActiveQuarter = true;
      this.isActiveMonth = false;
      this.$router.push({
        name: "",
        query: {
          tab: "quartertab",
        },
      });
    },
    monthPending() {
      this.isActiveMonth = true;
      this.isActiveQuarter = false;
      this.$router.push({
        name: "",
        query: {
          tab: "monthtab",
        },
      });
    },
    clicked() {
      this.enabled = true;
    },
    yearReviewList(results) {
      this.yearResults = results;
      this.yearlyReviewKpi = results.yearly_review;
    },
    pendingDetailsMonth(resultMonth) {
      this.countMonth = resultMonth.length;
    },
    pendingDetailsQuarter(resultQuarter) {
      this.countQuarter = resultQuarter.length;
    },

    getMonths() {
      const payload = {
        user_id: this.user_id,
        performance_year_id: this.performance_year_id,
      };
      this.$store.dispatch("getMonths", payload);
    },
    getApis() {
      this.getMonths();
      this.KpiQuarterlyProgress();
      this.KpiMonthlyProgress();
      this.demo();
    },

    // pendingCompletion() {

    //   // console.log("MyTeamDashboard");
    //   this.$router.push({
    //     name: "Quarter review pending",
    //     query: {
    //       tab: "tab",
    //     },
    //   });
    // },
    async demo() {
      // CODE_REVIEW: Maybe move this to store. Adopt a consistent method moving all the API calls to store.
      const token = localStorage.getItem("accessToken");
      this.kpiList.performance_year_id = this.performance_year_id;
      if (token) {
        return axios
          .post(
            "kra-kpi-list",
            this.kpiList,

            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            // console.log(
            //   "neww",
            //   response.data.results.year_kras_kpis_initiatives.performance_kras
            // );

            this.resultKra =
              response.data.results.year_kras_kpis_initiatives.performance_kras;
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },

    async KpiMonthlyProgress() {
      // CODE_REVIEW: Maybe move this to store. Adopt a consistent method moving all the API calls to store.
      const token = localStorage.getItem("accessToken");
      this.yearData.role_id = localStorage.getItem("role_id");
      this.yearData.user_id = localStorage.getItem("user_id");
      this.yearData.performance_kpi_id = this.kpiTitle;
      this.yearData.performance_year_id = this.performance_year_id;

      if (token) {
        return axios
          .post(
            "selfRoleKpi",
            this.yearData,

            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.resizeWindow();

            this.kraResult = response.data.results.performance_role_kpi.sort(
              function (a, b) {
                if (a.kpi_name < b.kpi_name) {
                  return -1;
                }
                if (a.kpi_name > b.kpi_name) {
                  return 1;
                }
                return 0;
              }
            );
            this.defaultKra = response.data.results.performance_role_kpi[0];
            if (this.kpiTitle === "") {
              this.kpiTitle = this.defaultKra.performance_kpi_id;
              this.KpiMonthlyProgress();
            }
            this.series[0].data = [];
            // console.log("resonse", response.data.results.performance_role_kpi);
            //  response.data.results.self_review_kpi.forEach((item) => {
            //  this.chartOptions.xaxis.categories.push(item.month)
            //   })

            // response.data.results.performance_role_kpi.forEach((item) => {

            // if(this.yearData.performance_kpi_id === item.performance_kpi_id) {
            //    this.series[0].data.push(item.expectation)

            //     console.log("expectation##",this.series[0].data)

            // }

            //  });
            this.series[1].data = [];
            response.data.results.self_review_kpi.forEach((item) => {
              if (
                this.yearData.performance_kpi_id === item.performance_kpi_id &&
                item.metric
              ) {
                this.series[1].data.push(item.metric);

                response.data.results.performance_role_kpi.forEach((item) => {
                  if (
                    this.yearData.performance_kpi_id === item.performance_kpi_id
                  ) {
                    this.series[0].data.push(item.expectation);

                    // console.log("expectation##", this.series[0].data);
                  }
                });

                // this.$refs.apex.chart.w.config.xaxis.convertedCatToNumeric = false
                // console.log("this.$refs.apex.chart.w.config.xaxis", item.month);
                //  if(item.month === "apr")
                //    this.chartOptions.xaxis.categories.push("apr")
                //    else if(item.month === "may")
                //    this.chartOptions.xaxis.categories.push("may")
                //    else if(item.month === "jun")
                //    this.chartOptions.xaxis.categories.push("jun")
                //    else if(item.month === "Jul")
                //    this.chartOptions.xaxis.categories.push("Jul")
                //    else if(item.month === "Aug")
                //    this.chartOptions.xaxis.categories.push("aug")
                //    else if(item.month === "sep")
                //    this.chartOptions.xaxis.categories.push("sept")
                //    else if(item.month === "oct")
                //    this.chartOptions.xaxis.categories.push("oct")
                //    else if(item.month === "nov")
                //    this.chartOptions.xaxis.categories.push("nov")
                //    else if(item.month === "dec")
                //    this.chartOptions.xaxis.categories.push("dec")
                //    else if(item.month === "jan")
                //    this.chartOptions.xaxis.categories.push("jan")
                //    else if(item.month === "feb")
                //    this.chartOptions.xaxis.categories.push("feb")
                //  else

                //item.month.concat(this.chartOptions.xaxis.categories)

                //this.chartOptions.xaxis.categories.shift()

                // this.chartOptions.xaxis.categories.unshift(item.month)
                // this.chartOptions.xaxis.categories.splice(0,0,item.month)
                // this.chartOptions.xaxis.categories.reverse()

                // console.log(
                //   "this.series[1].data.push",
                //   this.chartOptions.xaxis.categories
                // );
              }
            });
            var temp = [0, 0];
             if (this.series[1].data.length === 0) {
                this.series[1].data = temp;
              }

            response.data.results.self_review_kpi.forEach((item) => {
              //this.monthArray.push(item.month)
              // this.chartOptions.xaxis.categories[0].shift()
             
              this.chartOptions.xaxis.categories.push(item.month);

              // console.log(
              //   "this.series[1].data.push#####",
              //   this.chartOptions.xaxis.categories
              // );
              //             this.chartOptions.xaxis.categories.push(item.month)
              //             this.chartOptions.xaxis.categories = this.chartOptions.xaxis.categories.filter(element => {
              //   if(element !== null){
              //     return true;
              //   }else{
              //     return false;
              //   }
              // });
            });
            // this.chartOptions.xaxis.categories.splice(1,1)
            // console.log("this.series[1].data.push## after splice",this.chartOptions.xaxis.categories)
            // this.chartOptions.xaxis.categories.splice(this.chartOptions.xaxis.categories);
            //this.chartOptions.xaxis.categories.remove()
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },

    async KpiQuarterlyProgress() {
      // CODE_REVIEW: Maybe move this to store. Adopt a consistent method moving all the API calls to store.
      const token = localStorage.getItem("accessToken");
      this.yearDataQuarter.role_id = localStorage.getItem("role_id");
      this.yearDataQuarter.user_id = localStorage.getItem("user_id");
      this.yearDataQuarter.performance_kpi_id = this.kraQuarterTitle;
      this.yearDataQuarter.performance_year_id = this.performance_year_id;
      this.loading = true;

      if (token) {
        return axios
          .post(
            "quarterlyRoleKpi",
            this.yearDataQuarter,

            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.resizeWindow();
            // window.resizeTo()
            this.kraResultQuarter =
              response.data.results.performance_role_kpi.sort(function (a, b) {
                if (a.kpi_name < b.kpi_name) {
                  return -1;
                }
                if (a.kpi_name > b.kpi_name) {
                  return 1;
                }
                return 0;
              });
            this.defaultQuarter = response.data.results.performance_role_kpi[0];

            if (this.kraQuarterTitle === "") {
              this.kraQuarterTitle =
                response.data.results.performance_role_kpi[0].performance_kpi_id;
              this.KpiQuarterlyProgress();
            }
            this.loading = false;

            // response.data.results.performance_role_kpi.forEach((item) => {
            //   this.seriesBar[0].data.push(item.expectation);
            //   console.log("hi", this.seriesBar[0]);
            // });
            // response.data.results.quarterly_review_kpi[0].quarterly_review_kpis.forEach(
            //   (item) => {
            //     console.log("z", item);
            //     this.seriesBar[1].data.push(item.metric);
            //     console.log("hello", this.seriesBar[1]);
            //   }
            // );
            this.seriesBar[0].data = [];
            this.seriesBar[1].data = [];
            response.data.results.quarterly_review_kpi.forEach((item) => {
              if (
                this.yearDataQuarter.performance_kpi_id ===
                  item.performance_kpi_id &&
                item.aggregated_metric
              ) {
                this.seriesBar[1].data.push(item.aggregated_metric);
                // console.log("ghj", this.seriesBar[1]);

                response.data.results.performance_role_kpi.forEach((item) => {
                  if (
                    this.yearDataQuarter.performance_kpi_id ===
                    item.performance_kpi_id
                  ) {
                    this.seriesBar[0].data.push(item.expectation);

                    // console.log("expectation##", this.seriesBar[0].data);
                  }
                });

                // console.log(
                //   "this.seriesBar[1].data.push",
                //   this.chartOptionsBar.xaxis.categories
                // );
              }
            });
            var temp = [0, 0];
             if (this.seriesBar[1].data.length === 0) {
                this.seriesBar[1].data = temp;
              }

            response.data.results.quarterly_review_kpi.forEach((item) => {
              this.chartOptionsBar.xaxis.categories.push(item.quarter);
              // console.log(
              //   "this.seriesBar[1].data.push#####",
              //   this.chartOptionsBar.xaxis.categories
              // );
              // console.log("qwe", item.quarter);
            });
          });
      } else {
        this.loading = false;

        this.$router.push({ path: "/login" });
      }
    },
    getYears() {
      const token = localStorage.getItem("accessToken");
      if (token) {
        return axios
          .get(
            "performance-year-list",

            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            // console.log(
            //   "neww",
            //   response.data.results.year_kras_kpis_initiatives.performance_kras
            // );

            this.year_list = response.data.results;
            for (let item of this.year_list) {
              var temp1 = "";
              var temp2 = "";
              var dateFrom = item.date_from.split("");
              for (let i = 0; i < 5; i++) {
                temp1 += dateFrom[i];
              }
              var dateTo = item.date_to.split("");
              for (let p = 0; p < 4; p++) {
                temp2 += dateTo[p];
              }
              item.finacial_year = temp1 + "" + temp2;
            }
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    changeGraph() {
      this.KpiQuarterlyProgress().then(() => {});
    },
    resizeWindow() {
      window.dispatchEvent(new Event("resize"));
    },
    moveToMonth(month) {
      this.$router.push({
        path: "/performance/reviews/monthly/monthly-reviews",
        query: { id: month.id, status: month.status, month: month.month },
      });
    },
  },
};
</script>
<style>
.left {
  text-align: left !important;
}
.autocomplete-year {
  margin-right: 2%;
}
.autocomplete {
  width: 300px;
  text-align: left;
}
.autocomplete .v-list-item__content {
  text-align: left;
}
.v-list-item__content {
  text-align: left;
}
.status-btn {
  cursor: pointer;
}
</style>
