import axios from "@/axios";
import router from '@/router'

const state = {
  kra_id: [],
  kraList: [],
  // CODE_REVIEW: Initialize this value with a Boolean since that is the type of value that is used
  kraSuccessSnackbar: false,
  kraErrorSnackbar: false, 
  kraId: ''
};
const getters = {
  allKra: (state) => state.kraList,
  kraSuccessSnackbar: state => state.kraSuccessSnackbar,
  kraErrorSnackbar: state => state.kraErrorSnackbar,
  performanceKraId: state => state.kraId
};

const mutations = {
  STORE_KRA(state, data) {
    state.kraList.push(data);
    state.kraSuccessSnackbar = true;
  },
  GET_KRAS(state, data) {
    state.kraList = data;
  },
  GET_KRA_ID(state, data) {
    state.kraId = data;

  },
  DELETE_KRA(state, id) {
    state.kraList = state.kraList.filter(
      (kraList) => kraList.id !== id
    );
    state.kraSuccessSnackbar = true;
  },
  UPDATE_KRA(state, data) {
    const index = state.kraList.findIndex(kraList => kraList.id === data.id);
    if (index !== -1) {
      state.kraList.splice(index, 1, data);
    }
    state.kraSuccessSnackbar = true;
  }
};
const actions = {
  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async addKra({ commit, state }, kraData) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .post(
          "performancekra-create",
          kraData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.results);
          // coverting first letter of a string in JSON response to caps starts
          const getResponse = response.data.results;
          // CODE_REVIEW: Extract the replacer function of JSON.stringify to separate function to avoid repetition
          let userObj = JSON.stringify(getResponse, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });
          var parsed = JSON.parse(userObj);
          // coverting first letter of a string in JSON response to caps ends
          commit("STORE_KRA", parsed);
        })
        .catch((error) => {
          // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 71
          console.log(error);
          state.kraErrorSnackbar = true;
          throw new Error(error)
        });
    } else {
      router.push({ path: "/login" });
    }
  },

  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async getKras({ commit, state }, data) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    state.kraList = []
    commit('SET_DATA', { data: true, id: 'isLoading' }, { root: true })
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .post(
          "performancekra-list",
          { performance_year_id: data },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          // coverting first letter of a string in JSON response to caps starts
          const getAllKras = response.data.results;
          console.log("from storeeeeeee",getAllKras[0].id)
          state.kraId = getAllKras[0].id

          // CODE_REVIEW: Extract the replacer function of JSON.stringify to separate function to avoid repetition
          let userObj = JSON.stringify(getAllKras, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });

          var parsed = JSON.parse(userObj);

          // coverting first letter of a string in JSON response to caps ends

          commit("GET_KRAS", parsed);
          commit("GET_KRA_ID", state.kraId)
        })
        .catch((error) => {
          commit('SET_DATA', { data: false, id: 'isLoading' }, { root: true })
          console.log(error.response.data);

        });
    } else {
      router.push({ path: "/login" });
    }
  },

  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async updateKra({ commit, state }, payload) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .post(
          "performancekra-update/" +
          payload.id,
          payload.formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // coverting first letter of a string in JSON response to caps starts
          const getResponse = response.data.results;
          // CODE_REVIEW: Extract the replacer function of JSON.stringify to separate function to avoid repetition
          let userObj = JSON.stringify(getResponse, (key, value) => {
            if (typeof value === "string") {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return value;
          });
          var parsed = JSON.parse(userObj);
          // coverting first letter of a string in JSON response to caps ends
          commit("UPDATE_KRA", parsed);
        })
        .catch((error) => {
          // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 71
          console.log(error);
          state.kraErrorSnackbar = true;
          throw new Error(error)
        });
    } else {
      router.push({ path: "/login" });
    }
  },
  // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 38
  async deleteKra({ commit, state }, id) {
    // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 40
    const token = localStorage.getItem("accessToken");
    if (token) {
      // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 45
      return axios
        .delete(
          "performancekra-delete/" + id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          // console.log();
          commit("DELETE_KRA", id);
        })
        .catch((error) => {
          // CODE_REVIEW: Refer to src/store/modules/admin-kpi-roles.js	lines - 71
          console.log(error.response.data.message);
          if (error.response.data.message === " cannot deleted because kpis exist") {
            state.kraErrorSnackbar = true;
          }
          throw new Error(error)

        });
    } else {
      router.push({ path: "/login" });
    }
  },
};
export default { state, mutations, actions, getters };
