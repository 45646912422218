<template>
  <div>
    <h3 class="text-center font-weight-bold subtitle-1">Quarter - Self reviews KPI</h3>
    <v-card class="reviewd-details" elevation="0">
        <h6 class="mb-2"> <span>Reviewed by : </span> {{ name }} </h6>
        <h6 class="mb-2">
          <span>Current status : </span><v-btn x-small class="ml-1" :color="this.color">
            {{ reviewStatus }}
          </v-btn></h6>
       <h6 class="mb-2"> <span>RM Mail : </span> {{ mail }} </h6>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    rmName: {
      required: true,
    },
    rmMail: {
      required: true,
    },
  },
  data() {
    return {
      name: null,
      mail: null,
      reviewStatus: null,
      color: null,
    };
  },

  mounted() {
    this.name = this.rmName || this.$route.query.rm_name;
    this.mail = this.rmMail || this.$route.query.rm_mail;
    this.reviewStatus = this.$route.query.status;
    if (this.$route.query.status === "active") {
      this.color = "primary";
    } else if (this.$route.query.status === "inprogress") {
      this.color = "error";
    } else if (this.$route.query.status === "completed") {
      this.color = "success";
    }
  },
};
</script>


