import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import Loading from 'vue-loading-overlay';
import router from './router'
import toast from "vue-toastification";
import {store} from "./store/store";
import '@/plugins/apexcharts';
import yearPicker from 'vue-year-picker';
// CODE_REVIEW: Try to follow this order when arranging you top imports: third party modules at the top and the custm imports at the bottom.
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'




// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "@/assets/scss/styles.scss"

// Make BootstrapVue available throughout your project
// CODE_REVIEW: Why use Bootstrap just for breadcrumbs and not vuetify? If you must use, don't use the whole component library as you are doing it here.
// Here, you are just bundling the entire components without tree shaking. Import the needed breadcrumb component in the vue component and use it
// to enable tree shaking unneeded components. Importing locally may require to import the bootstrap css file here like above.
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// CODE_REVIEW: No need to bundle Bootstrap icon plugin if you are not using it.
Vue.use(IconsPlugin)
Vue.use(yearPicker);



Vue.config.productionTip = false
// CODE_REVIEW: Don't need the line below. moment is not a vue plugin to use it like this..
Vue.use(moment)

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})


new Vue({
  vuetify,
  // CODE_REVIEW: Don't need the line below. moment is not a vue plugin to use it like this..
  moment,
  router,
  Loading,
  toast,
  yearPicker,
  // CODE_REVIEW: The shorthand "store" like above properties is enough here as it uses the same name as "store"
  store: store,
  render: (h) => h(App),
}).$mount("#app");
