<template>
  <v-container>
    <v-row v-if="!displayAllMembers">
      <!-- <p v-show="page == 0">{{ allMembers }}</p> -->
     
      <v-col lg="3" md="12">
        <v-card class="pb-2 h-100 team-members" elevation="0">
          <v-toolbar dark color="grey lighten-4 black--text" elevation="0">
            <v-toolbar-title class="body-2 text-left font-weight-regular"
              >Team Members</v-toolbar-title
            >
          </v-toolbar>
          <v-list rounded class="body-2 font-weight-regular">
            <v-list-item-group v-model="userTab" color="primary">
              <v-list-item
                v-for="(item, index) in myTeam"
                :key="index"
                class="active px-1"
                :value="`${item.id}`"
                @click="getIds(item)"
              >
                <!--asif code class="myteam-items pb-2 active" -->
                <!-- {{ item.id }} -->
                <div class="align-items-center team-members-row">
                  <div md="2" class="text-left py-1">
                    <v-list-item-avatar size="33">
                      <v-img :src="item.profile_photo_url"></v-img>
                    </v-list-item-avatar>
                  </div>
                  <div class="text-left py-1">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="item.name"
                        class="caption"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        class="caption"
                        v-html="item.email"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </div>
                  <!-- {{ item.role_id }}-{{ item.id }} -->

                  <!-- <v-col class="text-right py-1">
                    <v-icon small class="mr-2" @click="getIds(item)"
                      >mdi-dots-horizontal</v-icon
                    >
                  </v-col> -->
                </div>
              </v-list-item>
            
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col lg="9" md="12">
        <!-- {{ userName }} KRA KPI Details -->
        <div class="tab-custom">
          <v-tabs
            height="40"
            class="mx-0 customtab-head"
            slider-color="transparent"
            flat
            v-model="tab"
          >
            <v-tab
              class="caption titles custom-transform-class text-none"
              @click="dashboardTab"
            >
              Dashboard
            </v-tab>
            <v-tab
              class="caption titles custom-transform-class text-none"
              @click="kraKpiTab"
            >
              KRA-KPI
            </v-tab>
            <v-tab
              class="caption titles custom-transform-class text-none"
              @click="reviewTab"
            >
              Reviews
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="admin-team-tab">
            <v-tab-item>
              <my-team-dashboard
                ref="team_dashboard"
                :userId="selectedUserId"
                :roleId="selectedRoleId"
              ></my-team-dashboard>
            </v-tab-item>
            <v-tab-item
              ><kra-kpi-tab
                ref="kra_kpi"
                :userId="selectedUserId"
                :roleId="selectedRoleId"
              ></kra-kpi-tab>
            </v-tab-item>
            <v-tab-item
              ><month-tabs
                ref="month_tab"
                :userId="selectedUserId"
                :roleId="selectedRoleId"
              ></month-tabs>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="displayAllMembers">
      <!-- <p>{{allMembers}}</p> -->
      <p v-show="page == 0">{{ allMembers }}</p>
      <v-col lg="3" md="12">
        <v-card class="pb-2 h-100 team-members" elevation="0">
          <v-toolbar dark color="grey lighten-4 black--text" elevation="0">
            <v-toolbar-title class="body-2 text-left font-weight-regular"
              >Team Members</v-toolbar-title
            >
          </v-toolbar>
          <!-- <p>{{ visiblePages }}</p> -->
          <v-list rounded class="body-2 font-weight-regular">
            <v-list-item-group v-model="userTab" color="primary">
              <v-list-item
                v-for="(item, index) in allMembers"
                :key="index"
                class="active px-1"
                :value="`${item.id}`"
                @click="getIds(item)"
              >
                <!--asif code class="myteam-items pb-2 active" -->
                <!-- {{ item.id }} -->
                <div class="align-items-center team-members-row">
                  <div md="2" class="text-left py-1">
                    <v-list-item-avatar size="33">
                      <v-img :src="item.profile_photo_url"></v-img>
                    </v-list-item-avatar>
                  </div>
                  <div class="text-left py-1">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="item.name"
                        class="caption"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        class="caption"
                        v-html="item.email"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </div>
                  <!-- {{ item.role_id }}-{{ item.id }} -->

                  <!-- <v-col class="text-right py-1">
                    <v-icon small class="mr-2" @click="getIds(item)"
                      >mdi-dots-horizontal</v-icon
                    >
                  </v-col> -->
                </div>
              </v-list-item>
              <v-pagination
                v-model="page"
                :length="totalPage"
                @input="onPageChange"
              ></v-pagination>
            </v-list-item-group>
            <div class="text-left py-1"></div>
          </v-list>
        </v-card>
      </v-col>
      <v-col lg="9" md="12">
        <!-- {{ userName }} KRA KPI Details -->
        <div class="tab-custom">
          <v-tabs
            height="40"
            class="mx-0 customtab-head"
            slider-color="transparent"
            flat
            v-model="tab"
          >
            <v-tab
              class="caption titles custom-transform-class text-none"
              @click="dashboardTab"
            >
              Dashboard
            </v-tab>
            <v-tab
              class="caption titles custom-transform-class text-none"
              @click="kraKpiTab"
            >
              KRA-KPI
            </v-tab>
            <v-tab
              class="caption titles custom-transform-class text-none"
              @click="reviewTab"
            >
              Reviews
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="admin-team-tab">
            <v-tab-item>
              <my-team-dashboard
                ref="team_dashboard"
                :userId="selectedUserId"
                :roleId="selectedRoleId"
              ></my-team-dashboard>
            </v-tab-item>
            <v-tab-item
              ><kra-kpi-tab
                ref="kra_kpi"
                :userId="selectedUserId"
                :roleId="selectedRoleId"
              ></kra-kpi-tab>
            </v-tab-item>
            <v-tab-item
              ><month-tabs
                ref="month_tab"
                :userId="selectedUserId"
                :roleId="selectedRoleId"
              ></month-tabs>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MyTeamDashboard from "../views/my-team/MyTeamDashboard.vue";
import KraKpiTab from "../views/performance/KraKpiTab.vue";
import MonthTabs from "../views/my-team/MonthTabs.vue";
import axios from "@/axios";

export default {
  components: {
    MyTeamDashboard,
    KraKpiTab,
    MonthTabs,
  },
  data() {
    return {
      tab: 0,
      selectedRoleId: null,
      selectedUserId: null,
      userName: null,
      userTab: null,
      allMembers: null,
      page: 1,
      perPage: 0,
      totalPage: 0,
      itemsPerPage: 0,

      allteam: {
        allTeamRoleId: null,
        allTeamUserId: null,
        allTeamUserName: null,
        allTeamUserTab: null,
      },
      allTeamFlag: true,
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.tab === "dashboard" || !this.$route.query.tab) {
          this.tab = 0;
        } else if (this.$route.query.tab === "krakpi") {
          this.tab = 1;
        } else if (this.$route.query.tab === "reviews") {
          this.tab = 2;
        }
      },
    },
    // userTab(val) {
    //   this.userTab = val;
    // },
  },

  computed: {
    ...mapGetters(["myTeam", "getPermissions"]),
    displayAllMembers() {
      return this.getPermissions.find(
        (item) => item.permission_name === "all_member_list"
      );
    },
    visiblePages() {
      const arr = this.allMembers;
      return arr.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    visibleTeams() {
      const arr = this.myTeam;
      return arr.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
  },

  created() {
    this.demo();
  },

  mounted() {
    this.$store.dispatch("getTeamMembers").then(() => {
      this.selectedUserId = this.$route.query.userId || this.myTeam[0].id;
      this.selectedRoleId = this.$route.query.roleId || this.myTeam[0].role_id;
      this.userName = this.$route.query.userName || this.myTeam[0].name;
      this.userTab = this.$route.query.userId || this.myTeam[0].id;
    });
    this.demo();
  },

  methods: {
    dashboardTab() {
      // console.log("MyTeamDashboard");
      this.$router.push({
        name: "My Team Dashboard",
        query: {
          tab: "dashboard",
          roleId: this.selectedRoleId,
          userId: this.selectedUserId,
        },
      });
    },
    kraKpiTab() {
      // console.log("KraKpiTab", this.$refs.kra_kpi?.$refs?.krakpi);
      this.$refs.kra_kpi?.$refs?.krakpi?.callApis();
      this.$router.push({
        name: "Team Member Kra-Kpi",
        query: {
          tab: "krakpi",
          myTeamFlag: true,
          roleId: this.selectedRoleId,
          userId: this.selectedUserId,
        },
      });
    },
    reviewTab() {
      // console.log("MonthTabs");
      // console.log(
      //   "review_Tab",
      //   this.$refs.month_tab?.$refs.month_card?.callApis
      // );
      this.$refs.month_tab?.$refs.month_card?.callApis();
      this.$router.push({
        name: "Team Member Monthly",
        query: {
          tab: "reviews",
          reviewTab: "monthly",
          myTeamFlag: true,
          monthCardFlag: true,
          roleId: this.selectedRoleId,
          userId: this.selectedUserId,
        },
      });
    },

    getIds(item) {
      this.selectedRoleId = item.role_id;
      this.selectedUserId = item.id;
      this.userName = item.name;
      this.userTab = item.id;
      // console.log("usertab", this.userTab);
      this.tab = 0;
      this.$router.push({
        name: "My Team Dashboard",
        query: {
          tab: "dashboard",
          roleId: this.selectedRoleId,
          userId: this.selectedUserId,
        },
      });
    },
    // getIdsAll(item) {
    //   this.allteam.allTeamRoleId = item.role_id;
    //   this.allteam.allTeamUserId = item.id;
    //   this.allteam.allTeamUserName = item.name;
    //   this.allteam.allTeamUserTab = item.id;
    //   this.tab = 0;
    //   this.$router.push({
    //     name: "My Team Dashboard",
    //     query: {
    //       tab: "dashboard",
    //       roleId: this.allteam.allTeamRoleId,
    //       userId: this.allteam.allTeamUserId,
    //     },
    //   });
    // },
    async demo() {
      const token = localStorage.getItem("accessToken");
      if (token) {
        return axios
          .get(
            "allMemberList?page=" + this.page,

            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            this.allMembers = response.data.results.data;
            this.totalPage = Number(response.data.results.last_page);
            this.page = Number(response.data.results.current_page);
            this.perPage = Number(response.data.results.per_page)
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    onPageChange() {
      this.demo();
    },
  },
};
</script>
