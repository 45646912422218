<template>
  <div>
    <v-dialog v-model="dialog" max-width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-col class="text-right pr-0 py-0">
          <v-btn x-small class="my-2 primary" v-bind="attrs" v-on="on"
            >Add KPI</v-btn
          >
        </v-col>
      </template>
      <v-card v-if="kraStatus === 'active'">
        <v-card-title class="primary justify-content-between">
          <span class="text-h6 text-white">Warning</span>
          <v-btn
            icon
            color="white"
            class="grey--lighten-7 px-5 caption"
            @click="dialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="subtitle-1 mt-5">
            Cannot be edited since the year is active!!!
          </div>
        </v-card-text>
        <v-card-actions class="pb-5 px-5">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
       
      <v-card v-else>
       <v-card v-if="kpiNotAssigned.length == 0">
        <v-card-title class="primary justify-content-between">
          <span class="text-h6 text-white">Warning</span>
          <v-btn
            icon
            color="white"
            class="grey--lighten-7 px-5 caption"
            @click="dialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="subtitle-1 mt-5">
            All KPIs are already assigned!!!
          </div>
        </v-card-text>
        <v-card-actions class="pb-5 px-5">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-form v-else ref="form">
        <v-card-title class="primary justify-content-between">
          <span class="text-h6 text-white">Add KPI</span>
          <v-btn
            icon
            color="white"
            class="grey--lighten-7 px-5 caption"
            @click="closeModal"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>

        <div v-for="item in kpiNotAssigned" :key="item.id">
          <v-card-text class="text-left py-0">
            <extra-details :item="item" @add-details="addDetails" />
          </v-card-text>
        </div>
        <v-card-actions class="pb-5 px-5">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="grey--lighten-7 px-5 caption"
            @click="closeModal"
          >
            Close
          </v-btn>
          <!-- <v-btn
            rounded
            class="primary caption px-5"
            color="blue darken-1"
            @click="dialog = false"
          >
            Save
          </v-btn> -->
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ExtraDetails from "./ExtraDetails.vue";
export default {
  components: {
    ExtraDetails,
  },

  props: {
    performanceKpis: {
      type: [Object, Array],
      required: true,
    },
    kpiNotAssigned: {
      type: [Array],
      required: true,
    },
    kraStatus: {
      type: [String],
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      on: false,
      modalItems: [
        {
          kraName: "Sales Revenue",
        },
      ],
    };
   
    },
     methods: {
      closeModal() {
      this.dialog = false;
      window.location.reload();
      this.$refs.form.reset();
    },
  },
};
</script>
